{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}
// version history
// 1.3.5.0 : Fixed bug in FixKeyField; Removed complicated, unnecessary
//           code at the beginning of DoGetData; Removed FTableName in
//           DataProxy to be obtained from FCds; Fixed bug that didn't
//           respond to a Table Name Change, Put CheckInactive to catch
//           a property change attempt during active state.
//         : Implemented BatchUpdates and associated logic for batch
//           processing. Eliminated FUpdateCount as FDataUpdates list
//           can do the job.
// RFE: The count of FChangeList should be exposed by a property in order
//      to disallow Refresh if changes are pending. 

unit WEBLib.MyCloudCDS;

//{$define cdsdebug}

{
  For soft error testing: look at the
  comments for "soft error testing"

  Hard error testing: Any Delphi Exception will be
  hard error and should cause red alert. Try sending a
  usage error from the app, for example make the id field
  name _IDx.
}


{$DEFINE NOPP}

interface

uses
   Classes, JS, Web, SysUtils, WEBLib.CDS, JSONDataSet,
   DB, WebLib.REST;

type
  TMyCloudDbClientDataset = class;
  TMyCloudDbClientDataProxy = class;

  TMyCloudDb = class(TRESTClient)
  private
    FOnConnect: TNotifyEvent;
  protected
    procedure DoOnAccessToken(Sender: TObject);
    function GetAuthURL: String; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property OnConnect: TNotifyEvent read FOnConnect write FOnConnect;
  end;

  TMyCloudDbEntityUpdate = class(TCollectionItem)
  private
    FDataProxy: TMyCloudDbClientDataProxy;
    FData: TJSObject;
    FCustomData: JSValue;
    FBatch: TRecordUpdateBatch;
    FDescriptor: TRecordUpdateDescriptor;
  protected
    procedure HandleInsert(Sender: TObject; AResponse: String; var Handled: Boolean);
    procedure HandleUpdateDelete(Sender: TObject; AResponse: String; var Handled: Boolean);
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function Update: Boolean;
    function Insert: Boolean;
    function Delete: Boolean;
    property CustomData: JSValue read FCustomData write FCustomData;
    property Data: TJSObject read FData write FData;
  end;

  TMyCloudDbEntityUpdates = class(TOwnedCollection)
  private
    FDataProxy: TMyCloudDbClientDataProxy;
    function GetItems(Index: Integer): TMyCloudDbEntityUpdate;
    procedure SetItems(Index: Integer; const Value: TMyCloudDbEntityUpdate);
  protected
    property DataProxy: TMyCloudDbClientDataProxy read FDataProxy;
  public
    constructor Create(AOwner: TPersistent); reintroduce;
    function Add: TMyCloudDbEntityUpdate; reintroduce;
    function Insert(Index: Integer): TMyCloudDbEntityUpdate; reintroduce;
    property Items[Index: Integer]: TMyCloudDbEntityUpdate read GetItems write SetItems; default;
  end;

  TMyCloudDbErrorEvent = procedure(Sender: TObject; errMsg: String) of object;

  TMyCloudDbClientDataProxy = Class(TDataProxy)
  private
    FDb: TMyCloudDb;
    FCds: TMyCloudDbClientDataset;
    FDataRequest: TDataRequest;
    FDataRequestEvent: TDataRequestEvent;
    FDataUpdates: TMyCloudDbEntityUpdates;
    FTableId: String;
    FAccessToken: string;
 protected
    procedure GetTables;
    procedure HandleGetTables(Sender: TObject; AResponse: String; var Handled: Boolean);
    function GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent) : TDataRequest; override;
    function DoGetData(aRequest : TDataRequest) : Boolean; override;
    procedure DoOnError(errMsg: String);
    procedure ProcessUpdateEnd(anIndex: Integer);
    procedure DoOnEntityError(anEntityUpdate: TMyCloudDbEntityUpdate; errMsg: String);
    procedure DoOnEntityUpdateDelete(anEntityUpdate: TMyCloudDbEntityUpdate);
    procedure DoOnEntityInsert(anEntityUpdate: TMyCloudDbEntityUpdate);
    procedure DoOnConnect(Sender: TObject);
    procedure GetEntities;
    procedure HandleGetEntities(Sender: TObject; AResponse: String; var Handled: Boolean);
    procedure ProcessUpdate(desc: TRecordUpdateDescriptor; aBatch: TRecordUpdateBatch=nil);
    procedure Close;
    function ProcessUpdateBatch(aBatch: TRecordUpdateBatch): Boolean; override;
    procedure CheckBatchComplete(aBatch: TRecordUpdateBatch);

  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property AccessToken: string read FAccessToken;
    property TableId: string read FTableId;
    property Db: TMyCloudDb read FDb;
  end;

  TBatchUpdates = (buAuto, buManual);

  TMyCloudDbClientDataset = Class(TCustomClientDataSet)
  private
    FDBProxy: TMyCloudDbClientDataProxy;
    FAppKey, FTableName, FAppCallbackURL: String;
    FSortFields: TJSArray;
    FConnecting, FConnected, FDataLoaded,
    FOpenAfterLoadDFM: Boolean;
    FRemKeyPos: JSValue;
    FOnError: TMyCloudDbErrorEvent;
    FRefreshPending: Boolean;
    FBatchUpdates: TBatchUpdates;
    function GetAccessToken: string;
  protected
    procedure InternalClose; override;
    Function AddToChangeList(aChange : TUpdateStatus): TRecordUpdateDescriptor ; override;
    procedure SetActive(Value: Boolean); override;
    procedure AfterLoadDFMValues; override;
    procedure DoAfterOpen; override;
    procedure DoOnError(errMsg: String);
    function GetSortSpec: String;
    procedure FixKeyField;
    function IsKeyFieldValid: Boolean;
    procedure SetAppKey(aKey: String);
    procedure SetTableName(aTableName: String);
    function GetUpdateCount: Integer;
    property DataLoaded: Boolean read FDataLoaded write FDataLoaded;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Refresh(Force: Boolean=False); reintroduce;
    procedure AddSortFieldDef(aField: String; isAscending: Boolean);
    procedure ClearSortFieldDefs;
    procedure ClearTokens;
    property AccessToken: string read GetAccessToken;
    property BatchUpdates: TBatchUpdates read FBatchUpdates write FBatchUpdates;
  published
    property AppKey: String read FAppKey write SetAppKey;
    property TableName: String read FTableName write SetTableName;
    property AppCallbackURL: String read FAppCallbackURL write FAppCallbackURL;
    property OnError: TMyCloudDbErrorEvent read FOnError write FOnError;
    property BeforeOpen;
    property AfterOpen;
    property BeforeClose;
    property AfterClose;
    property BeforeInsert;
    property AfterInsert;
    property BeforeEdit;
    property AfterEdit;
    property BeforePost;
    property AfterPost;
    property BeforeCancel;
    property AfterCancel;
    property BeforeDelete;
    property AfterDelete;
    property BeforeScroll;
    property AfterScroll;
    property OnCalcFields;
    property OnDeleteError;
    property OnEditError;
    property OnFilterRecord;
    property OnNewRecord;
    property OnPostError;
    property OnUpdateRecord;
  end;

  TWebmyCloudDbClientDataset = class(TMyCloudDbClientDataset);

implementation

uses
  WebLib.JSON;

const
  PRIMARY_KEY_FIELD: String = '_ID';

{ TMyCloudDb }

procedure TMyCloudDb.DoOnAccessToken(Sender: TObject);
begin
  inherited;
  if Assigned(FOnConnect) then
    FOnConnect(Self);
end;

function TMyCloudDb.GetAuthURL: String;
begin
  Result := 'https://api.myclouddata.net/login.html#?client_id=' + App.Key
       + '&redirect_uri=' + encodeURIComponent(App.CallbackURL)
       + '&response_type=token'
       + '&scope='
       + '&state=profile';
end;

constructor TMyCloudDb.Create(AOwner: TComponent);
begin
  inherited;
  APIBase := 'https://api.myclouddata.net/v2';
  PersistTokens.Enabled := true;
  PersistTokens.Key := 'myCloudData';
  ReadTokens;

  OnAccessToken := DoOnAccessToken;
end;

destructor TMyCloudDb.Destroy;
begin
  inherited;
end;

{ TMyCloudDbEntityUpdate }

function isErrorObject(AResponse: String; out aParsedOutput: JSValue; var errMsg: String): Boolean;
var
  err: String;
  jo: TJSObject;
begin
  Result := False;
  aParsedOutput := TJSJSON.parse(AResponse);
  if isArray(aParsedOutput) or (not isObject(aParsedOutput)) then
    exit;
  jo := TJSObject(aParsedOutput);
  err := String(jo['error']);
  if (err = 'undefined') then
    exit;
  errMsg := err;
  Result := True;
end;

procedure TMyCloudDbEntityUpdate.HandleInsert(Sender: TObject; AResponse: String;
  var Handled: Boolean);
var
  errMsg: String;
  aParsedOutput: JSValue;
begin
{$ifdef cdsdebug}
  console.log('Response Insert', AResponse);
{$endif}
  if (isErrorObject(AResponse, aParsedOutput, errMsg)) then
  begin
    FDataProxy.DoOnEntityError(Self, errMsg);
    exit;
  end;
  if not isObject(aParsedOutput) then
  begin
    FDataProxy.DoOnEntityError(Self, 'Error: Unexpected response on Insert');
    exit;
  end;
  FData[PRIMARY_KEY_FIELD] := TJSObject(aParsedOutput)[PRIMARY_KEY_FIELD];
{$ifdef cdsdebug}
  console.log(Format('Response received for Insert, Generated ID %d', [Integer(FData['_ID'])]));
{$endif}
  FDataProxy.DoOnEntityInsert(Self);
end;

procedure TMyCloudDbEntityUpdate.HandleUpdateDelete(Sender: TObject; AResponse: String;
  var Handled: Boolean);
var
  errMsg: String;
  aParsedOutput: JSValue;
begin
{$ifdef cdsdebug}
  console.log('Response Update/Delete', AResponse);
  console.log(Format('Response received for Update/Delete ID %d', [Integer(FData['_ID'])]));
{$endif}
  if (isErrorObject(AResponse, aParsedOutput, errMsg)) then
  begin
    FDataProxy.DoOnEntityError(Self, errMsg);
    exit;
  end;
  FDataProxy.DoOnEntityUpdateDelete(Self);
end;

constructor TMyCloudDbEntityUpdate.Create(Collection: TCollection);
begin
  inherited;
  FDataProxy := TMyCloudDbEntityUpdates(Collection).FDataProxy;
  FData := nil;
end;

destructor TMyCloudDbEntityUpdate.Destroy;
begin
  inherited;
end;

procedure TMyCloudDbEntityUpdate.Assign(Source: TPersistent);
begin
  inherited;
end;

function TMyCloudDbEntityUpdate.Update: Boolean;
var
  URL, postData: String;
  i: integer;
begin
  Result := False;

  if FData = nil then
    raise Exception.Create('MyCloudDbCDS Internal Error, Update: Data not set');

  URL := FDataProxy.Db.APIBase + '/data/table';

  if Assigned(FDataProxy.FCds) then
  begin
    for i := 0 to FDataProxy.FCds.Fields.Count - 1 do
    begin
      if FDataProxy.FCds.Fields[i].IsBlob then
        JSDelete(FData,FDataProxy.FCds.Fields[i].FieldName);
    end;
  end;

  postData := '{' +
                  '"tableid": ' + FDataProxy.TableID + ', ' +
                  '"fields": ' + TJSJSON.Stringify(FData) +
               '}';

  FDataProxy.Db.OnHttpResponse := HandleUpdateDelete;
  FDataProxy.Db.HttpsPut(URL, 'application/json;charset=UTF-8', postData);
end;

function TMyCloudDbEntityUpdate.Insert: Boolean;
var
  URL, postData: String;
begin
  Result := False;

  if FData = nil then
    raise Exception.Create('MyCloudDbCDS Internal Error, Update: Data not set');

  URL := FDataProxy.Db.APIBase + '/data/table';

  JSDelete(FData, PRIMARY_KEY_FIELD);

  postData := '{' +
                  '"tableid": ' + FDataProxy.TableID + ', ' +
                  '"fields": ' + TJSJSON.Stringify(FData) +
               '}';

  FDataProxy.Db.OnHttpResponse := HandleInsert;
  FDataProxy.Db.HttpsPost(URL, 'application/json;charset=UTF-8', postData);
end;

function TMyCloudDbEntityUpdate.Delete: Boolean;
var
  URL: String;
begin
  Result := False;

  if FData = nil then
    raise Exception.Create('MyCloudDbCDS Internal Error, Delete: Data not set');

  URL := FDataProxy.Db.APIBase + '/data/table?tableid=' + FDataProxy.TableID + '&recordid='
      + String(FData[PRIMARY_KEY_FIELD]);
  FDataProxy.Db.OnHttpResponse := HandleUpdateDelete;
  FDataProxy.Db.HttpsDelete(URL);
end;

{ TMyCloudDbEntityUpdates }

function TMyCloudDbEntityUpdates.GetItems(Index: Integer): TMyCloudDbEntityUpdate;
begin
  Result := TMyCloudDbEntityUpdate(inherited Items[Index]);
end;

procedure TMyCloudDbEntityUpdates.SetItems(Index: Integer;
  const Value: TMyCloudDbEntityUpdate);
begin
  inherited Items[index] := Value;
end;

constructor TMyCloudDbEntityUpdates.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, TMyCloudDbEntityUpdate);
  FDataProxy := TMyCloudDbClientDataProxy(AOwner);
end;

function TMyCloudDbEntityUpdates.Add: TMyCloudDbEntityUpdate;
begin
  Result := TMyCloudDbEntityUpdate(inherited Add);
end;

function TMyCloudDbEntityUpdates.Insert(Index: Integer): TMyCloudDbEntityUpdate;
begin
  Result := TMyCloudDbEntityUpdate(inherited Insert(Index));
end;

{ TMyCloudDbClientDataProxy }

procedure TMyCloudDbClientDataProxy.GetTables;
var
  URL: String;
begin
  URL := 'https://api.myclouddata.net/schema/table';
  FDb.OnHttpResponse := HandleGetTables;
  FDb.HttpsGet(URL);
end;

procedure TMyCloudDbClientDataProxy.HandleGetTables(Sender: TObject; AResponse: String;
  var Handled: Boolean);
var
  ja: TJSArray;
  jo: TJSObject;
  i: Integer;
  errMsg: String;
  aParsedOutput: JSValue;
begin
  if (isErrorObject(AResponse, aParsedOutput, errMsg)) then
  begin
    DoOnError(errMsg);
    FCds.FConnecting := False;
    exit;
  end;
  if not isArray(aParsedOutput) then
  begin
    DoOnError('Error: Unexpected response on Get Entities');
    exit;
  end;

  ja := TJSArray(aParsedOutput);
  for i := 0 to ja.length - 1 do
  begin
    jo := TJSObject(ja[i]);
    if SameText(FCds.TableName, String(jo['TableName'])) then
    begin
      FTableId := String(jo['TableID']);
      break;
    end;
  end;

  if FTableId = '' then
  begin
    DoOnError('Error: Table ' + FCds.TableName + ' does not exist.');
    exit;
  end;

  // The real connection success for Proxy is also finding the
  // Table Id.
  FCds.FConnected := True;
  FCds.FConnecting := False;
  GetEntities;
end;

// A data request object is created for initial loading sequence
// with this call first.
function TMyCloudDbClientDataProxy.GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent) : TDataRequest;
begin
  Result := GetDataRequestClass.Create(Self, aOptions, aAfterRequest, aAfterLoad);
  FDataRequest := Result;
  FDataRequestEvent := aAfterRequest;
end;

// The data loading is initiated by the ClientDataSet by this
// request coming from it.
function TMyCloudDbClientDataProxy.DoGetData(aRequest: TDataRequest): Boolean;
begin
  if FCds.DataLoaded then
  begin
{$ifdef cdsdebug}
    // Todo: Seems to come too often from DBGrid. That's why
    // DataLoaded flag is used. Once grid problem is fixed,
    // and it comes really when required, it will work better
    // for viewing multi-user latest updates in the DB.
    console.log('No more data to additional request');
{$endif}
    aRequest.success := rrEOF;
    aRequest.data := TJSArray.New;
    if Assigned(FDataRequestEvent) then
      FDataRequestEvent(FDataRequest);
    exit;
  end;
  if (FDb = nil) or (not FCds.FConnected) then
  begin
    if
      (FCds.AppKey <> '')
      and (FCds.TableName <> '')
      and (FCds.AppCallbackURL <> '') then
    begin
        if not FCds.IsKeyFieldValid then
        begin
          // soft error testing: Name id field other than _ID. Should generate this error
          // Usage error
          raise Exception.Create(
            Format('MyCloudDbCDS Usage Error: Key Field "%s" must be defined and should be of datatype ftString', [PRIMARY_KEY_FIELD])
            );
        end;
	  //Also in this case, case fix can not be done
      FDb := TMyCloudDb.Create(Self);
{$ifdef cdsdebug}
      console.log('Opening myCloudData DB.');
{$endif}
      FDb.App.Key := FCds.AppKey;
      FDb.App.CallbackURL := FCds.AppCallbackURL;
      FDb.PersistTokens.Enabled := True;
      FDb.PersistTokens.Key := 'myCloudData';
      FTableId := '';
      FCds.FConnecting := True;
      FDb.OnConnect := DoOnConnect;
      FDb.Connect;
    end
    else
    begin
      // Usage error
      raise Exception.Create('MyCloudDbCDS Usage Error: AppKey, AppCallbackURL and TableName must be specified.');
    end;
  end
  else
  begin
    GetEntities;
  end;
  // This Result has no meaning in Async, caller does not check result
  Result := True;
end;


// On any error, whether for initial loading sequence or for
// later updates, this is called. It communicates the error
// accordingly to either the CDS for its loading sequence
// or to the App via CDS OnError handler.
procedure TMyCloudDbClientDataProxy.DoOnError(errMsg: String);
begin
  if (not FCds.DataLoaded) and Assigned(FDataRequestEvent) then
  begin
    FDataRequest.success := rrFail;
    FDataRequest.ErrorMsg := errMsg;
    FDataRequestEvent(FDataRequest);
  end;
  FCds.DoOnError(errMsg);
end;

procedure TMyCloudDbClientDataProxy.ProcessUpdateEnd(anIndex: Integer);
begin
  FDataUpdates.Delete(anIndex);
  if FCds.FRefreshPending and (FDataUpdates.Count = 0) then
    FCds.Refresh;
end;

procedure TMyCloudDbClientDataProxy.DoOnEntityError(anEntityUpdate: TMyCloudDbEntityUpdate; errMsg: String);
begin
  if FCds.FBatchUpdates = buManual then
  begin
    anEntityUpdate.FDescriptor.ResolveFailed(errMsg);
    CheckBatchComplete(anEntityUpdate.FBatch);
  end;
  ProcessUpdateEnd(anEntityUpdate.Index);
  DoOnError(errMsg);
end;

procedure TMyCloudDbClientDataProxy.DoOnEntityUpdateDelete(anEntityUpdate: TMyCloudDbEntityUpdate);
begin
  if FCds.FBatchUpdates = buManual then
  begin
    anEntityUpdate.FDescriptor.Resolve(nil);
    CheckBatchComplete(anEntityUpdate.FBatch);
  end;
  ProcessUpdateEnd(anEntityUpdate.Index);
end;

procedure TMyCloudDbClientDataProxy.DoOnEntityInsert(anEntityUpdate: TMyCloudDbEntityUpdate);
var
  aBookMark: TBookMark;
begin
{$ifdef cdsdebug}
  console.log('On Insert, Going to Bookmark: ', anEntityUpdate.CustomData);
{$endif}
  aBookMark.Flag := bfCurrent;
  aBookMark.Data := anEntityUpdate.CustomData;
  FCds.GotoBookmark(aBookMark);
  FCds.Edit;
  FCds.FieldByName(PRIMARY_KEY_FIELD).AsString := String(anEntityUpdate.FData[PRIMARY_KEY_FIELD]);
  // This triggers an additional modify update but that is OK.
  FCds.Post;
  FCds.EnableControls;

  if FCds.FBatchUpdates = buManual then
  begin
    anEntityUpdate.FDescriptor.Resolve(nil);
    CheckBatchComplete(anEntityUpdate.FBatch);
  end;
  ProcessUpdateEnd(anEntityUpdate.Index);
end;

procedure TMyCloudDbClientDataProxy.DoOnConnect(Sender: TObject);
begin
  if Assigned(FDb) then
    FAccessToken := FDb.Accesstoken;
  GetTables;
end;

procedure TMyCloudDbClientDataProxy.GetEntities;
var
  URL, postdata: String;
begin
  URL := 'https://api.myclouddata.net/v2/data/tablefilter';
  FDb.OnHttpResponse := HandleGetEntities;
  // To test DB error (soft): FTableID + 'x' +
  // should generate OnError for application or exception if not subscribed
  postdata := '{ "tableid":"' + FTableID + '"'
              + FCds.GetSortSpec
              + '}';
  FDb.HttpsPost(URL, 'application/json;charset=UTF-8', postdata);
end;

procedure TMyCloudDbClientDataProxy.HandleGetEntities(Sender: TObject; AResponse: String; var Handled: Boolean);
var
  data: TJSArray;
  errMsg: String;
  aParsedOutput: JSValue;
begin
  if (isErrorObject(AResponse, aParsedOutput, errMsg)) then
  begin
    DoOnError(errMsg);
    FCds.FConnecting := False;
    exit;
  end;

  if not isArray(aParsedOutput) then
  begin
    DoOnError('Error: Unexpected response on Get Entities');
    exit;
  end;

  data := TJSArray(aParsedOutput);

  // determine if part of loading sequence data request
  if Assigned(FDataRequest) and Assigned(FDataRequest.DataSet) then
  begin
    // Get All finished is second part of initial data loading
    // sequence in the CDS. Now in order to set Rows, the dataset
    // must be closed. This is mandatory.
    FDataRequest.success := rrEOF;
    FDataRequest.Data := data;
    if FCds.Active then
      FCds.Close;
    FCds.Rows := data;
    FCds.DataLoaded := True;
{$ifdef cdsdebug}
    console.log('Response: all objects loaded');
{$endif}
    FCds.FConnecting := False;
    FCds.FConnected := True;
    if Assigned(FDataRequestEvent) then
      FDataRequestEvent(FDataRequest);
  end;
end;

procedure TMyCloudDbClientDataProxy.ProcessUpdate(desc: TRecordUpdateDescriptor; aBatch: TRecordUpdateBatch=nil);
var
  aBookMark: TBookMark;
  anEntityUpdate: TMyCloudDbEntityUpdate;
begin
  if (desc.Status = usModified) then
  begin
    anEntityUpdate := FDataUpdates.Add;
    anEntityUpdate.FBatch := aBatch;
    anEntityUpdate.FDescriptor := desc;
    anEntityUpdate.Data := TJSObject(desc.data);
{$ifdef cdsdebug}
    console.log(Format('Modifying ID %d', [Integer(TJSObject(desc.data)['_ID'])]));
{$endif}
    anEntityUpdate.Update;
  end;
  if (desc.Status = usInserted) then
  begin
    anEntityUpdate := FDataUpdates.Add;
    anEntityUpdate.FBatch := aBatch;
    anEntityUpdate.FDescriptor := desc;
    anEntityUpdate.Data := TJSObject(desc.data);
    aBookmark := FCds.BookMark;
{$ifdef cdsdebug}
    if (aBookmark.Flag <> bfCurrent) then
      console.log('MyCloudDbCDS Internal Warning, Insert: Bookmark flag on insert not current as expected.');
{$endif}
    anEntityUpdate.CustomData := aBookmark.data;
{$ifdef cdsdebug}
    console.log('Bookmark noted for this Insert: ', aBookmark.data);
{$endif}
    FCds.DisableControls;
    anEntityUpdate.Insert;
  end;
  if desc.Status = usDeleted then
  begin
    anEntityUpdate := FDataUpdates.Add;
    anEntityUpdate.FBatch := aBatch;
    anEntityUpdate.FDescriptor := desc;
    anEntityUpdate.Data := TJSObject(desc.data);
{$ifdef cdsdebug}
    console.log(Format('Deleting ID %d', [Integer(TJSObject(desc.data)['_ID'])]));
{$endif}
    anEntityUpdate.Delete;
  end;
end;

procedure TMyCloudDbClientDataProxy.Close;
begin
  // we do not close connection because this is also
  // the Refresh path
end;

// Dummy abstract method. Must be coded.
function TMyCloudDbClientDataProxy.ProcessUpdateBatch(
  aBatch: TRecordUpdateBatch): Boolean;
var
  desc: TRecordUpdateDescriptor;
  I: Integer;
begin
  if FCds.FBatchUpdates = buAuto then
  begin
    Result := False;
    exit;
  end;
  Result := True;
  For I:=0 to aBatch.List.Count-1 do
  begin
    desc := aBatch.List[I];
    ProcessUpdate(desc, aBatch);
  end;
end;

procedure TMyCloudDbClientDataProxy.CheckBatchComplete(aBatch: TRecordUpdateBatch);
var
  BatchOK : Boolean;
  I : Integer;
begin
  BatchOK := True;
  I := aBatch.List.Count - 1;
  while BatchOK and (I >= 0) do
  begin
    BatchOK := aBatch.List[I].ResolveStatus in [rsResolved, rsResolveFailed];
    Dec(I);
  end;

  if BatchOK and Assigned(aBatch.OnResolve) then
    aBatch.OnResolve(Self, aBatch);
end;

constructor TMyCloudDbClientDataProxy.Create(AOwner: TComponent);
begin
  inherited;
  FDataRequest := nil;
  FDataRequestEvent := nil;
  FDb := nil;
  FCds := TMyCloudDbClientDataset(AOwner);
  FTableId := '';
  FDataUpdates := TMyCloudDbEntityUpdates.Create(Self);
end;

destructor TMyCloudDbClientDataProxy.Destroy;
begin
  if FDb <> nil then
    FDb.Free;
  if FDataUpdates <> nil then
    FDataUpdates.Free;
  inherited;
end;

{ TMyCloudDbClientDataset }

procedure TMyCloudDbClientDataset.InternalClose;
begin
  inherited;
  FDBProxy.Close;
  FDataLoaded := False;
  FOpenAfterLoadDFM := False;
  FConnected := False;
  FConnecting := False;
end;

// This is called on each change. So we use it to trigger
// DB updates.
Function TMyCloudDbClientDataset.AddToChangeList(aChange : TUpdateStatus) : TRecordUpdateDescriptor ;
var
  desc: TRecordUpdateDescriptor;
begin
  if FBatchUpdates = buManual then
  begin
    inherited;
    exit;
  end;
{$ifdef cdsdebug}
  console.log('Processing change instantly in Auto Update for change type: ', aChange);
{$endif}
  desc := FDBProxy.GetUpdateDescriptor(Self, GetBookmark, ActiveBuffer.data, aChange);
  FDBProxy.ProcessUpdate(desc);
  result := nil;
end;

procedure TMyCloudDbClientDataset.SetActive(Value: Boolean);
begin
  if (FieldDefs.Count = 0)
    and Value and (State = dsInactive)
    and (not FDataLoaded)
    and (not FOpenAfterLoadDFM)
  then
  begin
    FOpenAfterLoadDFM := True;
    Exit;
  end;
  if value and (State = dsInactive)
    and (not FDataLoaded)
    // Connecting needs several operations so ignore additional SetActive requests
    and (not FConnecting)
  then
  begin
    FOpenAfterLoadDFM := True;

    // The following Load internally causes Active := True
    // in db.pas and then "inherited" path is taken
    Load([loNoEvents], nil);
    Exit;
  end;

  if not FConnecting then
    inherited;
end;

procedure TMyCloudDbClientDataset.AfterLoadDFMValues;
begin
  inherited;
  if FOpenAfterLoadDFM then
  begin
    // if field defs are still absent better raise error
    if FieldDefs.Count = 0 then
      raise Exception.Create(
          'MyCloudDbCDS Usage Error: Can not Set Active, Field definitions missing.');
    SetActive(True);
  end;
end;
procedure TMyCloudDbClientDataset.DoAfterOpen;
begin
  inherited;

  // Reposition to same rec after a Refresh
  // Not sure if should be before or after inherited. The doubt
  // is related to CDS runtime indexes and a reposition should
  // be tested with and without indexes.
  if FRemKeyPos <> nil then
  begin
    Locate(PRIMARY_KEY_FIELD, FRemKeyPos, []);
    FRemKeyPos := nil;
  end;
end;

// Communicate the error to the App. DataProxy uses it on error.
procedure TMyCloudDbClientDataset.DoOnError(errMsg: String);
begin
  //console.log('MyCloudDbCDS Error: ', errMsg);
  if Assigned(FOnError) then
    FOnError(Self, errMsg)
  else
    raise Exception.Create(errMsg);
end;

function TMyCloudDbClientDataset.GetAccessToken: string;
begin
  Result := FDBProxy.AccessToken;
end;

function TMyCloudDbClientDataset.GetSortSpec: String;
var
  sortspec: String;
  aSortDef: TJSObject;
  I: Integer;
begin
  sortspec := '';
  if (FSortFields.length > 0) then
  begin
    sortspec := ', "sorting": [';
    for I := 0 to FSortFields.length-1 do
    begin
      aSortDef := TJSObject(FSortFields[I]);
      sortspec := sortspec +
                   '{' +
                   ' "field": "' + String(aSortDef['field']) + '",' +
                   ' "sortorder": "' + String(aSortDef['sortorder']) + '"' +
                   '}';
    end;
    sortspec := sortspec + ' ] ';
  end;
  result := sortspec;
end;

procedure TMyCloudDbClientDataset.FixKeyField;
var
  i: Integer;
  found: Boolean;
begin
  found := False;
  for i := 0 to FieldDefs.Count - 1 do
  begin
    if SameText(TNamedItem(FieldDefs.Items[i]).Name, PRIMARY_KEY_FIELD) then
    begin
      found := true;
      // Fix the Case
      TNamedItem(FieldDefs.Items[i]).Name := PRIMARY_KEY_FIELD;
      {$ifdef cdsdebug}
      console.log('MyCloudDbCDS Usage Warning: Key field name corrected to exact _ID.');
      {$endif}
    end;
    if found and (FieldDefs.Items[i].DataType <> ftString) then
    begin
      // Fix data type
      FieldDefs.Items[i].DataType := ftString;
      {$ifdef cdsdebug}
      console.log('MyCloudDbCDS Usage Warning: Key field type corrected to ftString.');
      {$endif}
    end;
    if found then
      break;
  end;
end;

function TMyCloudDbClientDataset.IsKeyFieldValid: Boolean;
var
  aFieldDef: TFieldDef;
begin
  FixKeyField;
  aFieldDef := TFieldDef(TDefCollection(FieldDefs).Find(PRIMARY_KEY_FIELD));
  Result := (aFieldDef <> nil) and (aFieldDef.DataType = ftString);
end;

procedure TMyCloudDbClientDataset.SetAppKey(aKey: String);
begin
  CheckInactive;
  FAppKey := aKey;
end;

procedure TMyCloudDbClientDataset.SetTableName(aTableName: String);
begin
  CheckInactive;
  FTableName := aTableName;
end;

function TMyCloudDbClientDataset.GetUpdateCount: Integer;
begin
  Result := 0;
  if FDBProxy = nil then
    exit;
  Result := FDBProxy.FDataUpdates.Count;
end;

constructor TMyCloudDbClientDataset.Create(AOwner: TComponent);
begin
  inherited;
  FAppKey := '';
  FTableName := '';
  FAppCallbackURL := '';
  FDataLoaded := False;
  FConnected := False;
  FConnecting := False;
  FRemKeyPos := nil;
  FDBProxy := nil;
  FSortFields := TJSArray.New;
  FDBProxy := TMyCloudDbClientDataProxy.Create(Self);
  DataProxy := FDBProxy;
  FRefreshPending := False;
  FBatchUpdates := buAuto;
end;

destructor TMyCloudDbClientDataset.Destroy;
begin
  if FDBProxy <> nil then
    FDBProxy.Free;
  inherited;
end;

procedure TMyCloudDbClientDataset.Refresh(Force: Boolean=False);
begin
  if (State = dsInactive) or FConnecting then
    exit;
  if Force then
    FDBProxy.FDataUpdates.Clear;

  // Since a Refresh is going to close the db and reopen,
  // better wait for any updates in progress to finish.
  // Even that may not foolproof in certain conditions
  // and interleaving of operations. But we can try.
  if GetUpdateCount > 0 then
  begin
    console.log('Refresh posted because Updates are Pending: ', GetUpdateCount);
    FRefreshPending := True;
    exit;
  end;
  //Todo: In case of batch, similar check is needed on FChangeList


{$ifdef cdsdebug}
  console.log('Actual Refresh proceeds');
{$endif}
  FRefreshPending := False;

  if CurrentRecord > -1 then
    // remember the record to reposition after refresh
    FRemKeyPos := FieldByName(PRIMARY_KEY_FIELD).Value;

{$ifdef cdsdebug}
  console.log('Closing the DB for a Refresh Load.');
{$endif}
  Close;
  Load([loNoEvents], nil);
end;

procedure TMyCloudDbClientDataset.AddSortFieldDef(aField: String; isAscending: Boolean);
var
  aSortDef: TJSObject;
begin
  aSortDef := TJSObject.New;
  aSortDef['field'] := aField;
  if isAscending then
    aSortDef['sortorder'] := 'ASC'
  else
    aSortDef['sortorder'] := 'DESC';
  FSortFields.Push(aSortDef);
end;

procedure TMyCloudDbClientDataset.ClearSortFieldDefs;
begin
  FSortFields.length := 0;
end;

procedure TMyCloudDbClientDataset.ClearTokens;
var
  LDb: TMyCloudDb;
begin
  LDb := TMyCloudDb.Create(Self);
  try
    LDB.ClearTokens;
  finally
    LDB.Free;
  end;
end;

end.
