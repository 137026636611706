{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}
unit WEBLib.myCloudData;

{$DEFINE NOPP}

interface

uses
  Classes, WebLib.REST, JS, SysUtils;

type
  TmyCloudDataMetaData = class;
  TmyCloudDataEntity = class;
  TmyCloudDataEntities = class;
  TmyCloudDataTable = class;
  TmyCloudData = class;

  TmyCloudDataTable = class(TCollectionItem)
  private
    FEntityQuery: boolean;
    FTableName: string;
    FTableID: string;
    FEntities: TmyCloudDataEntities;
    FMetaData: TmyCloudDataMetaData;
    FmyCloudData: TmyCloudData;
    FOnMetaData: TNotifyEvent;
    FOnEntities: TNotifyEvent;
  protected
    procedure HandleGetMetaData(Sender: TObject; AResponse: string; var Handled: boolean);
    procedure HandleGetEntities(Sender: TObject; AResponse: string; var Handled: boolean);
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure GetMetaData;
    procedure GetEntities;

    property MetaData: TmyCloudDataMetaData read FMetaData;
    property Entities: TmyCloudDataEntities read FEntities;
  published
    property TableName: string read FTableName write FTableName;
    property TableID: string read FTableID write FTableID;
    property OnMetaData: TNotifyEvent read FOnMetaData write FOnMetaData;
    property OnEntities: TNotifyEvent read FOnEntities write FOnEntities;
  end;

  TmyCloudDataTables = class(TOwnedCollection)
  private
    FmyCloudData: TmyCloudData;
    function GetItem(Index: integer): TmyCloudDataTable; reintroduce;
    procedure SetItem(Index: integer; const Value: TmyCloudDataTable);

  public
    constructor Create(AOwner: TPersistent); reintroduce;
    function Add: TmyCloudDataTable; reintroduce;
    function Insert(Index: integer): TmyCloudDataTable; reintroduce;
    property Items[Index: integer]: TmyCloudDataTable read GetItem write SetItem;
  end;


  TFieldType = (ftUnknown, ftString, ftSmallint, ftInteger, ftWord, // 0..4
    ftBoolean, ftFloat, ftCurrency, ftBCD, ftDate, ftTime, ftDateTime, // 5..11
    ftBytes, ftVarBytes, ftAutoInc, ftBlob, ftMemo, ftGraphic, ftFmtMemo, // 12..18
    ftParadoxOle, ftDBaseOle, ftTypedBinary, ftCursor, ftFixedChar, ftWideString, // 19..24
    ftLargeint, ftADT, ftArray, ftReference, ftDataSet, ftOraBlob, ftOraClob, // 25..31
    ftVariant, ftInterface, ftIDispatch, ftGuid, ftTimeStamp, ftFMTBcd, // 32..37
    ftFixedWideChar, ftWideMemo, ftOraTimeStamp, ftOraInterval, // 38..41
    ftLongWord, ftShortint, ftByte, ftExtended, ftConnection, ftParams, ftStream, //42..48
    ftTimeStampOffset, ftObject, ftSingle); //49..51

  TFieldTypes = set of TFieldType;


  TMyCloudDataMetaDataTypedField = (tfNone, tfRadioButton, tfComboBox, tfCheckBox);

  TMyCloudDataMetaDataItem = class(TCollectionItem)
  private
    FFieldName: string;
    FDataType: TFieldType;
    FSize: integer;
    FEnabled: boolean;
    FLabelText: string;
    FLookupTable: int64;
    FMask: string;
    FWidth: integer;
    FMaximum: double;
    FOrder: integer;
    FMaximumDate: TDatetime;
    FLookupKeyField: string;
    FLookupField: string;
    FVisible: boolean;
    FDescription: string;
    FMinimum: double;
    FMinimumDate: TDatetime;
    FTypedField: TMyCloudDataMetaDataTypedField;
    FDefaultValue: string;
    FRequired: boolean;
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    property Size: integer read FSize;
    property DataType: TFieldType read FDataType;
  published
    property FieldName: string read FFieldName write FFieldName;
    property LabelText: string read FLabelText write FLabelText;
    property DefaultValue: string read FDefaultValue write FDefaultValue;
    property Width: integer read FWidth write FWidth;
    property Order: integer read FOrder write FOrder;
    property Mask: string read FMask write FMask;
    property Minimum: double read FMinimum write FMinimum;
    property Maximum: double read FMaximum write FMaximum;
    property MinimumDate: TDateTime read FMinimumDate write FMinimumDate;
    property MaximumDate: TDateTime read FMaximumDate write FMaximumDate;
    property Visible: boolean read FVisible write FVisible default true;
    property Enabled: boolean read FEnabled write FEnabled default true;
    property Required: boolean read FRequired write FRequired default false;
    property Description: string read FDescription write FDescription;
    property LookupTable: int64 read FLookupTable write FLookupTable;
    property LookupField: string read FLookupField write FLookupField;
    property LookupKeyField: string read FLookupKeyField write FLookupKeyField;
    property TypedField: TMyCloudDataMetaDataTypedField read FTypedField write FTypedField;
  end;

  TMyCloudDataMetaData = class(TOwnedCollection)
  private
    function GetItems(Index: Integer): TMyCloudDataMetaDataItem;
    procedure SetItems(Index: Integer; const Value: TMyCloudDataMetaDataItem);
  public
    constructor Create(AOwner: TPersistent); reintroduce;
    function Add: TMyCloudDataMetaDataItem; reintroduce; overload;
    function Add(PropertyName: string; DataType: TFieldType; Size: integer = 0): TMyCloudDataMetaDataItem; overload;
    function Insert(Index: Integer): TMyCloudDataMetaDataItem; reintroduce;
    property Items[Index: Integer]: TMyCloudDataMetaDataItem read GetItems write SetItems; default;
    function GetMetaDataItemByName(AName: string): TMyCloudDataMetaDataItem;
  end;

  TMyCloudDataBlob = class(TPersistent)
  private
    //FDataStore: TAdvCustomMyCloudData;
    FTable: TMyCloudDataTable;
    FEntity: TMyCloudDataEntity;
    FField: string;
  public
    //constructor Create(ADataStore: TCustomDataStore);
    property Table: TMyCloudDataTable read FTable write FTable;
    property Entity: TMyCloudDataEntity read FEntity write FEntity;
    property Field: string read FField write FField;

    //function LoadFromFile(AFileName: string): boolean;
    //function SaveToFile(AFileName: string): boolean;
    //function LoadFromStream(AStream: TStream): boolean;
    //function SaveToStream(AStream: TStream): boolean;
  end;

  TmyCloudDataEntity = class(TCollectionItem)
  private
    FValues: TStringList;
    FBlob: TmyCloudDataBlob;
    FTable: TmyCloudDataTable;
    FOnDelete: TNotifyEvent;
    FOnUpdate: TNotifyEvent;
    FOnInsert: TNotifyEvent;
    FID: string;
    function GetValue(AName: string): string;
    procedure SetValue(AName: string; const Value: string);
    function GetBlob(AName: string): TMyCloudDataBlob;
    function GetIntID: int64;
  protected
    procedure HandleInsert(Sender: TObject; AResponse: string; var Handled: boolean);
    procedure HandleDelete(Sender: TObject; AResponse: string; var Handled: boolean);
    procedure HandleUpdate(Sender: TObject; AResponse: string; var Handled: boolean);

    property Table: TMyCloudDataTable read FTable write FTable;
    property Values: TStringList read FValues;
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    //procedure FromJSON(jo: TJSONObject); override;
    property Value[AName: string]: string read GetValue write SetValue;
    property Blob[AName: string]: TMyCloudDataBlob read GetBlob;
    function Update: boolean;
    function Insert: boolean;
    function Delete: boolean;
    property IntID: int64 read GetIntID;
    property ID: string read FID write FID;
  published
    property OnInsert: TNotifyEvent read FOnInsert write FOnInsert;
    property OnUpdate: TNotifyEvent read FOnUpdate write FOnUpdate;
    property OnDelete: TNotifyEvent read FOnDelete write FOnDelete;
  end;

  TmyCloudDataEntities = class(TOwnedCollection)
  private
    FTable: TMyCloudDataTable;
    function GetItems(Index: Integer): TMyCloudDataEntity;
    procedure SetItems(Index: Integer; const Value: TMyCloudDataEntity);
  protected
    property Table: TMyCloudDataTable read FTable write FTable;
  public
    constructor Create(AOwner: TPersistent); reintroduce;
    function Add: TMyCloudDataEntity; reintroduce;
    function Insert(Index: Integer): TMyCloudDataEntity; reintroduce;
    property Items[Index: Integer]: TMyCloudDataEntity read GetItems write SetItems; default;
  end;

  TmyCloudData = class(TRESTClient)
  private
    FTables: TmyCloudDataTables;
    FOnTables: TNotifyEvent;
    procedure SetTables(const Value: TmyCloudDataTables);
  protected
    procedure HandleTestTokens(Sender: TObject; AResponse: string; var Handled: boolean);
    procedure HandleGetTables(Sender: TObject; AResponse: string; var Handled: boolean);
    function GetAuthURL: string; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure TestTokens; override;
    procedure GetTables;
    property Tables: TmyCloudDataTables read FTables write SetTables;
    property OnTables: TNotifyEvent read FOnTables write FOnTables;
  end;

  TWebmyCloudData = class(TmyCloudData);

implementation

uses
  WebLib.JSON;

{ TmyCloudData }

constructor TmyCloudData.Create(AOwner: TComponent);
begin
  inherited;
  APIBase := 'https://api.myclouddata.net/v2';
  FTables := TmyCloudDataTables.Create(Self);
end;

destructor TmyCloudData.Destroy;
begin
  FTables.Free;
  inherited;
end;

function TmyCloudData.GetAuthURL: string;
begin
  Result := 'https://api.myclouddata.net/login.html#?client_id=' + App.Key
       + '&redirect_uri=' + encodeURIComponent(App.CallbackURL)
       + '&response_type=token'
       + '&scope='
       + '&state=profile';
end;

procedure TmyCloudData.GetTables;
var
  URL: string;
begin
  // now query tables
  URL := 'https://api.myclouddata.net/schema/table';
  OnHttpResponse := HandleGetTables;
  HttpsGet(URL);
end;

procedure TmyCloudData.HandleGetTables(Sender: TObject; AResponse: string;
  var Handled: boolean);
var
  js: TJSON;
  ja: TJSONArray;
  jo: TJSONObject;
  i: integer;
  tbl: TmyCloudDataTable;

begin
  js := TJSON.Create;

  try
    //ShowMessage(AResponse);
    ja := TJSONArray(js.Parse(AResponse));

    FTables.Clear;

    for i := 0 to ja.Count - 1 do
    begin
      jo := TJSONObject(ja.Items[i]);
      tbl := FTables.Add;
      tbl.TableName := jo.GetJSONValue('TableName');
      tbl.TableID := jo.GetJSONValue('TableID');
    end;

    if Assigned(OnTables) then
      OnTables(Self);
  finally
    js.Free;
  end;
end;

procedure TmyCloudData.HandleTestTokens(Sender: TObject; AResponse: string;
  var Handled: boolean);
var
  js: TJSON;
  ja: TJSONArray;
begin
  js := TJSON.Create;

  try
    ja := TJSONArray(js.Parse(AResponse));

    if Assigned(ja) then
      if Assigned(OnAccessToken) then
        OnAccessToken(Self)
      else
        DoAuth;
  finally
  end;
end;

procedure TmyCloudData.SetTables(const Value: TmyCloudDataTables);
begin
  FTables := Value;
end;

procedure TmyCloudData.TestTokens;
var
  URL: string;
begin
  inherited;
  URL := APIBase + '/users/me';

  URL := 'https://api.myclouddata.net/schema/table';
  OnHttpResponse := HandleTestTokens;
  HttpsGet(URL);
end;

{ TmyCloudDataTables }

function TmyCloudDataTables.Add: TmyCloudDataTable;
begin
  Result := TmyCloudDataTable(inherited Add);
end;

constructor TmyCloudDataTables.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, TmyCloudDataTable);
  FmyCloudData := (AOwner as TMyCloudData);
end;

function TmyCloudDataTables.GetItem(Index: integer): TmyCloudDataTable;
begin
  Result := TmyCloudDataTable(inherited Items[Index]);
end;

function TmyCloudDataTables.Insert(Index: integer): TmyCloudDataTable;
begin
  Result := TmyCloudDataTable(inherited Insert(Index));
end;

procedure TmyCloudDataTables.SetItem(Index: integer;
  const Value: TmyCloudDataTable);
begin
  inherited Items[Index] := Value;
end;

{ TmyCloudDataTable }

constructor TmyCloudDataTable.Create(Collection: TCollection);
begin
  inherited;
  FMetaData := TmyCloudDataMetaData.Create(Self);
  FEntities := TmyCloudDataEntities.Create(Self);
  FmyCloudData := (Collection as TmyCloudDataTables).FmyCloudData;
end;

destructor TmyCloudDataTable.Destroy;
begin
  FMetaData.Free;
  FEntities.Free;
  inherited;
end;


procedure TmyCloudDataTable.GetEntities;
var
  URL,postdata: string;
begin
  if MetaData.Count = 0 then
  begin
    FEntityQuery := true;
    GetMetaData;
  end
  else
  begin
    URL := 'https://api.myclouddata.net/v2/data/tablefilter';
    FmyCloudData.OnHttpResponse := HandleGetEntities;
    postdata := '{ "tableid":"' + TableID +  '"}';
    FmyCloudData.HttpsPost(URL,'application/json;charset=UTF-8', postdata);
  end;
end;

procedure TmyCloudDataTable.GetMetaData;
var
  URL: string;
begin
  URL := 'https://api.myclouddata.net/v2/schema/table/field?tableid=' + TableID;
  FmyCloudData.OnHttpResponse := HandleGetMetaData;
  FmyCloudData.HttpsGet(URL);
end;

procedure TmyCloudDataTable.HandleGetEntities(Sender: TObject;
  AResponse: string; var Handled: boolean);
var
  js: TJSON;
  ja: TJSONArray;
  jo: TJSONObject;
  i,j: integer;
  ent: TmyCloudDataEntity;
  fldname: string;
begin
  js := TJSON.Create;

  try
    //ShowMessage(aresponse);
    ja := TJSONArray(js.Parse(AResponse));

    Entities.Clear;

    for i := 0 to ja.Count - 1 do
    begin
      jo := TJSONObject(ja.Items[i]);

      ent := Entities.Add;
      ent.ID := jo.GetJSONValue('_ID');

      for j := 1 to MetaData.Count - 1 do
      begin
        fldname := MetaData.Items[j].FieldName;
        ent.Value[fldname] := jo.GetJSONValue(fldname);
      end;

      //ent.Value['Brand'] := jo.Get('Brand');
      //ent.Value['Type'] := jo.Get('Type');
    end;

    if Assigned(OnEntities) then
      OnEntities(Self);

  finally
    js.Free;
  end;
end;

procedure TmyCloudDataTable.HandleGetMetaData(Sender: TObject;
  AResponse: string; var Handled: boolean);
var
  js: TJSON;
  ja: TJSONArray;
  jo: TJSONObject;
  i: integer;
  meta: TMyCloudDataMetaDataItem;
  dtype: string;
begin
  js := TJSON.Create;

  try
    //ShowMessage(AResponse);
    ja := TJSONArray(js.Parse(AResponse));

    MetaData.Clear;

    for i := 0 to ja.Count - 1 do
    begin
      jo := TJSONObject(ja.Items[i]);

      meta := MetaData.Add;
      meta.FFieldName := TRestClient.GetJSONProp(jo,'column_name');
      meta.FSize := TRestClient.GetJSONInt(jo,'character_maximum_length');

      dtype := TRESTClient.GetJSONProp(jo, 'data_type');

      if dtype = 'bigint' then
        meta.FDataType := ftLargeint;

      if dtype = 'int' then
        meta.FDataType := ftInteger;

      if dtype = 'float' then
        meta.FDataType := ftFloat;

      if dtype = 'nvarchar' then
        meta.FDataType := ftWideString;

      if dtype = 'bit' then
        meta.FDataType := ftBoolean;

      if dtype = 'datetime' then
        meta.FDataType := ftDateTime;

      if dtype = 'date' then
        meta.FDataType := ftDate;

      if dtype = 'time' then
        meta.FDataType := ftTime;

      if dtype = 'varbinary' then
        meta.FDataType :=  ftBlob;
    end;

    if FEntityQuery then
    begin
      FEntityQuery := false;
      GetEntities;
    end
    else
    begin
      if Assigned(OnMetaData) then
        OnMetaData(Self);
    end;

  finally
    js.Free;
  end;
end;

{ TMyCloudDataMetaData }

function TMyCloudDataMetaData.Add(PropertyName: string; DataType: TFieldType;
  Size: integer): TMyCloudDataMetaDataItem;
begin
  Result := TMyCloudDataMetaDataItem(inherited Add);
  Result.FFieldName := PropertyName;
  Result.FDataType := DataType;
  Result.FSize := Size;
end;

function TMyCloudDataMetaData.Add: TMyCloudDataMetaDataItem;
begin
  Result := TMyCloudDataMetaDataItem(inherited Add);
end;

constructor TMyCloudDataMetaData.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, TMyCloudDataMetaDataItem);
end;

function TMyCloudDataMetaData.GetItems(
  Index: Integer): TMyCloudDataMetaDataItem;
begin
  Result := TMyCloudDataMetaDataItem(inherited Items[Index]);
end;

function TMyCloudDataMetaData.GetMetaDataItemByName(
  AName: string): TMyCloudDataMetaDataItem;
begin
  Result := nil;
end;

function TMyCloudDataMetaData.Insert(Index: Integer): TMyCloudDataMetaDataItem;
begin
  Result := TMyCloudDataMetaDataItem(inherited Insert(Index));
end;

procedure TMyCloudDataMetaData.SetItems(Index: Integer;
  const Value: TMyCloudDataMetaDataItem);
begin
  inherited Items[Index] := Value;
end;

{ TmyCloudDataEntity }

procedure TmyCloudDataEntity.Assign(Source: TPersistent);
begin
  inherited;
end;

constructor TmyCloudDataEntity.Create(Collection: TCollection);
begin
  inherited;
  FValues := TStringList.Create;
  FTable := TmyCloudDataEntities(Collection).FTable;
end;

function TmyCloudDataEntity.Delete: boolean;
var
  URL: string;
begin
  Result := false;

  URL := Table.FmyCloudData.APIBase + '/data/table?tableid=' + Table.TableID + '&recordid=' + ID;

  Table.FmyCloudData.OnHttpResponse := HandleDelete;
  Table.FmyCloudData.HttpsDelete(URL);
end;

destructor TmyCloudDataEntity.Destroy;
begin
  FValues.Free;
  inherited;
end;

function TmyCloudDataEntity.GetBlob(AName: string): TMyCloudDataBlob;
begin
  Result := FBlob;
end;

function TmyCloudDataEntity.GetIntID: int64;
begin
  Result := StrToInt('');
end;

function TmyCloudDataEntity.GetValue(AName: string): string;
begin
  Result := FValues.Values[AName];
end;

procedure TmyCloudDataEntity.HandleDelete(Sender: TObject; AResponse: string;
  var Handled: boolean);
begin
  Collection.Delete(Self.Index);
  if Assigned(OnDelete) then
    OnDelete(Self);
end;

procedure TmyCloudDataEntity.HandleInsert(Sender: TObject; AResponse: string;
  var Handled: boolean);
var
  js: TJSON;
  jo: TJSONObject;
begin
  js := TJSON.Create;

  try
    jo := TJSONObject(js.Parse(AResponse));
    ID := jo.GetJSONValue('_ID');
  finally
    js.Free;
  end;

  if Assigned(OnInsert) then
    OnInsert(Self);
end;

procedure TmyCloudDataEntity.HandleUpdate(Sender: TObject; AResponse: string;
  var Handled: boolean);
begin
  if Assigned(OnUpdate) then
    OnUpdate(Self);
end;

function EscapeJSON(str: string): string;
begin
  Result := str;
  Result := stringreplace(Result,'\', '\\', [rfReplaceAll]);
  Result := stringreplace(Result,'"', '\"', [rfReplaceAll]);
  Result := stringreplace(Result,#13, '\r', [rfReplaceAll]);
  Result := stringreplace(Result,#9, '\t', [rfReplaceAll]);
end;

function TmyCloudDataEntity.Insert: boolean;
var
  URL: string;
  postdata, pair: string;
  i: Integer;
begin
  Result := false;

  url := Table.FmyCloudData.APIBASE + '/data/table';

  postdata := '{'
        + '"tableid": ' + Table.TableID + ', '
        + '"fields": {';

  for i := 0 to FValues.Count - 1 do
  begin
    pair := '"' + FValues.Names[i] +'":"' + EscapeJSON(FValues.ValueFromIndex[i])+'"';

    if i > 0 then
      postdata := postdata + ',';

    postdata := postdata + pair;
  end;

  postdata := postdata + '}}';


  Table.FmyCloudData.OnHttpResponse := HandleInsert;

  Table.FmyCloudData.HttpsPost(URL, 'application/json;charset=UTF-8', postdata);
end;

procedure TmyCloudDataEntity.SetValue(AName: string; const Value: string);
begin
  FValues.Values[AName] := Value;
end;

function TmyCloudDataEntity.Update: boolean;
var
  URL: string;
  postdata, pair: string;
  i: Integer;
begin
  Result := false;

  url := Table.FmyCloudData.APIBASE + '/data/table';

  postdata := '{'
        + '"tableid": ' + Table.TableID + ', '
        + '"fields": {';


  pair := '"_ID":"' + ID +'"';
  postdata := postdata + pair;

  for i := 0 to FValues.Count - 1 do
  begin
    pair := '"' + FValues.Names[i] +'":"' + EscapeJSON(FValues.ValueFromIndex[i])+'"';

    postdata := postdata + ',' + pair;
  end;

  postdata := postdata + '}}';


  Table.FmyCloudData.OnHttpResponse := HandleUpdate;

  Table.FmyCloudData.HttpsPut(URL, 'application/json;charset=UTF-8', postdata);

end;

{ TmyCloudDataEntities }

function TmyCloudDataEntities.Add: TMyCloudDataEntity;
begin
  Result := TMyCloudDataEntity(inherited Add);
end;

constructor TmyCloudDataEntities.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, TmyCloudDataEntity);
  FTable := TmyCloudDataTable(AOwner);
end;

function TmyCloudDataEntities.GetItems(Index: Integer): TMyCloudDataEntity;
begin
  Result := TMyCloudDataEntity(inherited Items[Index]);
end;

function TmyCloudDataEntities.Insert(Index: Integer): TMyCloudDataEntity;
begin
  Result := TMyCloudDataEntity(inherited Insert(Index));
end;

procedure TmyCloudDataEntities.SetItems(Index: Integer;
  const Value: TMyCloudDataEntity);
begin
  inherited Items[index] := Value;
end;

{ TMyCloudDataMetaDataItem }

constructor TMyCloudDataMetaDataItem.Create(Collection: TCollection);
begin
  inherited;

end;

destructor TMyCloudDataMetaDataItem.Destroy;
begin

  inherited;
end;


end.
