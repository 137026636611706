unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.REST, WEBLib.myCloudData, WEBLib.ExtCtrls, WEBLib.Buttons, Data.DB,
  WEBLib.MyCloudCDS, WEBLib.Login, WEBLib.DBCtrls, Vcl.Grids, WEBLib.DB,
  WEBLib.WebCtrls, WEBLib.ComCtrls, WEBLib.JSON, WEBLib.EditAutocomplete,
  Vcl.Imaging.jpeg, WEBLib.CDS, WEBLib.SQLCDS, WEBLib.Grids;

type
  TForm1 = class(TForm)
    WebMyCloudDbClientDataset: TMyCloudDbClientDataset;
    WebDataSource: TDataSource;
    WebPageControl: TPageControl;
    WPCInloggen: TTabSheet;
    WPCDatabase: TTabSheet;
    WebLoginPanel: TLoginPanel;
    WebPanel1: TPanel;
    lblGebGebruiker: TLabel;
    lblGebWachtwoord: TLabel;
    lblGebNaam: TLabel;
    VeldGebGebruiker: TEdit;
    VeldGebWachtwoord: TEdit;
    VeldGebNaam: TEdit;
    GebbtnAdd: TBitBtn;
    GebbtnUpdate: TBitBtn;
    GebbtnDelete: TBitBtn;
    GebDBGrid: TDBGrid;
    GebDBNavigator: TDBNavigator;
    GebDoeEntities: TButton;
    GebListBox1: TListBox;
    GebListBox2: TListBox;
    GebRefresh: TButton;
    VulBox: TButton;
    Basis: TPanel;
    lblGebFunctie: TLabel;
    lblGebLocatie: TLabel;
    VeldGebFunctie: TEdit;
    VeldGebLocatie: TEdit;
    VeldGebActief: TCheckBox;
    OnderdelenControl: TPageControl;
    PageGebruikers: TTabSheet;
    PageLocaties: TTabSheet;
    PageOpdrachten: TTabSheet;
    LocListBox1: TListBox;
    LocListBox2: TListBox;
    WebPanel2: TPanel;
    lblLocLocatie: TLabel;
    lblLocAdres: TLabel;
    lblLocContactpersoon: TLabel;
    lblLocEmailadres: TLabel;
    VeldLocLocatie: TEdit;
    VeldLocAdres: TEdit;
    VeldLocContactpersoon: TEdit;
    LocbtnAdd: TBitBtn;
    LocbtnUpdate: TBitBtn;
    LocbtnDelete: TBitBtn;
    LocDbGrid: TDBGrid;
    LocDBNavigator: TDBNavigator;
    LocRefresh: TButton;
    VeldLocEmailadres: TEdit;
    VeldLocActief: TCheckBox;
    LocDoeEntities: TButton;
    WebGroupBox1: TGroupBox;
    lblBeheerder: TLabel;
    lblBesteller: TLabel;
    lblUitvoerder: TLabel;
    lblControleur: TLabel;
    GebActief: TCheckBox;
    LocActief: TCheckBox;
    SoortBieb: TRadioGroup;
    SoortFunctie: TCheckGroup;
    OpdActief: TCheckBox;
    OpdListBox2: TListBox;
    OpdListBox1: TListBox;
    OpdDoeEntities: TButton;
    WebPanel3: TPanel;
    OpdbtnAdd: TBitBtn;
    OpdbtnUpdate: TBitBtn;
    OpdbtnDelete: TBitBtn;
    lblOpdLocatie: TLabel;
    VeldOpdLocatie: TEdit;
    VeldOpdContactpersoon: TEdit;
    lblOpdContactpersoon: TLabel;
    lblOpdracht: TLabel;
    OpdOpdrachtDate: TDateTimePicker;
    lblOpdDatum: TLabel;
    OpdStatus: TRadioGroup;
    VeldOpdActief: TCheckBox;
    lblFilteren: TLabel;
    FilterBox: TComboBox;
    OpdOpdracht: TMemo;
    OpdbtnNieuw: TBitBtn;
    OpdLogo: TImageControl;
    GebLogo: TImageControl;
    LocLogo: TImageControl;
    OpdNaam: TLabel;
    LocNaam: TLabel;
    GebNaam: TLabel;
    WebMessageDlg1: TMessageDlg;
    OpdMetaDataCheck: TCheckBox;
    lblReactie: TLabel;
    OpdReactie: TMemo;
    lblReactieDatum: TLabel;
    OpdReactieDate: TDateTimePicker;
    lblVerslag: TLabel;
    OpdVerslag: TMemo;
    lblGeplandDatum: TLabel;
    OpdGeplandDate: TDateTimePicker;
    lblVerslagDatum: TLabel;
    OpdVerslagDate: TDateTimePicker;
    lblAlgemeneOpmerkingen: TLabel;
    OpdAlgOpm: TMemo;
    GebCR: TLabel;
    LocCR: TLabel;
    OpdCR: TLabel;
    GebToonWW: TCheckBox;
    lblInloggerOpd: TLabel;
    NaamInloggerOpd: TLabel;
    lblInloggerGeb: TLabel;
    NaamInloggerGeb: TLabel;
    lblInloggerLoc: TLabel;
    NaamInloggerLoc: TLabel;
    CloudAlles: TmyCloudData;
    Actief: TButton;
    lblTeller: TLabel;
    ReserveTekst: TMemo;
    lblOpdrachtgever: TLabel;
    VeldOpdOpdrachtgever: TComboBox;

    procedure GebListBox2Change(Sender: TObject);
    procedure GebbtnAddClick(Sender: TObject);
    procedure GebbtnUpdateClick(Sender: TObject);
    procedure GebbtnDeleteClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);

    procedure WebLogin(Sender: TObject);

    procedure GebDBGridFixedCellClick(Sender: TObject; ACol, ARow: Integer);

    procedure GebToonEntities(Sender: TObject);

    procedure SchakelDBin(Sender: TObject);
    procedure WebMyCloudDbClientDatasetAfterOpen(DataSet: TDataSet);
    procedure VulBoxClick(Sender: TObject);
    procedure CloudDataResponse(Sender: TObject; AResponse: string);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure GebDBGridClickCell(Sender: TObject; ACol, ARow: Integer);

    procedure LocListBox2Change(Sender: TObject);
    procedure LocbtnAddClick(Sender: TObject);

    procedure GebRefreshClick(Sender: TObject);
    procedure LocRefreshClick(Sender: TObject);
    procedure OpdRefreshClick(Sender: TObject);

    procedure LocbtnUpdateClick(Sender: TObject);
    procedure LocbtnDeleteClick(Sender: TObject);
    procedure LocListBox2Click(Sender: TObject);
    procedure GebListBox2Click(Sender: TObject);

    procedure GebDoeEntitiesClick(Sender: TObject);
    procedure LocDoeEntitiesClick(Sender: TObject);

    procedure GebShowTables;
    procedure LocShowTables;
    procedure OpdShowTables;

    procedure LocToonEntities(Sender: TObject);

    procedure OpdToonEntities(Sender: TObject);

    procedure GebShowEntities(Sender: TObject);
    procedure LocShowEntities(Sender: TObject);
    procedure OpdShowEntities(Sender: TObject);

    procedure GebGotmcdEntityInsert(Sender: TObject);
    procedure GebGotmcdEntityUpdate(Sender: TObject);
    procedure GebGotmcdEntityDelete(Sender: TObject);

    procedure LocGotmcdEntityInsert(Sender: TObject);
    procedure LocGotmcdEntityUpdate(Sender: TObject);
    procedure LocGotmcdEntityDelete(Sender: TObject);

    procedure OpdGotmcdEntityInsert(Sender: TObject);
    procedure OpdGotmcdEntityUpdate(Sender: TObject);
    procedure OpdGotmcdEntityDelete(Sender: TObject);

    procedure OnderdelenControlChange(Sender: TObject);
    procedure ActiveerGebruikersClick(Sender: TObject);
    procedure ActiveerLocatiesClick(Sender: TObject);
    procedure GebActiefClick(Sender: TObject);
    procedure LocActiefClick(Sender: TObject);
    procedure ActiveerOpdrachtenClick(Sender: TObject);
    procedure OpdDoeEntitiesClick(Sender: TObject);

    procedure OpdListBox2Change(Sender: TObject);
    procedure OpdListBox2Click(Sender: TObject);

    procedure OpdbtnAddClick(Sender: TObject);
    procedure OpdActiefClick(Sender: TObject);
    [async]
    procedure OpdbtnUpdateClick(Sender: TObject); async;
    [async]
    procedure OpdbtnDeleteClick(Sender: TObject); async;
    procedure FilterBoxChange(Sender: TObject);
    procedure OpdbtnNieuwClick(Sender: TObject);
    procedure OpdStatusChange(Sender: TObject);

    procedure OpdMetaDataCheckClick(Sender: TObject);
    procedure OpdToonMetaData(Sender: TObject);
    procedure GebToonWWClick(Sender: TObject);
    procedure CloudAllesAccessToken(Sender: TObject);
    procedure CloudAllesResponse(Sender: TObject; AResponse: string);
    procedure ActiefClick(Sender: TObject);

  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;
  Toegang, GebZetTabel, GebToonTabel, LocZetTabel, OpdZetTabel, LocToonTabel, Start, TweedeSet: Integer;
  Teller, GebStopper, LocStopper, OpdStopper, StatusWas, DeleteUitvoeren :Integer;
  ILBeheerder, ILBesteller, ILUitvoerder, ILControleur: Integer;
  URLString, APPKeyString, APPSecretString, BewaarTekst: String;

implementation

{$R *.dfm}

uses Math;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.WebFormCreate(Sender: TObject);
begin
 If (Form1.Width > 1200) Then Basis.Left := (Form1.Width - Basis.Width) Div 2 Else Basis.Left := 0;

 WebLoginPanel.Left := Round((Basis.Width - WebLoginPanel.Width) Div 2);

 GebCR.HTML := Chr(169)+' Copyright (2022) J den Braber';
 LocCR.HTML := Chr(169)+' Copyright (2022) J den Braber';
 OpdCR.HTML := Chr(169)+' Copyright (2022) J den Braber';

 NaamInloggerGeb.HTML := '';
 NaamInloggerLoc.HTML := '';
 NaamInloggerOpd.HTML := '';
 lblTeller.Visible := False;

 //URLString := 'http://localhost:8000/klussenbeheer/klussenbeheer.html';
 URLString := 'http://klussenbeheer.nl/klussenbeheer.html';

 APPKeyString := '500f8b04e39094b5f0ac8e9f5edd1a31d8092ff9d87b';
 //APPKeyString := '';

 //APPSecretString := '480f9116f4c19bbbf0a58e8450db0535dd1e21f4';
 APPSecretString := '';

 Toegang := 0;
 Start := 0;
 TweedeSet := 0;
 Teller := 0;
 StatusWas := -1;

 lblTeller.HTML := '';

 GebZetTabel := 0;
 LocZetTabel := 0;
 OpdZetTabel := 0;

 OpdbtnNieuw.Enabled := False;
 OpdbtnAdd.Enabled := False;
 OpdbtnUpdate.Enabled := False;
 OpdbtnDelete.Enabled := False;

 WebPageControl.TabIndex := 0;
 OnderdelenControl.ShowTabs := True;
 OnderdelenControl.TabIndex := 0;

 PageGebruikers.TabVisible := False;
 PageLocaties.TabVisible := False;
 PageOpdrachten.TabVisible := False;

 ActiefClick(Self);
 WebLogin(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebDBGridClickCell(Sender: TObject; ACol, ARow: Integer);
begin
 If TweedeSet = 0 Then Exit;

 If ARow < 1 Then Exit;

 GebListBox2.ItemIndex := ARow - 1;
 GebListBox2Change(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebDBGridFixedCellClick(Sender: TObject; ACol, ARow: Integer);
var LIndex: string;
    desc: boolean;
    opt: TIndexOptions;
    DBCol: integer;
begin
  If TweedeSet = 0 Then Exit;

  if ACol < GebDBGrid.FixedCols then Exit;

  DBCol := ACol - GebDBGrid.FixedCols;

  Lindex := 'By '+ GebDBGrid.Columns[DBCol].DataField;

  // toggle sort order when the same column is clicked
  desc := SameText(WebMyCloudDbClientDataset.ActiveIndex, LIndex);

  if desc then LIndex := LIndex + 'Desc';

  opt := [];

  if desc then opt := opt + [ixDescending];

  // clear any previous indexes and add the new index to sort
  WebMyCloudDbClientDataset.Indexes.Clear;
  WebMyCloudDbClientDataset.Indexes.Add(LIndex, GebDBGrid.Columns[DBCol].DataField, opt);
  WebMyCloudDbClientDataset.ActiveIndex := LIndex;

  // set the sort indicator in the grid column header
  if ixDescending in WebMyCloudDbClientDataset.Indexes.Find(Lindex).Options then
    GebDBGrid.Columns[DBCol].SortIndicator := siDescending
  else
    GebDBGrid.Columns[DBCol].SortIndicator := siAscending;
  {
  WebDbGrid.Columns[0].Width := 60;
  WebDbGrid.Columns[1].Width := 150;
  WebDbGrid.Columns[2].Width := 150;
  WebDbGrid.Columns[3].Width := 150;
  }
  GebDBGrid.Refresh;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebShowTables;
var i: integer;
begin
 GebListBox1.Items.Clear;

 for i := 0 to CloudAlles.Tables.Count - 1 do
 begin
  If CloudAlles.Tables.Items[i].TableName = 'BOGebruikers' Then
   GebListBox1.Items.Add(CloudAlles.Tables.Items[i].TableName + ' - ' + CloudAlles.Tables.Items[i].TableID);
 end;

 If GebListbox1.Items.Count > 0 Then
 Begin
  GebListbox1.ItemIndex := 0;
  GebDoeEntitiesClick(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocShowTables;
var i: integer;
begin
 LocListBox1.Items.Clear;

 for i := 0 to CloudAlles.Tables.Count - 1 do
 begin
  If CloudAlles.Tables.Items[i].TableName = 'BOLocaties' Then
   LocListBox1.Items.Add(CloudAlles.Tables.Items[i].TableName+' - ' + CloudAlles.Tables.Items[i].TableID);
 end;

 If LocListBox1.Items.Count > 0 Then
 Begin
  LocListBox1.ItemIndex := 0;
  LocDoeEntitiesClick(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdShowTables;
var i: integer;
begin
 OpdListBox1.Items.Clear;

 for i := 0 to CloudAlles.Tables.Count - 1 do
 begin
  If CloudAlles.Tables.Items[i].TableName = 'BOOpdrachten' Then
   OpdListBox1.Items.Add(CloudAlles.Tables.Items[i].TableName+' - ' + CloudAlles.Tables.Items[i].TableID);
 end;

 If OpdListBox1.Items.Count > 0 Then
 Begin
  OpdListBox1.ItemIndex := 0;
  OpdDoeEntitiesClick(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebDoeEntitiesClick(Sender: TObject);
var tblGeb: TmyCloudDataTable;
begin
 if GebListBox1.ItemIndex >= 0 then
 Begin
  //tblGeb := CloudAlles.Tables.Items[0];
  tblGeb := CloudAlles.Tables.Items[0];

  //tblGeb.OnEntities := GebToonEntities;
  //tblGeb.GetEntities;

  GebToonEntities(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocDoeEntitiesClick(Sender: TObject);
var tblLoc: TmyCloudDataTable;
begin
 if LocListBox1.ItemIndex >= 0 then
 Begin
  //tblLoc := CloudAlles.Tables.Items[1];

  //tblLoc.OnEntities := LocToonEntities;
  //tblLoc.GetEntities;

  LocToonEntities(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdDoeEntitiesClick(Sender: TObject);
var tblOpd: TmyCloudDataTable;
begin
 if OpdListBox1.ItemIndex >= 0 then
 Begin
  //tblOpd := CloudAlles.Tables.Items[2];

  //tblOpd.OnEntities := OpdToonEntities;
  //tblOpd.GetEntities;

  OpdToonEntities(Self);
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebToonEntities(Sender: TObject);
begin
 GebShowEntities(Self);

 if GebListbox1.Items.Count >= 0 then
 begin
  GebListbox1.ItemIndex := 0;
  GebListBox2Change(Self);
 end;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocToonEntities(Sender: TObject);
begin
 LocShowEntities(Self);

 if LocListbox1.Items.Count >= 0 then
 begin
  LocListbox1.ItemIndex := 0;
  LocListBox2Change(Self);
 end;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdToonEntities(Sender: TObject);
begin
 OpdShowEntities(Self);

 if OpdListbox1.Items.Count >= 0 then
 begin
  OpdListbox1.ItemIndex := 0;
  OpdListBox2Change(Self);
 end;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebActiefClick(Sender: TObject);
begin
 GebStopper := 1;
 GebShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebShowEntities(Sender: TObject);
var i,idx, ZoekDP, EersteDubbele, loper: integer;
    tblGeb: TmyCloudDataTable;
    Origineel, Dubbele: String;
begin
 if GebListbox1.ItemIndex >= 0 then
 begin

  tblGeb := CloudAlles.Tables.Items[0];

  If GebStopper = 1 Then
  Begin
   tblGeb.GetEntities;
   If tblGeb.Entities.Count > 0 Then GebStopper := 0;
  End;

  if tblGeb.MetaData.Count >= 5 then
  begin
   idx := GebListBox2.ItemIndex;
   GebListBox2.Items.Clear;

   for i := 0 to tblGeb.Entities.Count - 1 do
   begin
    If GebActief.Checked = True Then
    Begin
     If tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[6].FieldName] = 'Ja' Then
     Begin
      GebListbox2.Items.Add(tblGeb.Entities.Items[i].Index.ToString + ': ' +
                            tblGeb.Entities.Items[i].ID+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[6].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[1].FieldName]+' - ' +
       //WebListbox2.Items.Add(tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[1].FieldName]+' - ' +
                            //tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[2].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[3].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[4].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[5].FieldName]);
     End;
    End
    Else
     Begin
      GebListbox2.Items.Add(tblGeb.Entities.Items[i].Index.ToString + ': ' +
                            tblGeb.Entities.Items[i].ID+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[6].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[1].FieldName]+' - ' +
       //WebListbox2.Items.Add(tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[1].FieldName]+' - ' +
                            //tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[2].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[3].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[4].FieldName]+' - ' +
                            tblGeb.Entities.Items[i].Value[tblGeb.MetaData.Items[5].FieldName]);
     End;
   end;

   GebListbox2.ItemIndex := Max(idx,0);
  end;

  If GebListBox2.Items.Count > 1 Then
  Begin
   //Stel eerste Entitie vast
   ZoekDP := Pos(':', GebListBox2.Items[0]);
   If ZoekDP > 0 Then Origineel := Copy(GebListBox2.Items[0], ZoekDP + 2, Length(GebListBox2.Items[0]) - ZoekDP - 1);

   //Is er mogelijk een Dubbele?
   If (GebListBox2.Items.Count MOD 2) = 0 Then
   Begin
    EersteDubbele := (GebListBox2.Items.Count DIV 2);

    //Stel eerste Dubbele vast
    ZoekDP := Pos(':', GebListBox2.Items[EersteDubbele]);
    If ZoekDP > 0 Then Dubbele := Copy(GebListBox2.Items[EersteDubbele], ZoekDP + 2, Length(GebListBox2.Items[EersteDubbele]) - ZoekDP - 1);

    //Controleer of er echt een dubbele is, zo ja verwijder de tweede helft
    If Dubbele = Origineel Then
    Begin
     For loper := GebListBox2.Items.Count - 1 DownTo EersteDubbele Do
     GebListBox2.Items.Delete(loper);
    End;

   End;
  End;

 end;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocActiefClick(Sender: TObject);
begin
 LocStopper := 1;
 LocShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocShowEntities(Sender: TObject);
var i,idx, ZoekDP, EersteDubbele, loper: integer;
    tblLoc: TmyCloudDataTable;
    Origineel, Dubbele: String;
Begin
 if LocListbox1.ItemIndex >= 0 then
 begin
  tblLoc := CloudAlles.Tables.Items[1];

  If LocStopper = 1 Then
  Begin
   tblLoc.GetEntities;
   If tblLoc.Entities.Count > 0 Then LocStopper := 0;
  End;

  if tblLoc.MetaData.Count >= 5 then
  begin
   idx := LocListBox2.ItemIndex;
   LocListBox2.Items.Clear;

   for i := 0 to tblLoc.Entities.Count - 1 do
   begin
    If LocActief.Checked = True Then
    Begin
     If tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[5].FieldName] = 'Ja' Then
     Begin
      LocListbox2.Items.Add(tblLoc.Entities.Items[i].Index.ToString + ': ' +
                            tblLoc.Entities.Items[i].ID+' - ' +
                            tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[5].FieldName]+' - ' +
                            tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[1].FieldName]+' - ' +
                            tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[2].FieldName]+' - ' +
                            tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[3].FieldName]+' - ' +
                            tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[4].FieldName]);
     end;
    End
    Else
    Begin
     LocListbox2.Items.Add(tblLoc.Entities.Items[i].Index.ToString + ': ' +
                           tblLoc.Entities.Items[i].ID+' - ' +
                           tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[5].FieldName]+' - ' +
                           tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[1].FieldName]+' - ' +
                           tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[2].FieldName]+' - ' +
                           tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[3].FieldName]+' - ' +
                           tblLoc.Entities.Items[i].Value[tblLoc.MetaData.Items[4].FieldName]);
    end;
   end;

   LocListbox2.ItemIndex := Max(idx,0);
  end;

  If LocListBox2.Items.Count > 1 Then
  Begin
   //Stel eerste Entitie vast
   ZoekDP := Pos(':', LocListBox2.Items[0]);
   If ZoekDP > 0 Then Origineel := Copy(LocListBox2.Items[0], ZoekDP + 2, Length(LocListBox2.Items[0]) - ZoekDP - 1);

   //Is er mogelijk een Dubbele?
   If (LocListBox2.Items.Count MOD 2) = 0 Then
   Begin
    EersteDubbele := (LocListBox2.Items.Count DIV 2);

    //Stel eerste Dubbele vast
    ZoekDP := Pos(':', LocListBox2.Items[EersteDubbele]);
    If ZoekDP > 0 Then Dubbele := Copy(LocListBox2.Items[EersteDubbele], ZoekDP + 2, Length(LocListBox2.Items[EersteDubbele]) - ZoekDP - 1);

    //Controleer of er echt een dubbele is, zo ja verwijder de tweede helft
    If Dubbele = Origineel Then
    Begin
     For loper := LocListBox2.Items.Count - 1 DownTo EersteDubbele Do
     LocListBox2.Items.Delete(loper);
    End;

   End;
  End;

 end;
End;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdActiefClick(Sender: TObject);
begin
 OpdStopper := 1;
 OpdShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.FilterBoxChange(Sender: TObject);
begin
 OpdStopper := 1;
 OpdShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdShowEntities(Sender: TObject);
var i,idx, ZoekDP, EersteDubbele, loper, ZoekT, OpdrachtStat: integer;
    tblOpd: TmyCloudDataTable;
    Origineel, Dubbele: String;
    DatumOpdracht, OpdrachtStatus, Jaar, Maand, Dag, Tekst: String;
    Matchen: Boolean;
Begin
 if OpdListbox1.ItemIndex >= 0 then
 begin
  tblOpd := CloudAlles.Tables.Items[2];

  If OpdStopper = 1 Then
  Begin
   tblOpd.GetEntities;
   If tblOpd.Entities.Count > 0 Then OpdStopper := 0;
  End;

  if tblOpd.MetaData.Count >= 5 then
  begin
   idx := OpdListBox2.ItemIndex;
   OpdListBox2.Items.Clear;

   //Datum omzetten
   for i := 0 to tblOpd.Entities.Count - 1 do
   begin
    DatumOpdracht := tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[4].FieldName];
    If DatumOpdracht <> '' Then
    Begin
     ZoekT := Pos('T', DatumOpdracht);
     If ZoekT > 0 Then DatumOpdracht := Copy(DatumOpdracht, 1, ZoekT - 1);
     //Formaat Jaar - Maand- Dag is anders in Database dan in de Date component, dus herschikken.
     Jaar := Copy(DatumOpdracht, 1, 4);
     Maand := Copy(DatumOpdracht,6, 2);
     Dag := Copy(DatumOpdracht, 9, 2);
     DatumOpdracht := Dag + '-' + Maand + '-' + Jaar;
    End;

    //Status ombouwen
    OpdrachtStat := 0;
    If (tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[5].FieldName] = '0') Or
       (tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[5].FieldName] = 'null') Then OpdrachtStatus := ''
    Else
    Begin
     OpdrachtStat := StrToInt(tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[5].FieldName]);
     If OpdrachtStat In [1..4] Then OpdrachtStatus := OpdStatus.Items[OpdrachtStat - 1];
    End;

    //Eventueel Filteren op de Status van de Opdracht
    Matchen := True;
    If (FilterBox.ItemIndex In [1..4]) And (OpdrachtStat In [1..4]) Then
     If OpdrachtStat = FilterBox.ItemIndex Then Matchen := True Else Matchen := False;

    //Langere tekst van Opdracht inkorten
    Tekst := tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[3].FieldName];
    If Tekst = '' Then Tekst := 'Geen Opdracht.' Else If Length(Tekst) > 50 Then Tekst := Copy(Tekst, 1, 50);

    If OpdActief.Checked = True Then
    Begin
     If tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[11].FieldName] = 'Ja' Then
     Begin
      If Matchen = True Then
      OpdListbox2.Items.Add(tblOpd.Entities.Items[i].Index.ToString + ': ' +
                            tblOpd.Entities.Items[i].ID+' - ' +
                            tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[11].FieldName]+' - ' +
                            tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[1].FieldName]+' - ' +
                            tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[2].FieldName]+' - ' +
                            //tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[3].FieldName]+' - ' +
                            Tekst+' - '+
                            DatumOpdracht+' - '+ //dit is [4]
                            OpdrachtStatus); //dit is [5]
     end;
    End
    Else
    Begin
     If Matchen = True Then
     OpdListbox2.Items.Add(tblOpd.Entities.Items[i].Index.ToString + ': ' +
                           tblOpd.Entities.Items[i].ID+' - ' +
                           tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[11].FieldName]+' - ' +
                           tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[1].FieldName]+' - ' +
                           tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[2].FieldName]+' - ' +
                           //tblOpd.Entities.Items[i].Value[tblOpd.MetaData.Items[3].FieldName]+' - ' +
                           Tekst+' - '+
                           DatumOpdracht+' - '+ //dit is [4]
                           OpdrachtStatus); //dit is [5]
    End;
   end;
   OpdListbox2.ItemIndex := Max(idx,0);
  end;

  If OpdListBox2.Items.Count > 1 Then
  Begin
   //Stel eerste Entitie vast
   ZoekDP := Pos(':', OpdListBox2.Items[0]);
   If ZoekDP > 0 Then Origineel := Copy(OpdListBox2.Items[0], ZoekDP + 2, Length(OpdListBox2.Items[0]) - ZoekDP - 1);

   //Is er mogelijk een Dubbele?
   If (OpdListBox2.Items.Count MOD 2) = 0 Then
   Begin
    EersteDubbele := (OpdListBox2.Items.Count DIV 2);

    //Stel eerste Dubbele vast
    ZoekDP := Pos(':', OpdListBox2.Items[EersteDubbele]);
    If ZoekDP > 0 Then Dubbele := Copy(OpdListBox2.Items[EersteDubbele], ZoekDP + 2, Length(OpdListBox2.Items[EersteDubbele]) - ZoekDP - 1);

    //Controleer of er echt een dubbele is, zo ja verwijder de tweede helft
    If Dubbele = Origineel Then
    Begin
     For loper := OpdListBox2.Items.Count - 1 DownTo EersteDubbele Do
     OpdListBox2.Items.Delete(loper);
    End;

   End;
  End;

 end;
End;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.CloudAllesResponse(Sender: TObject; AResponse: string);
Var zoeker, loper, Wachter: Integer;
    tbl: TmyCloudDataTable;
    ent: TmyCloudDataEntity;
begin
 If CloudAlles.Tables.Count = 0 Then Exit;
 Inc(Teller);
 lblTeller.HTML := IntToStr(Teller);

 If Toegang = 0 Then
 Begin
  tbl := CloudAlles.Tables.Items[0];

  For zoeker := 0 To tbl.Entities.Count - 1 Do
  Begin
   ent := tbl.Entities[zoeker];
   If (ent.Value[tbl.MetaData.Items[1].FieldName] = WebLoginPanel.User) And (ent.Value[tbl.MetaData.Items[2].FieldName] = WebLoginPanel.Password) And
      (ent.Value[tbl.MetaData.Items[6].FieldName] = 'Ja') Then
   Begin
    Toegang := 1;
    if ent.Value[tbl.MetaData.Items[7].FieldName] = 'Ja' Then ILBeheerder := 1 Else ILBeheerder := 0;
    if ent.Value[tbl.MetaData.Items[8].FieldName] = 'Ja' Then ILBesteller := 1 Else ILBesteller := 0;
    if ent.Value[tbl.MetaData.Items[9].FieldName] = 'Ja' Then ILUitvoerder := 1 Else ILUitvoerder := 0;
    if ent.Value[tbl.MetaData.Items[10].FieldName] = 'Ja' Then ILControleur := 1 Else ILControleur := 0;
   End;
  End;
 End;

 //Onderstaande is om nogmaals op WebLoginPanel te klikken vanwege wachten op de Response
 If Toegang = 0 Then
 Begin
  If Start = 1 Then
  Begin
   WebLogin(Self);
   Start := 0;
  End;
 End;

 If Toegang = 1 Then
 Begin
  If (ILBeheerder = 1) Or (ILUitvoerder = 1) Then //Jan
  Case OnderdelenControl.ActivePageIndex Of
  0: GebShowTables;
  1: LocShowTables;
  2: OpdShowTables;
  End; {Case}

  If ILBesteller = 1 Then OpdShowTables; //Henny & Rick
  If ILControleur = 1 Then OpdShowTables; //Cathy
 End;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.WebLogin(Sender: TObject);
Var Zoeker: Integer;
    tbl, tblLoc, tblOpd: TmyCloudDataTable;
    ent: TmyCloudDataEntity;
begin
 ILBeheerder := 0;
 ILBesteller := 0;
 ILUitvoerder := 0;
 ILControleur := 0;
 Start := 1;

 If CloudAlles.Tables.Count = 0 Then Exit;

 Toegang := 0;

 tbl := CloudAlles.Tables.Items[0];
 tbl.GetEntities;

 For zoeker := 0 To tbl.Entities.Count - 1 Do
 Begin
  ent := tbl.Entities[zoeker];
  If (ent.Value[tbl.MetaData.Items[1].FieldName] = WebLoginPanel.User) And (ent.Value[tbl.MetaData.Items[2].FieldName] = WebLoginPanel.Password) And
     (ent.Value[tbl.MetaData.Items[6].FieldName] = 'Ja') Then
  Begin
   Toegang := 1;

   NaamInloggerGeb.HTML := ent.Value[tbl.MetaData.Items[3].FieldName];
   NaamInloggerLoc.HTML := ent.Value[tbl.MetaData.Items[3].FieldName];
   NaamInloggerOpd.HTML := ent.Value[tbl.MetaData.Items[3].FieldName];

   if ent.Value[tbl.MetaData.Items[7].FieldName] = 'Ja' Then ILBeheerder := 1 Else ILBeheerder := 0;
   if ent.Value[tbl.MetaData.Items[8].FieldName] = 'Ja' Then ILBesteller := 1 Else ILBesteller := 0;
   if ent.Value[tbl.MetaData.Items[9].FieldName] = 'Ja' Then ILUitvoerder := 1 Else ILUitvoerder := 0;
   if ent.Value[tbl.MetaData.Items[10].FieldName] = 'Ja' Then ILControleur := 1 Else ILControleur := 0;

   lblBeheerder.HTML := 'Beheerder: ' + IntToStr(ILBeheerder);
   lblBesteller.HTML := 'Besteller: ' + IntToStr(ILBesteller);
   lblUitvoerder.HTML := 'Uitvoerder: ' + IntToStr(ILUitvoerder);
   lblControleur.HTML := 'Controleur: ' + IntToStr(ILControleur);

   If (ILBeheerder = 1) Or (ILUitvoerder = 1) Then //Jan
   Begin
    OpdbtnNieuw.Enabled := True;
    OpdbtnAdd.Enabled := True;
    OpdbtnUpdate.Enabled := True;
    OpdbtnDelete.Enabled := True;
    OpdMetaDataCheck.Visible := True;

    OpdOpdracht.Enabled := True;
    OpdReactie.Enabled := True;
    OpdVerslag.Enabled := True;
    OpdAlgOpm.Enabled := True;

    OpdReactieDate.Enabled := True;
    OpdGeplandDate.Enabled := True;
    OpdVerslagDate.Enabled := True;
   End;

   If ILBesteller = 1 Then //Henny & Rick
   Begin
    tblOpd := CloudAlles.Tables.Items[2];
    OpdbtnNieuw.Enabled := True;
    OpdbtnAdd.Enabled := True;
    OpdbtnUpdate.Enabled := True;
    OpdbtnDelete.Enabled := False;

    OpdOpdracht.Enabled := True;
    OpdReactie.Enabled := False;
    OpdVerslag.Enabled := False;
    OpdAlgOpm.Enabled := True;

    OpdReactieDate.Enabled := False;
    OpdGeplandDate.Enabled := False;
    OpdVerslagDate.Enabled := False;
   End;

   If ILControleur = 1 Then //Cathy
   Begin
    tblOpd := CloudAlles.Tables.Items[2];
    OpdbtnNieuw.Enabled := False;
    OpdbtnAdd.Enabled := False;
    OpdbtnUpdate.Enabled := False;
    OpdbtnDelete.Enabled := False;
    //de Controleur mag niks veranderen aan de Status
    OpdStatus.Enabled := False;

    OpdOpdracht.Enabled := True;
    OpdReactie.Enabled := False;
    OpdVerslag.Enabled := False;
    OpdAlgOpm.Enabled := True;

    OpdReactieDate.Enabled := False;
    OpdGeplandDate.Enabled := False;
    OpdVerslagDate.Enabled := False;
   End;

  End;
 End;

 //WebLoginPanel.User := 'JanLaptop';
 //WebLoginPanel.Password := 'Camti67%@Camti67%@';

 If Toegang = 0 Then If (WebLoginPanel.User = 'JanLaptop') And (WebLoginPanel.Password = 'Camti67%@Camti67%@') Then
 Begin
  Toegang := 1;
  ILBeheerder := 1;
  ILBesteller := 0;
  ILUitvoerder := 1;
  ILControleur := 0;

  NaamInloggerGeb.HTML := 'Jan den Braber';
  NaamInloggerLoc.HTML := 'Jan den Braber';
  NaamInloggerOpd.HTML := 'Jan den Braber';

  OpdbtnNieuw.Enabled := True;
  OpdbtnAdd.Enabled := True;
  OpdbtnUpdate.Enabled := True;
  OpdbtnDelete.Enabled := True;
 End;

 //If (Toegang = 0) And (Start = 0)  Then ShowMessage('Gebruikersnaam en/of Wachtwoord onjuist.');
 //Toegang := 1;

 If Toegang = 1 Then
 Begin
  If Not ((ILBeheerder = 0) And (ILBesteller = 0) And (ILUitvoerder = 0) And (ILControleur = 0)) Then
  Begin //Als er geen enkele Functie of Rol aangevinkt is, mag een inlogger er niet inkomen.

   WPCDatabase.TabVisible := True;
   WPCDatabase.ShowCaption := True;
   WebPageControl.TabIndex := 1;
   WPCInloggen.TabVisible := False;
   WPCInloggen.ShowCaption := False;

   PageGebruikers.TabVisible := False;
   PageLocaties.TabVisible := False;
   PageOpdrachten.TabVisible := False;

   If ILBeheerder = 1 Then
   Begin
    PageGebruikers.TabVisible := True;
    PageLocaties.TabVisible := True;
    PageOpdrachten.TabVisible := True;
    OnderdelenControl.TabIndex := 0;
    OnderdelenControl.ActivePageIndex := 0;
   End
   Else
   Begin
    If ILBesteller = 1 Then
    Begin
     PageOpdrachten.TabVisible := True;
     OnderdelenControl.ActivePageIndex := 2;
    End;

    If ILUitvoerder = 1 Then
    Begin
     PageOpdrachten.TabVisible := True;
     OnderdelenControl.ActivePageIndex := 2;
    End;

    If ILControleur = 1 Then
    Begin
     PageOpdrachten.TabVisible := True;
     OnderdelenControl.ActivePageIndex := 2;
    End;
   End;

   OnderdelenControlChange(Self);
  End;
 End;

 //SchakelDBin(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.CloudDataResponse(Sender: TObject; AResponse: string);
Var zoeker, loper: Integer;
    tbl: TmyCloudDataTable;
    ent: TmyCloudDataEntity;

    Naam, Locatie, Functie: String;
    JS: TJSON;
    JA: TJSONArray;
    JO: TJSONObject;
    i: integer;
begin
{
 If CloudData.Tables.Count = 0 Then Exit;

 tbl := CloudData.Tables.Items[0];
 //tbl.GetEntities;

 If Toegang = 0 Then
 Begin
  For zoeker := 0 To tbl.Entities.Count - 1 Do
  Begin
   ent := tbl.Entities[zoeker];
   If (ent.Value[tbl.MetaData.Items[1].FieldName] = WebLoginPanel.User) And (ent.Value[tbl.MetaData.Items[2].FieldName] = WebLoginPanel.Password) And
      (ent.Value[tbl.MetaData.Items[6].FieldName] = 'Ja') Then
   Toegang := 1;
  End;
 End;

 //Onderstaande is om nogmaals op WebLoginPanel te klikken vanwege wachten op de Response
 If Toegang = 0 Then
 Begin
  If Start = 1 Then
  Begin
   //WebLoginPanelLogin(Self);
   WebLogin(Self);
   Start := 0;
  End;
 End;

 If Toegang = 1 Then CloudData.Tables.Clear;

 //WebLabel1.HTML := AResponse;

 {
 VerzamelBox.Items.Clear;
 JS := TJSON.Create;
 JA := TJSONArray(JS.Parse(AResponse));

 for i := 0 to JA.Count - 1 do
 begin
  JO := TJSONObject(JA.Items[i]);
  VerzamelBox.Items.Add(JO.GetJSONValue('Naam') + ' - ' + JO.GetJSONValue('Locatie') + ' - ' + JO.GetJSONValue('Functie'));
 end;
 JS.Free;
 }

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.CloudAllesAccessToken(Sender: TObject);
begin
 CloudAlles.GetTables;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.ActiefClick(Sender: TObject);
begin
 GebStopper := 1;
 LocStopper := 1;
 OpdStopper := 1;

 CloudAlles.App.CallbackURL := URLString;
 CloudAlles.App.Key := APPKeyString;
 CloudAlles.App.Secret := APPSecretString;
 CloudAlles.PersistTokens.Enabled := False;
 CloudAlles.PersistTokens.Key := '';
 CloudAlles.Connect;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.ActiveerGebruikersClick(Sender: TObject);
begin
 //GebStopper := 1;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.ActiveerLocatiesClick(Sender: TObject);
begin
 //LocStopper := 1;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.ActiveerOpdrachtenClick(Sender: TObject);
begin
 //OpdStopper := 1;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdStatusChange(Sender: TObject);
begin
 If OpdStatus.Enabled = False Then Exit;

 //De Besteller mag alleen van -1 naar 0 verzetten
 If (ILBesteller = 1) And (StatusWas = -1) Then
 Begin
  If OpdStatus.ItemIndex In [1..3] Then OpdStatus.ItemIndex := StatusWas;
 End;

 If ILBeheerder = 1 Then
 Begin
  StatusWas := OpdStatus.ItemIndex;
 End;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

Procedure TForm1.SchakelDBin(Sender: TObject);
Begin
 TweedeSet := 1;

 WebMyCloudDbClientDataSet.Active := False;
 WebMyCloudDbClientDataSet.FieldDefs.Clear;
 WebMyCloudDbClientDataSet.FieldDefs.Add('_ID', ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Actief',ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Gebruikersnaam',ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Wachtwoord',ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Naam',ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Functie',ftString);
 WebMyCloudDbClientDataSet.FieldDefs.Add('Locatie',ftString);
 WebMyCloudDbClientDataSet.Filtered := True;

 WebMyCloudDbClientDataset.AppCallbackURL := 'http://localhost:8000/klussenbeheer/klussenbeheer.html';
 //WebMyCloudDbClientDataset.AppCallbackURL := 'http://klussenbeheer.nl/klussenbeheer/klussenbeheer.html';
 //WebMyCloudDbClientDataset.AppKey := '500f8b04e39094b5f0ac8e9f5cd31931df002ef8d973';
 WebMyCloudDbClientDataset.AppKey := '500f8b04e39094b5f0ac8e9f5edd1a31d8092ff9d87b';
 WebMyCloudDbClientDataset.TableName := 'BOGebruikers';
 WebMyCloudDbClientDataSet.Active := True;
End;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.WebMyCloudDbClientDatasetAfterOpen(DataSet: TDataSet);
Var Accept: Boolean;
begin
{
 GebDbGrid.Columns[0].Width := 40;
 GebDbGrid.Columns[1].Width := 40;
 GebDbGrid.Columns[2].Width := 110;
 GebDbGrid.Columns[3].Width := 210;
 GebDbGrid.Columns[4].Width := 200;
 GebDbGrid.Columns[5].Width := 90;
 GebDbGrid.Columns[6].Width := 115;

 DataSet.Filtered := False;
 ////DataSet.Filter := 'Naam = ''Jan''' + 'And ' + 'Locatie = ''Camti''';
 //DataSet.Filter := 'Naam = ''' + WebLoginPanel.User + '''' + ' And ' + 'Locatie = ''' + WebLoginPanel.Password + '''';
 DataSet.Filter := 'Gebruikersnaam = ''' + WebLoginPanel.User + '''' + ' And ' + 'Wachtwoord = ''' + WebLoginPanel.Password + '''';
 DataSet.Filtered := True;

 Accept := False;
 //If (Dataset.FieldByName('Naam').AsString <> '') And (Dataset.FieldByName('Locatie').AsString <> '') Then
 If (Dataset.FieldByName('Gebruikersnaam').AsString <> '') And (Dataset.FieldByName('Wachtwoord').AsString <> '') Then
 Begin
  //Accept := (Dataset.FieldByName('Naam').AsString = WebLoginPanel.User) And (Dataset.FieldByName('Locatie').AsString = WebLoginPanel.Password);
  Accept := (Dataset.FieldByName('Gebruikersnaam').AsString = WebLoginPanel.User) And (Dataset.FieldByName('Wachtwoord').AsString = WebLoginPanel.Password) And
            (Dataset.FieldByName('Actief').AsString = 'Ja');
  If Accept = True Then Toegang := 1 Else Toegang := 0;
 End;

 If Toegang = 0 Then If (WebLoginPanel.User = 'JanLaptop') And (WebLoginPanel.Password = 'Camti67%@Camti67%@') Then Toegang := 1;

 If Toegang = 0 Then ShowMessage('U heeft geen toegang.');

 If Toegang = 1 Then
 Begin
  WPCDatabase.TabVisible := True;
  WPCDatabase.ShowCaption := True;
  WebPageControl.TabIndex := 1;
  WPCInloggen.TabVisible := False;
  WPCInloggen.ShowCaption := False;
  DataSet.Filtered := False;
 End;
}

 //VulBoxClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebRefreshClick(Sender: TObject);
begin
 WebMyCloudDbClientDataSet.Refresh(True);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocRefreshClick(Sender: TObject);
begin
 WebMyCloudDbClientDataSet.Refresh(True);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdRefreshClick(Sender: TObject);
begin
 WebMyCloudDbClientDataSet.Refresh(True);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 CloudAlles.ClearTokens;
 CloudAlles.Tables.Clear;
 Close;
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebToonWWClick(Sender: TObject);
begin
 //Standaard staat het wachwoord op ********
 If GebToonWW.Checked Then VeldGebWachtwoord.PasswordChar := #0 Else VeldGebWachtwoord.PasswordChar := '*';
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.VulBoxClick(Sender: TObject);
var loper: Integer;
    tbl: TmyCloudDataTable;
    ent: TmyCloudDataEntity;
begin
 {
 If CloudData.Tables.Count = 0 Then Exit;
 VerzamelBox.Items.Clear;

 tbl := CloudData.Tables.Items[0];

 For loper := 0 To tbl.Entities.Count - 1 Do
 Begin
  ent := tbl.Entities[loper];
  VerzamelBox.Items.Add('Index: ' + tbl.Entities.Items[loper].Index.ToString);
  VerzamelBox.Items.Add('ID: ' + tbl.Entities.Items[loper].ID);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[1].FieldName]);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[2].FieldName]);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[3].FieldName]);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[4].FieldName]);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[5].FieldName]);
  VerzamelBox.Items.Add(ent.Value[tbl.MetaData.Items[6].FieldName]);
 End;
 }
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebbtnAddClick(Sender: TObject);
var tblGeb: TmyCloudDataTable;
    entGeb: TmyCloudDataEntity;
    Actief, Beheerder, Besteller, Uitvoerder, Controleur: String;
begin
 if GebListBox1.ItemIndex = -1 then Exit;

 tblGeb := CloudAlles.Tables.Items[0];

 if tblGeb.MetaData.Count >= 3  then
 begin
  entGeb := tblGeb.Entities.Add;
  entGeb.Value[tblGeb.MetaData.Items[1].FieldName] := VeldGebGebruiker.Text;
  entGeb.Value[tblGeb.MetaData.Items[2].FieldName] := VeldGebWachtwoord.Text;
  entGeb.Value[tblGeb.MetaData.Items[3].FieldName] := VeldGebNaam.Text;
  entGeb.Value[tblGeb.MetaData.Items[4].FieldName] := VeldGebFunctie.Text;
  entGeb.Value[tblGeb.MetaData.Items[5].FieldName] := VeldGebLocatie.Text;
  If VeldGebActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[6].FieldName] := Actief;

  If SoortFunctie.Checked[0] = True Then Beheerder := 'Ja' Else Beheerder := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[7].FieldName] := Beheerder;
  If SoortFunctie.Checked[1] = True Then Besteller := 'Ja' Else Besteller := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[8].FieldName] := Besteller;
  If SoortFunctie.Checked[2] = True Then Uitvoerder := 'Ja' Else Uitvoerder := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[9].FieldName] := Uitvoerder;
  If SoortFunctie.Checked[3] = True Then Controleur := 'Ja' Else Controleur := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[10].FieldName] := Controleur;

  entGeb.OnInsert := GebGotmcdEntityInsert;
  entGeb.Insert;
 end;

 GebRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocbtnAddClick(Sender: TObject);
var tblLoc: TmyCloudDataTable;
    entLoc: TmyCloudDataEntity;
    Actief: String;
    SrtBiblio: String;
begin
 if LocListBox1.ItemIndex = -1 then Exit;

 tblLoc := CloudAlles.Tables.Items[1];

 if tblLoc.MetaData.Count >= 3 then
 begin
  entLoc := tblLoc.Entities.Add;
  entLoc.Value[tblLoc.MetaData.Items[1].FieldName] := VeldLocLocatie.Text;
  entLoc.Value[tblLoc.MetaData.Items[2].FieldName] := VeldLocAdres.Text;
  entLoc.Value[tblLoc.MetaData.Items[3].FieldName] := VeldLocContactpersoon.Text;
  entLoc.Value[tblLoc.MetaData.Items[4].FieldName] := VeldLocEmailadres.Text;
  If VeldLocActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
  entLoc.Value[tblLoc.MetaData.Items[5].FieldName] := Actief;

  If SoortBieb.ItemIndex = -1 Then SrtBiblio := '0' Else SrtBiblio := IntToStr(SoortBieb.ItemIndex + 1);
  entLoc.Value[tblLoc.MetaData.Items[6].FieldName] := SrtBiblio;

  entLoc.OnInsert := LocGotmcdEntityInsert;
  entLoc.Insert;
 end;

 LocRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdbtnNieuwClick(Sender: TObject);
var tblOpd: TmyCloudDataTable;
    entOpd: TmyCloudDataEntity;
    Actief, SrtStatus, Datum: String;
    TDatum: TDateTime;
    ZoekSP: Integer;
Begin
 if OpdListBox1.ItemIndex = -1 then Exit;

 tblOpd := CloudAlles.Tables.Items[2];

 if tblOpd.MetaData.Count >= 3 then
 begin
  entOpd := tblOpd.Entities.Add;
  entOpd.Value[tblOpd.MetaData.Items[1].FieldName] := '';
  entOpd.Value[tblOpd.MetaData.Items[2].FieldName] := '';
  entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := '';
  entOpd.Value[tblOpd.MetaData.Items[17].FieldName] := '';

  //Huidige Datum opvragen en invullen
  Datum := DateTimeToStr(Now);
  ZoekSP := Pos(' ', Datum);
  If ZoekSP > 0 Then Datum := Copy(Datum, 1, ZoekSP);
  OpdOpdrachtDate.Date := StrToDate(Datum);

  entOpd.Value[tblOpd.MetaData.Items[4].FieldName] := OpdOpdrachtDate.Text;
  entOpd.Value[tblOpd.MetaData.Items[5].FieldName] := '0';
  entOpd.Value[tblOpd.MetaData.Items[11].FieldName] := 'Nee';

  //de rest van de Datums op 01-01-2022 zetten
  OpdReactieDate.Date := StrToDate(Datum);
  entOpd.Value[tblOpd.MetaData.Items[7].FieldName] := OpdReactieDate.Text;
  OpdGeplandDate.Date := StrToDate(Datum);
  entOpd.Value[tblOpd.MetaData.Items[16].FieldName] := OpdGeplandDate.Text;
  OpdVerslagDate.Date := StrToDate(Datum);
  entOpd.Value[tblOpd.MetaData.Items[9].FieldName] := OpdVerslagDate.Text;

  entOpd.OnInsert := OpdGotmcdEntityInsert;
  entOpd.Insert;
 end;

 OpdRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdbtnAddClick(Sender: TObject);
var tblOpd: TmyCloudDataTable;
    entOpd: TmyCloudDataEntity;
    Actief: String;
    SrtStatus, Tekst, Reeks: String;
    ZoekBR: Integer;
begin
 if OpdListBox1.ItemIndex = -1 then Exit;

 tblOpd := CloudAlles.Tables.Items[2];

 if tblOpd.MetaData.Count >= 3 then
 begin
  entOpd := tblOpd.Entities.Add;
  entOpd.Value[tblOpd.MetaData.Items[1].FieldName] := VeldOpdLocatie.Text;
  entOpd.Value[tblOpd.MetaData.Items[2].FieldName] := VeldOpdContactpersoon.Text;
  entOpd.Value[tblOpd.MetaData.Items[17].FieldName] := VeldOpdOpdrachtgever.Text;

  //If OpdOpdracht.Text = '' Then entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := 'Geen opdracht.' Else
  //entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := OpdOpdracht.Text;

  ////Opdrachten
  //Afbreekeinden per regel vaststellen
  Reeks := '';
  For ZoekBR := 0 To OpdOpdracht.Lines.Count - 1 Do
  Begin
   Reeks := Reeks + IntToStr(Length(OpdOpdracht.Lines[ZoekBR])) + ';';
  End;

  //Als die er zijn dan opslaan in Field 14
  If Reeks <> '' Then
  Begin
   Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
   entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := Reeks;
  End Else entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := '';

  //De Opdracht opslaan na eventueel ontbreaken.
  If OpdOpdracht.Text = '' Then
  Begin
   entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := 'Geen Opdracht.';
   entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := '';
  End
  Else
  Begin
   //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
   Tekst := '';
   OpdOpdracht.Lines.Delimiter := ' ';
   Tekst := OpdOpdracht.Lines.DelimitedText;
   Tekst := Tekst.Replace('/', '-');
   Tekst := Tekst.Replace('"', '');
   entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := Tekst;
  End;
  entOpd.Value[tblOpd.MetaData.Items[4].FieldName] := OpdOpdrachtDate.Text;

  ////Reactie
  //Afbreek einden per regel vaststellen
  Reeks := '';
  For ZoekBR := 0 To OpdReactie.Lines.Count - 1 Do
  Begin
   Reeks := Reeks + IntToStr(Length(OpdReactie.Lines[ZoekBR])) + ';';
  End;

  //Als die er zijn dan opslaan in Field 14
  If Reeks <> '' Then
  Begin
   Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
   entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := Reeks;
  End Else entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := '';

  //De Reactie opslaan na eventueel ontbreaken.
  If OpdReactie.Text = '' Then
  Begin
   entOpd.Value[tblOpd.MetaData.Items[6].FieldName] := 'Geen Reactie.';
   entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := '';
  End
  Else
  Begin
   //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
   Tekst := '';
   OpdReactie.Lines.Delimiter := ' ';
   Tekst := OpdReactie.Lines.DelimitedText;
   Tekst := Tekst.Replace('/', '-');
   Tekst := Tekst.Replace('"', '');
   entOpd.Value[tblOpd.MetaData.Items[6].FieldName] := Tekst;
  End;
  If OpdReactieDate.Text = '' Then OpdReactieDate.Text := '01-01-2022';
  entOpd.Value[tblOpd.MetaData.Items[7].FieldName] := OpdReactieDate.Text;
  If OpdGeplandDate.Text = '' Then OpdGeplandDate.Text := '01-01-2022';
  entOpd.Value[tblOpd.MetaData.Items[16].FieldName] := OpdGeplandDate.Text;

  ////Verslag
  //Afbreek einden per regel vaststellen
  Reeks := '';
  For ZoekBR := 0 To OpdVerslag.Lines.Count - 1 Do
  Begin
   Reeks := Reeks + IntToStr(Length(OpdVerslag.Lines[ZoekBR])) + ';';
  End;

  //Als die er zijn dan opslaan in Field 14
  If Reeks <> '' Then
  Begin
   Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
   entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := Reeks;
  End Else entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := '';

  //Het Verslag opslaan na eventueel ontbreaken.
  If OpdVerslag.Text = '' Then
  Begin
   entOpd.Value[tblOpd.MetaData.Items[8].FieldName] := 'Nog geen Verslag.';
   entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := '';
  End
  Else
  Begin
   //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
   Tekst := '';
   OpdVerslag.Lines.Delimiter := ' ';
   Tekst := OpdVerslag.Lines.DelimitedText;
   Tekst := Tekst.Replace('/', '-');
   Tekst := Tekst.Replace('"', '');
   entOpd.Value[tblOpd.MetaData.Items[8].FieldName] := Tekst;
  End;
  If OpdVerslagDate.Text = '' Then OpdVerslagDate.Text := '01-01-2022';
  entOpd.Value[tblOpd.MetaData.Items[9].FieldName] := OpdVerslagDate.Text;

  ////Opmerkingen
  //Afbreek einden per regel vaststellen
  Reeks := '';
  For ZoekBR := 0 To OpdAlgOpm.Lines.Count - 1 Do
  Begin
   Reeks := Reeks + IntToStr(Length(OpdAlgOpm.Lines[ZoekBR])) + ';';
  End;

  //Als die er zijn dan opslaan in Field 14
  If Reeks <> '' Then
  Begin
   Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
   entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := Reeks;
  End Else entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := '';

  //De Opmerkingen opslaan na eventueel ontbreaken.
  If OpdAlgOpm.Text = '' Then
  Begin
   entOpd.Value[tblOpd.MetaData.Items[10].FieldName] := 'Nog geen Opmerkingen.';
   entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := '';
  End
  Else
  Begin
   //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
   Tekst := '';
   OpdAlgOpm.Lines.Delimiter := ' ';
   Tekst := OpdAlgOpm.Lines.DelimitedText;
   Tekst := Tekst.Replace('/', '-');
   Tekst := Tekst.Replace('"', '');
   entOpd.Value[tblOpd.MetaData.Items[10].FieldName] := Tekst;
  End;


  If OpdStatus.ItemIndex = -1 Then SrtStatus := '0' Else SrtStatus := IntToStr(OpdStatus.ItemIndex + 1);
  entOpd.Value[tblOpd.MetaData.Items[5].FieldName] := SrtStatus;

  If VeldOpdActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
  entOpd.Value[tblOpd.MetaData.Items[11].FieldName] := Actief;

  entOpd.OnInsert := OpdGotmcdEntityInsert;
  entOpd.Insert;
 end;

 OpdRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebbtnUpdateClick(Sender: TObject);
var tblGeb: TmyCloudDataTable;
    entGeb: TmyCloudDataEntity;
    Actief, Beheerder, Besteller, Uitvoerder, Controleur: String;
begin
 if GebListBox1.ItemIndex = -1 then Exit;
 If GebListBox2.Items.Count = 0 Then Exit;

 tblGeb := CloudAlles.Tables.Items[0];
 entGeb := tblGeb.Entities[GebListBox2.ItemIndex];

 if tblGeb.MetaData.Count >= 3 then
 begin
  entGeb.Value[tblGeb.MetaData.Items[1].FieldName] := VeldGebGebruiker.Text;
  entGeb.Value[tblGeb.MetaData.Items[2].FieldName] := VeldGebWachtwoord.Text;
  entGeb.Value[tblGeb.MetaData.Items[3].FieldName] := VeldGebNaam.Text;
  entGeb.Value[tblGeb.MetaData.Items[4].FieldName] := VeldGebFunctie.Text;
  entGeb.Value[tblGeb.MetaData.Items[5].FieldName] := VeldGebLocatie.Text;
  If VeldGebActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[6].FieldName] := Actief;

  If SoortFunctie.Checked[0] = True Then Beheerder := 'Ja' Else Beheerder := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[7].FieldName] := Beheerder;
  If SoortFunctie.Checked[1] = True Then Besteller := 'Ja' Else Besteller := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[8].FieldName] := Besteller;
  If SoortFunctie.Checked[2] = True Then Uitvoerder := 'Ja' Else Uitvoerder := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[9].FieldName] := Uitvoerder;
  If SoortFunctie.Checked[3] = True Then Controleur := 'Ja' Else Controleur := 'Nee';
  entGeb.Value[tblGeb.MetaData.Items[10].FieldName] := Controleur;

  entGeb.OnUpdate := GebGotmcdEntityUpdate;
  entGeb.Update;
 end;

 GebRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocbtnUpdateClick(Sender: TObject);
var tblLoc: TmyCloudDataTable;
    entLoc: TmyCloudDataEntity;
    Actief: String;
    SrtBiblio: String;
begin
 if LocListBox1.ItemIndex = -1 then Exit;

 tblLoc := CloudAlles.Tables.Items[1];
 entLoc := tblLoc.Entities[LocListBox2.ItemIndex];

 if tblLoc.MetaData.Count >= 3 then
 begin
  entLoc.Value[tblLoc.MetaData.Items[1].FieldName] := VeldLocLocatie.Text;
  entLoc.Value[tblLoc.MetaData.Items[2].FieldName] := VeldLocAdres.Text;
  entLoc.Value[tblLoc.MetaData.Items[3].FieldName] := VeldLocContactpersoon.Text;
  entLoc.Value[tblLoc.MetaData.Items[4].FieldName] := VeldLocEmailadres.Text;
  If VeldLocActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
  entLoc.Value[tblLoc.MetaData.Items[5].FieldName] := Actief;

  If SoortBieb.ItemIndex = -1 Then SrtBiblio := '0' Else SrtBiblio := IntToStr(SoortBieb.ItemIndex + 1);
  entLoc.Value[tblLoc.MetaData.Items[6].FieldName] := SrtBiblio;

  entLoc.OnUpdate := LocGotmcdEntityUpdate;
  entLoc.Update;
 end;

 LocRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdbtnUpdateClick(Sender: TObject);
var tblOpd: TmyCloudDataTable;
    entOpd: TmyCloudDataEntity;
    Actief, SrtStatus, Reeks, Tekst: String;
    ZoekBR: Integer;
    mr: TModalResult;
    TestBeh, TestWijz: Integer;
begin

 mr := await(TModalResult, WebMessageDlg1.ShowDialog('Als je aanpassingen aangebracht hebt zijn die definitief.' + #13 + #13 + 'Wil je deze Opdracht echt Opslaan?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));

 If mr = mrYes Then
 Begin

  if OpdListBox1.ItemIndex = -1 then Exit;
  If OpdListBox2.Items.Count = 0 Then Exit;

  tblOpd := CloudAlles.Tables.Items[2];
  entOpd := tblOpd.Entities[OpdListBox2.ItemIndex];

  if tblOpd.MetaData.Count >= 3 then
  begin
   entOpd.Value[tblOpd.MetaData.Items[1].FieldName] := VeldOpdLocatie.Text;
   entOpd.Value[tblOpd.MetaData.Items[2].FieldName] := VeldOpdContactpersoon.Text;
   entOpd.Value[tblOpd.MetaData.Items[17].FieldName] := VeldOpdOpdrachtgever.Text;

   ////Opdracht
   //Afbreek einden per regel vaststellen
   Reeks := '';
   For ZoekBR := 0 To OpdOpdracht.Lines.Count - 1 Do
   Begin
    Reeks := Reeks + IntToStr(Length(OpdOpdracht.Lines[ZoekBR])) + ';';
   End;

   TestBeh := 0;
   TestWijz := 0;
   If (ILBeheerder <> 1) Then TestBeh := 1;
   If TestBeh = 1 Then If (ReserveTekst.Lines.Count > 1) And (OpdOpdracht.Lines.Count = 0) Then TestWijz := 1;

   //Deze Test constructie is bedoeld om te voorkomen dat een niet-Beheerder een heel Opdracht veld kan wissen.
   //Het lukt hem / haar wel als ze dat regel voor regel doen.
   //Maar er moet toch altijd een vorm van aanpassen mogelijk blijven.
   //Maar als de opdracht uit maar 1 regel bestaat, dan kan de opdracht wél gewist worden.
   If TestWijz = 0 Then
   Begin
    
    //Als die er zijn dan opslaan in Field 12
    If Reeks <> '' Then
    Begin
     Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
     entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := Reeks;
    End Else entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := '';

    //De Opdracht opslaan na eventueel ontbreaken.
    If OpdOpdracht.Text = '' Then
    Begin
     entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := 'Geen opdracht.';
     entOpd.Value[tblOpd.MetaData.Items[12].FieldName] := '';
    End
    Else
    Begin
     //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
     Tekst := '';
     OpdOpdracht.Lines.Delimiter := ' ';
     Tekst := OpdOpdracht.Lines.DelimitedText;
     Tekst := Tekst.Replace('/', '-');
     Tekst := Tekst.Replace('"', '');
     entOpd.Value[tblOpd.MetaData.Items[3].FieldName] := Tekst;
    End;
    If OpdOpdrachtDate.Text = '' Then OpdOpdrachtDate.Text := '01-01-2022';
    entOpd.Value[tblOpd.MetaData.Items[4].FieldName] := OpdOpdrachtDate.Text;

   End;

   ////Reactie
   //Afbreek einden per regel vaststellen
   Reeks := '';
   For ZoekBR := 0 To OpdReactie.Lines.Count - 1 Do
   Begin
    Reeks := Reeks + IntToStr(Length(OpdReactie.Lines[ZoekBR])) + ';';
   End;

   //Als die er zijn dan opslaan in Field 13
   If Reeks <> '' Then
   Begin
    Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
    entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := Reeks;
   End Else entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := '';

   //De Reactie opslaan na eventueel ontbreaken.
   If OpdReactie.Text = '' Then
   Begin
    entOpd.Value[tblOpd.MetaData.Items[6].FieldName] := 'Geen Reactie.';
    entOpd.Value[tblOpd.MetaData.Items[13].FieldName] := '';
   End
   Else
   Begin
    //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
    Tekst := '';
    OpdReactie.Lines.Delimiter := ' ';
    Tekst := OpdReactie.Lines.DelimitedText;
    Tekst := Tekst.Replace('/', '-');
    Tekst := Tekst.Replace('"', '');
    entOpd.Value[tblOpd.MetaData.Items[6].FieldName] := Tekst;
   End;
   If OpdReactieDate.Text = '' Then OpdReactieDate.Text := '01-01-2022';
   entOpd.Value[tblOpd.MetaData.Items[7].FieldName] := OpdReactieDate.Text;
   If OpdGeplandDate.Text = '' Then OpdGeplandDate.Text := '01-01-2022';
   entOpd.Value[tblOpd.MetaData.Items[16].FieldName] := OpdGeplandDate.Text;


   ////Verslag
   //Afbreek einden per regel vaststellen
   Reeks := '';
   For ZoekBR := 0 To OpdVerslag.Lines.Count - 1 Do
   Begin
    Reeks := Reeks + IntToStr(Length(OpdVerslag.Lines[ZoekBR])) + ';';
   End;

   //Als die er zijn dan opslaan in Field 14
   If Reeks <> '' Then
   Begin
    Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
    entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := Reeks;
   End Else entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := '';

   //Het Verslag opslaan na eventueel ontbreaken.
   If OpdVerslag.Text = '' Then
   Begin
    entOpd.Value[tblOpd.MetaData.Items[8].FieldName] := 'Nog geen Verslag.';
    entOpd.Value[tblOpd.MetaData.Items[14].FieldName] := '';
   End
   Else
   Begin
    //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
    Tekst := '';
    OpdVerslag.Lines.Delimiter := ' ';
    Tekst := OpdVerslag.Lines.DelimitedText;
    Tekst := Tekst.Replace('/', '-');
    Tekst := Tekst.Replace('"', '');
    entOpd.Value[tblOpd.MetaData.Items[8].FieldName] := Tekst;
   End;
   If OpdVerslagDate.Text = '' Then OpdVerslagDate.Text := '01-01-2022';
   entOpd.Value[tblOpd.MetaData.Items[9].FieldName] := OpdVerslagDate.Text;


   ////Opmerkingen
   //Afbreek einden per regel vaststellen
   Reeks := '';
   For ZoekBR := 0 To OpdAlgOpm.Lines.Count - 1 Do
   Begin
    Reeks := Reeks + IntToStr(Length(OpdAlgOpm.Lines[ZoekBR])) + ';';
   End;

   //Als die er zijn dan opslaan in Field 15
   If Reeks <> '' Then
   Begin
    Reeks := Copy(Reeks, 1, Length(Reeks) - 1);
    entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := Reeks;
   End Else entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := '';

   //De Opmerkingen opslaan na eventueel ontbreaken.
   If OpdAlgOpm.Text = '' Then
   Begin
    entOpd.Value[tblOpd.MetaData.Items[10].FieldName] := 'Nog geen Opmerkingen.';
    entOpd.Value[tblOpd.MetaData.Items[15].FieldName] := '';
   End
   Else
   Begin
    //De / en de " eruit halen, vóór het opslaan. Blijkbaar zijn dat een soort van stuurcodes in de e-mail.
    Tekst := '';
    OpdAlgOpm.Lines.Delimiter := ' ';
    Tekst := OpdAlgOpm.Lines.DelimitedText;
    Tekst := Tekst.Replace('/', '-');
    Tekst := Tekst.Replace('"', '');
    entOpd.Value[tblOpd.MetaData.Items[10].FieldName] := Tekst;
   End;


   If OpdStatus.Enabled = True Then
   Begin
    If OpdStatus.ItemIndex = -1 Then SrtStatus := '0' Else SrtStatus := IntToStr(OpdStatus.ItemIndex + 1);
    entOpd.Value[tblOpd.MetaData.Items[5].FieldName] := SrtStatus;
   End;

   If VeldOpdActief.Checked Then Actief := 'Ja' Else Actief := 'Nee';
   entOpd.Value[tblOpd.MetaData.Items[11].FieldName] := Actief;

   entOpd.OnUpdate := OpdGotmcdEntityUpdate;
   entOpd.Update;
  end;

  OpdRefreshClick(Self);
 End;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebbtnDeleteClick(Sender: TObject);
var Index: Integer;
    tblGeb: TmyCloudDataTable;
    entGeb: TmyCloudDataEntity;
begin
 if GebListBox1.ItemIndex = -1 then Exit;
 If GebListBox2.Items.Count = 0 Then Exit;

 Index := GebListBox2.ItemIndex;
 tblGeb := CloudAlles.Tables.Items[0];
 entGeb := tblGeb.Entities[GebListBox2.ItemIndex];

 if tblGeb.MetaData.Count >= 3  then
 begin
  entGeb.OnDelete := GebGotmcdEntityDelete;
  entGeb.Delete;

  GebListBox2.Items.Delete(Index);

  VeldGebGebruiker.Text := '';
  VeldGebWachtwoord.Text := '';
  VeldGebNaam.Text := '';
  VeldGebFunctie.Text := '';
  VeldGebLocatie.Text := '';
  VeldGebActief.Checked := False;
  SoortFunctie.Checked[0] := False;
  SoortFunctie.Checked[1] := False;
  SoortFunctie.Checked[2] := False;
  SoortFunctie.Checked[3] := False;

  SoortBieb.ItemIndex := -1;
 end;

 GebRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocbtnDeleteClick(Sender: TObject);
var Index: Integer;
    tblLoc: TmyCloudDataTable;
    entLoc: TmyCloudDataEntity;
begin
 if LocListBox1.ItemIndex = -1 then Exit;
 If LocListBox2.Items.Count = 0 Then Exit;

 Index := LocListBox2.ItemIndex;
 tblLoc := CloudAlles.Tables.Items[1];
 entLoc := tblLoc.Entities[LocListBox2.ItemIndex];

 if tblLoc.MetaData.Count >= 3  then
 begin
  entLoc.OnDelete := LocGotmcdEntityDelete;
  entLoc.Delete;

  LocListBox2.Items.Delete(Index);

  VeldLocLocatie.Text := '';
  VeldLocAdres.Text := '';
  VeldLocContactpersoon.Text := '';
  VeldLocEmailadres.Text := '';
  VeldLocActief.Checked := False;
 end;

 LocRefreshClick(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdbtnDeleteClick(Sender: TObject);
var Index: Integer;
    tblOpd: TmyCloudDataTable;
    entOpd: TmyCloudDataEntity;
    mr: TModalResult;
begin

 mr := await(TModalResult, WebMessageDlg1.ShowDialog('De Opdracht verwijderen is definitief.' + #13 + #13 + 'Wil je deze Opdracht echt verwijderen?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));

 If mr = mrYes Then
 Begin
  if OpdListBox1.ItemIndex = -1 then Exit;
  If OpdListBox2.Items.Count = 0 Then Exit;

  Index := OpdListBox2.ItemIndex;
  tblOpd := CloudAlles.Tables.Items[2];
  entOpd := tblOpd.Entities[OpdListBox2.ItemIndex];

  if tblOpd.MetaData.Count >= 3  then
  begin
   entOpd.OnDelete := OpdGotmcdEntityDelete;
   entOpd.Delete;

   OpdListBox2.Items.Delete(Index);

   VeldLocLocatie.Text := '';
   VeldLocContactpersoon.Text := '';
   OpdOpdracht.Text := '';
   OpdReactie.Text := '';
   OpdVerslag.Text := '';
   OpdAlgOpm.Text := '';
   OpdOpdrachtDate.Text := '';
   VeldOpdActief.Checked := False;
   OpdStatus.ItemIndex := -1;
  end;

  OpdRefreshClick(Self);
 End;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdMetaDataCheckClick(Sender: TObject);
var tblOpd: TmyCloudDataTable;
begin

 if OpdListBox1.ItemIndex = -1 then exit;

 If OpdMetaDataCheck.Checked = True Then
 Begin
  OpdActief.Checked := False;
  OpdActief.Enabled := False;
  FilterBox.ItemIndex := 0;
  FilterBox.Enabled := False;
 End
 Else
 Begin
  OpdActief.Checked := False;
  OpdActief.Enabled := True;
  FilterBox.ItemIndex := 0;
  FilterBox.Enabled := True;
  End;

 OpdToonMetaData(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}
procedure TForm1.OpdToonMetaData(Sender: TObject);
var tblOpd: TmyCloudDataTable;
    i: integer;
begin
 If OpdMetaDataCheck.Checked Then
 Begin
  if OpdListbox1.ItemIndex >= 0 then
  begin
   tblOpd := CloudAlles.Tables.Items[2];

   OpdListBox2.Items.Clear;
   OpdListBox2.Items.Add(IntToStr(tblOpd.MetaData.Count));
   
   for i := 0 to tblOpd.MetaData.Count - 1 do
   begin
    OpdListBox2.Items.Add(IntToStr(i) + ' - ' + tblOpd.MetaData.Items[i].FieldName);
   end;
  end;
 End
 Else OpdShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebGotmcdEntityInsert(Sender: TObject);
begin
 GebShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebGotmcdEntityUpdate(Sender: TObject);
begin
 GebShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebGotmcdEntityDelete(Sender: TObject);
begin
 GebShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocGotmcdEntityInsert(Sender: TObject);
begin
 LocShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocGotmcdEntityUpdate(Sender: TObject);
begin
 LocShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdGotmcdEntityUpdate(Sender: TObject);
begin
 OpdShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocGotmcdEntityDelete(Sender: TObject);
begin
 LocShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdGotmcdEntityInsert(Sender: TObject);
begin
 OpdShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdGotmcdEntityDelete(Sender: TObject);
begin
 LocShowEntities(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebListBox2Change(Sender: TObject);
var tblGeb: TmyCloudDataTable;
    entGeb: TmyCloudDataEntity;
begin
 If GebListBox2.Items.Count = 0 Then Exit;

 tblGeb := CloudAlles.Tables.Items[0];
 entGeb := tblGeb.Entities[GebListBox2.ItemIndex];

 if tblGeb.MetaData.Count >= 3  then
 begin
  VeldGebGebruiker.Text := entGeb.Value[tblGeb.MetaData.Items[1].FieldName];
  VeldGebWachtwoord.Text := entGeb.Value[tblGeb.MetaData.Items[2].FieldName];
  VeldGebNaam.Text := entGeb.Value[tblGeb.MetaData.Items[3].FieldName];
  VeldGebFunctie.Text := entGeb.Value[tblGeb.MetaData.Items[4].FieldName];
  VeldGebLocatie.Text := entGeb.Value[tblGeb.MetaData.Items[5].FieldName];
  If entGeb.Value[tblGeb.MetaData.Items[6].FieldName] = 'Ja' Then VeldGebActief.Checked := True Else VeldGebActief.Checked := False;

  If entGeb.Value[tblGeb.MetaData.Items[7].FieldName] = 'Ja' Then SoortFunctie.Checked[0] := True Else SoortFunctie.Checked[0] := False;
  If entGeb.Value[tblGeb.MetaData.Items[8].FieldName] = 'Ja' Then SoortFunctie.Checked[1] := True Else SoortFunctie.Checked[1] := False;
  If entGeb.Value[tblGeb.MetaData.Items[9].FieldName] = 'Ja' Then SoortFunctie.Checked[2] := True Else SoortFunctie.Checked[2] := False;
  If entGeb.Value[tblGeb.MetaData.Items[10].FieldName] = 'Ja' Then SoortFunctie.Checked[3] := True Else SoortFunctie.Checked[3] := False;
 end;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.GebListBox2Click(Sender: TObject);
begin
 GebListBox2Change(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocListBox2Change(Sender: TObject);
var tblLoc: TmyCloudDataTable;
    entLoc: TmyCloudDataEntity;
    SrtBiblio: Integer;
begin
 If LocListBox2.Items.Count = 0 Then Exit;

 tblLoc := CloudAlles.Tables.Items[1];
 entLoc := tblLoc.Entities[LocListBox2.ItemIndex];

 if tblLoc.MetaData.Count >= 3  then
 begin
  VeldLocLocatie.Text := entLoc.Value[tblLoc.MetaData.Items[1].FieldName];
  VeldLocAdres.Text := entLoc.Value[tblLoc.MetaData.Items[2].FieldName];
  VeldLocContactpersoon.Text := entLoc.Value[tblLoc.MetaData.Items[3].FieldName];
  VeldLocEmailadres.Text := entLoc.Value[tblLoc.MetaData.Items[4].FieldName];
  If entLoc.Value[tblLoc.MetaData.Items[5].FieldName] = 'Ja' Then VeldLocActief.Checked := True Else VeldLocActief.Checked := False;
  If (entLoc.Value[tblLoc.MetaData.Items[6].FieldName] = '0') Or (entLoc.Value[tblLoc.MetaData.Items[6].FieldName] = 'null') Then SoortBieb.ItemIndex := -1
  Else
  Begin
   SrtBiblio := StrToInt(entLoc.Value[tblLoc.MetaData.Items[6].FieldName]);
   If SrtBiblio In [1..4] Then SoortBieb.Itemindex := SrtBiblio - 1;
  End;
 end;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.LocListBox2Click(Sender: TObject);
begin
 LocListBox2Change(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdListBox2Change(Sender: TObject);
var tblOpd: TmyCloudDataTable;
    entOpd: TmyCloudDataEntity;
    SrtStatus, ZoekT, Toon, Vuller, Extra: Integer;
    DatumOpdracht, DatumReactie, DatumGepland, DatumVerslag, Jaar, Maand, Dag: String;
    Reeks: TArray<string>;
    OpdBR, OpdTekst, ReaTekst, VerTekst, OpmTekst, Regel: String;
    TestBeh: Integer;
begin
 If OpdListBox2.Items.Count = 0 Then Exit;

 tblOpd := CloudAlles.Tables.Items[2];
 entOpd := tblOpd.Entities[OpdListBox2.ItemIndex];

 if tblOpd.MetaData.Count >= 3  then
 begin

  //Eerst eens testen of het een niet-Beheerder is.
  //Als het een niet-Beheerder is en de Status staat op Gepland - Afgerond - Opgeleverd dan moet het Opdracht memo op Enables False gezet worden.
  TestBeh := 0;
  If (ILBeheerder <> 1) Then TestBeh := 1;
  If TestBeh = 1 Then
  Begin
   If ((entOpd.Value[tblOpd.MetaData.Items[5].FieldName] = '0') Or (entOpd.Value[tblOpd.MetaData.Items[5].FieldName] = 'null')) Then SrtStatus := -1
   Else SrtStatus := StrToInt(entOpd.Value[tblOpd.MetaData.Items[5].FieldName]);
   If SrtStatus In [2..4] Then
   Begin
    OpdOpdracht.Enabled := False;
    OpdOpdrachtDate.Enabled := False;
   End
   Else
   Begin
    OpdOpdracht.Enabled := True;
    OpdOpdrachtDate.Enabled := True;
   End;
  End;

  VeldOpdLocatie.Text := entOpd.Value[tblOpd.MetaData.Items[1].FieldName];
  VeldOpdContactpersoon.Text := entOpd.Value[tblOpd.MetaData.Items[2].FieldName];
  VeldOpdOpdrachtgever.Text := entOpd.Value[tblOpd.MetaData.Items[17].FieldName];

  ////Opdracht
  OpdOpdracht.Lines.Clear;
  ReserveTekst.Lines.Clear;
  Reeks := Nil;
  //Lees eerst BR uit en omzetten naar een Array van Strings
  OpdBR := entOpd.Value[tblOpd.MetaData.Items[12].FieldName];
  If (OpdBR <> '') And (OpdBR <> 'null') Then Reeks := OpdBR.Split(';');

  //Haal de Tekst op uit de database
  OpdTekst := entOpd.Value[tblOpd.MetaData.Items[3].FieldName];
  //Als er geen Tekst is dan een kreet neerzetten.
  If (OpdTekst = '') Or (OpdTekst = 'null') Then OpdOpdracht.Text := 'Geen Opdracht.';

  //Er is maar 1 regel Tekst
  If (OpdTekst <> '') And (OpdTekst <> 'null') And (Reeks = Nil) Then OpdOpdracht.Text := OpdTekst;

  //Kijken of er een Tekst is en eventueel de totale tekst in delen splitsen
  If (OpdTekst <> '') And (Length(Reeks) > 0) Then
  Begin
   For Vuller := 0 To Length(Reeks) - 2 Do
   Begin
    If Reeks[Vuller] <> '' Then
    Begin
     Regel := '';
     Regel := Copy(OpdTekst, 1, StrToInt(Reeks[Vuller]) + 1);
     OpdTekst := OpdTekst.Remove(0, Length(Regel));
     OpdOpdracht.Lines.Add(Regel);
     ReserveTekst.Lines.Add(Regel);
    End;
   End;
   OpdOpdracht.Lines.Add(OpdTekst);
   ReserveTekst.Lines.Add(OpdTekst);
  End;

  DatumOpdracht := entOpd.Value[tblOpd.MetaData.Items[4].FieldName];
  If (DatumOpdracht <> '') And (DatumOpdracht <> 'null') Then
  Begin
   ZoekT := Pos('T', DatumOpdracht);
   If ZoekT > 0 Then DatumOpdracht := Copy(DatumOpdracht, 1, ZoekT - 1);
   //Formaat Jaar - Maand- Dag is anders in Database dan in de Date component, dus herschikken.
   Jaar := Copy(DatumOpdracht, 1, 4);
   Maand := Copy(DatumOpdracht,6, 2);
   Dag := Copy(DatumOpdracht, 9, 2);
   DatumOpdracht := Dag + '-' + Maand + '-' + Jaar;
   OpdOpdrachtDate.Date := StrToDate(DatumOpdracht);
  End Else OpdOpdrachtDate.Date := StrToDate('01-01-2022');

  ////Reactie
  OpdReactie.Lines.Clear;
  Reeks := Nil;
  //Lees eerste BR uit en omzetten naar een Array van Strings
  OpdBR := entOpd.Value[tblOpd.MetaData.Items[13].FieldName];
  If (OpdBR <> '') And (OpdBR <> 'null') Then Reeks := OpdBR.Split(';');
  //Haal de Tekst op uit de database
  ReaTekst := entOpd.Value[tblOpd.MetaData.Items[6].FieldName];

  //Als er geen Tekst is dan een kreet neerzetten.
  If (ReaTekst = '') Or (ReaTekst = 'null') Then OpdReactie.Text := 'Nog geen Reactie.';

  //Er is maar 1 regel Tekst
  If (ReaTekst <> '') And (ReaTekst <> 'null') And (Reeks = Nil) Then OpdReactie.Text := ReaTekst;

  //Kijken of er een Tekst is en eventueel de totale tekst in delen splitsen
  If (ReaTekst <> '') And (Length(Reeks) > 0) Then
  Begin
   For Vuller := 0 To Length(Reeks) - 2 Do
   Begin
    If Reeks[Vuller] <> '' Then
    Begin
     Regel := '';
     Regel := Copy(ReaTekst, 1, StrToInt(Reeks[Vuller]) + 1);
     ReaTekst := ReaTekst.Remove(0, Length(Regel));
     OpdReactie.Lines.Add(Regel);
    End;
   End;
   OpdReactie.Lines.Add(ReaTekst);
  End;

  ////Datum Reactie
  DatumReactie := entOpd.Value[tblOpd.MetaData.Items[7].FieldName];
  If (DatumReactie <> '') And (DatumReactie <> 'null') Then
  Begin
   ZoekT := Pos('T', DatumReactie);
   If ZoekT > 0 Then DatumReactie := Copy(DatumReactie, 1, ZoekT - 1);
   //Formaat Jaar - Maand- Dag is anders in Database dan in de Date component, dus herschikken.
   Jaar := Copy(DatumReactie, 1, 4);
   Maand := Copy(DatumReactie,6, 2);
   Dag := Copy(DatumReactie, 9, 2);
   DatumReactie := Dag + '-' + Maand + '-' + Jaar;
   OpdReactieDate.Date := StrToDate(DatumReactie);
  End Else OpdReactieDate.Date := StrToDate('01-01-2022');

  ////Datum Gepland
  DatumGepland := entOpd.Value[tblOpd.MetaData.Items[16].FieldName];
  If (DatumGepland <> '') And (DatumGepland <> 'null') Then
  Begin
   ZoekT := Pos('T', DatumGepland);
   If ZoekT > 0 Then DatumGepland := Copy(DatumGepland, 1, ZoekT - 1);
   //Formaat Jaar - Maand- Dag is anders in Database dan in de Date component, dus herschikken.
   Jaar := Copy(DatumGepland, 1, 4);
   Maand := Copy(DatumGepland,6, 2);
   Dag := Copy(DatumGepland, 9, 2);
   DatumGepland := Dag + '-' + Maand + '-' + Jaar;
   OpdGeplandDate.Date := StrToDate(DatumGepland);
  End Else OpdGeplandDate.Date := StrToDate('01-01-2022');


  ////Verslag
  OpdVerslag.Lines.Clear;
  Reeks := Nil;
  //Lees eerste BR uit en omzetten naar een Array van Strings
  OpdBR := entOpd.Value[tblOpd.MetaData.Items[14].FieldName];
  If (OpdBR <> '') And (OpdBR <> 'null') Then Reeks := OpdBR.Split(';');
  //Haal de Tekst op uit de database
  VerTekst := entOpd.Value[tblOpd.MetaData.Items[8].FieldName];

  //Als er geen Tekst is dan een kreet neerzetten.
  If (VerTekst = '') Or (VerTekst = 'null') Then OpdVerslag.Text := 'Nog geen Verslag.';

  //Er is maar 1 regel Tekst
  If (VerTekst <> '') And (VerTekst <> 'null') And (Reeks = Nil) Then OpdVerslag.Text := VerTekst;

  //Kijken of er een Tekst is en eventueel de totale tekst in delen splitsen
  If (VerTekst <> '') And (Length(Reeks) > 0) Then
  Begin
   For Vuller := 0 To Length(Reeks) - 2 Do
   Begin
    If Reeks[Vuller] <> '' Then
    Begin
     Regel := '';
     Regel := Copy(VerTekst, 1, StrToInt(Reeks[Vuller]) + 1);
     VerTekst := VerTekst.Remove(0, Length(Regel));
     OpdVerslag.Lines.Add(Regel);
    End;
   End;
   OpdVerslag.Lines.Add(VerTekst);
  End;

  ////Datum Verslag
  DatumVerslag := entOpd.Value[tblOpd.MetaData.Items[9].FieldName];
  If (DatumVerslag <> '') And (DatumVerslag <> 'null') Then
  Begin
   ZoekT := Pos('T', DatumVerslag);
   If ZoekT > 0 Then DatumVerslag := Copy(DatumVerslag, 1, ZoekT - 1);
   //Formaat Jaar - Maand- Dag is anders in Database dan in de Date component, dus herschikken.
   Jaar := Copy(DatumVerslag, 1, 4);
   Maand := Copy(DatumVerslag,6, 2);
   Dag := Copy(DatumVerslag, 9, 2);
   DatumVerslag := Dag + '-' + Maand + '-' + Jaar;
   OpdVerslagDate.Date := StrToDate(DatumVerslag);
  End Else OpdVerslagDate.Date := StrToDate('01-01-2022');

   ////Opmerkingen
  OpdAlgOpm.Lines.Clear;
  Reeks := Nil;
  //Lees eerste BR uit en omzetten naar een Array van Strings
  OpdBR := entOpd.Value[tblOpd.MetaData.Items[15].FieldName];
  If (OpdBR <> '') And (OpdBR <> 'null') Then Reeks := OpdBR.Split(';');
  //Haal de Tekst op uit de database
  OpmTekst := entOpd.Value[tblOpd.MetaData.Items[10].FieldName];

  //Als er geen Tekst is dan een kreet neerzetten.
  If (OpmTekst = '') Or (OpmTekst = 'null') Then OpdAlgOpm.Text := 'Nog geen Opmerkingen.';

  //Er is maar 1 regel Tekst
  If (OpmTekst <> '') And (OpmTekst <> 'null') And (Reeks = Nil) Then OpdAlgOpm.Text := OpmTekst;

  //Kijken of er een Tekst is en eventueel de totale tekst in delen splitsen
  If (OpmTekst <> '') And (Length(Reeks) > 0) Then
  Begin
   For Vuller := 0 To Length(Reeks) - 2 Do
   Begin
    If Reeks[Vuller] <> '' Then
    Begin
     Regel := '';
     Regel := Copy(OpmTekst, 1, StrToInt(Reeks[Vuller]) + 1);
     OpmTekst := OpmTekst.Remove(0, Length(Regel));
     OpdAlgOpm.Lines.Add(Regel);
    End;
   End;
   OpdAlgOpm.Lines.Add(OpmTekst);
  End;


  If entOpd.Value[tblOpd.MetaData.Items[11].FieldName] = 'Ja' Then VeldOpdActief.Checked := True Else VeldOpdActief.Checked := False;

  If ((entOpd.Value[tblOpd.MetaData.Items[5].FieldName] = '0') Or
     (entOpd.Value[tblOpd.MetaData.Items[5].FieldName] = 'null')) Then OpdStatus.ItemIndex := -1
  Else
  Begin
   SrtStatus := StrToInt(entOpd.Value[tblOpd.MetaData.Items[5].FieldName]);
   If SrtStatus In [1..4] Then OpdStatus.ItemIndex := SrtStatus - 1;
  End;

  //Altijd vastleggen wat de Status van een Opdracht is.
  StatusWas := OpdStatus.ItemIndex;

  If ILBesteller = 1 Then
  Begin
   If StatusWas = -1 Then OpdStatus.Enabled := True Else OpdStatus.Enabled := False;
  End;

 end;

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OpdListBox2Click(Sender: TObject);
begin
 OpdListBox2Change(Self);
end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}

procedure TForm1.OnderdelenControlChange(Sender: TObject);
var tbl: TmyCloudDataTable;
begin

 Case OnderdelenControl.ActivePageIndex Of
 0 : Begin //Gebruikers
      If Toegang = 1 Then
       If (ILBeheerder = 1) Or (ILUitvoerder = 1) Then //Jan
       Begin
        tbl := CloudAlles.Tables.Items[0];
        tbl.GetEntities;
        //GebShowTables;
       End;
     End;
 1 : Begin //Locaties
      If Toegang = 1 Then
       If (ILBeheerder = 1) Or (ILUitvoerder = 1) Then //Jan
       Begin
        tbl := CloudAlles.Tables.Items[1];
        tbl.GetEntities;
        //LocShowTables;
       End;
     End;
 2 : Begin //Opdrachten
      If Toegang = 1 Then
       If (ILBeheerder = 1) Or (ILUitvoerder = 1) Then //Jan
       Begin
        tbl := CloudAlles.Tables.Items[2];
        tbl.GetEntities;
        //OpdShowTables;
       End;
     End;
 End; {Case}

end;

{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}
{MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM}










procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Basis := TPanel.Create(Self);
  WebPageControl := TPageControl.Create(Self);
  WPCInloggen := TTabSheet.Create(Self);
  WebLoginPanel := TLoginPanel.Create(Self);
  WPCDatabase := TTabSheet.Create(Self);
  OnderdelenControl := TPageControl.Create(Self);
  PageGebruikers := TTabSheet.Create(Self);
  GebNaam := TLabel.Create(Self);
  GebCR := TLabel.Create(Self);
  GebLogo := TImageControl.Create(Self);
  lblInloggerGeb := TLabel.Create(Self);
  NaamInloggerGeb := TLabel.Create(Self);
  GebDoeEntities := TButton.Create(Self);
  VulBox := TButton.Create(Self);
  GebListBox1 := TListBox.Create(Self);
  GebListBox2 := TListBox.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  lblGebGebruiker := TLabel.Create(Self);
  lblGebWachtwoord := TLabel.Create(Self);
  lblGebNaam := TLabel.Create(Self);
  lblGebFunctie := TLabel.Create(Self);
  lblGebLocatie := TLabel.Create(Self);
  VeldGebGebruiker := TEdit.Create(Self);
  VeldGebWachtwoord := TEdit.Create(Self);
  VeldGebNaam := TEdit.Create(Self);
  GebbtnAdd := TBitBtn.Create(Self);
  GebbtnUpdate := TBitBtn.Create(Self);
  GebbtnDelete := TBitBtn.Create(Self);
  GebDBGrid := TDBGrid.Create(Self);
  GebDBNavigator := TDBNavigator.Create(Self);
  GebRefresh := TButton.Create(Self);
  VeldGebFunctie := TEdit.Create(Self);
  VeldGebLocatie := TEdit.Create(Self);
  VeldGebActief := TCheckBox.Create(Self);
  WebGroupBox1 := TGroupBox.Create(Self);
  lblBeheerder := TLabel.Create(Self);
  lblBesteller := TLabel.Create(Self);
  lblUitvoerder := TLabel.Create(Self);
  lblControleur := TLabel.Create(Self);
  SoortFunctie := TCheckGroup.Create(Self);
  GebToonWW := TCheckBox.Create(Self);
  GebActief := TCheckBox.Create(Self);
  Actief := TButton.Create(Self);
  PageLocaties := TTabSheet.Create(Self);
  LocNaam := TLabel.Create(Self);
  LocCR := TLabel.Create(Self);
  LocLogo := TImageControl.Create(Self);
  lblInloggerLoc := TLabel.Create(Self);
  NaamInloggerLoc := TLabel.Create(Self);
  LocListBox1 := TListBox.Create(Self);
  LocListBox2 := TListBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  lblLocLocatie := TLabel.Create(Self);
  lblLocAdres := TLabel.Create(Self);
  lblLocContactpersoon := TLabel.Create(Self);
  lblLocEmailadres := TLabel.Create(Self);
  VeldLocLocatie := TEdit.Create(Self);
  VeldLocAdres := TEdit.Create(Self);
  VeldLocContactpersoon := TEdit.Create(Self);
  LocbtnAdd := TBitBtn.Create(Self);
  LocbtnUpdate := TBitBtn.Create(Self);
  LocbtnDelete := TBitBtn.Create(Self);
  LocDbGrid := TDBGrid.Create(Self);
  LocDBNavigator := TDBNavigator.Create(Self);
  LocRefresh := TButton.Create(Self);
  VeldLocEmailadres := TEdit.Create(Self);
  VeldLocActief := TCheckBox.Create(Self);
  SoortBieb := TRadioGroup.Create(Self);
  LocDoeEntities := TButton.Create(Self);
  LocActief := TCheckBox.Create(Self);
  PageOpdrachten := TTabSheet.Create(Self);
  lblFilteren := TLabel.Create(Self);
  OpdNaam := TLabel.Create(Self);
  OpdCR := TLabel.Create(Self);
  OpdLogo := TImageControl.Create(Self);
  lblInloggerOpd := TLabel.Create(Self);
  NaamInloggerOpd := TLabel.Create(Self);
  OpdActief := TCheckBox.Create(Self);
  OpdListBox2 := TListBox.Create(Self);
  OpdListBox1 := TListBox.Create(Self);
  OpdDoeEntities := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  lblOpdLocatie := TLabel.Create(Self);
  lblOpdContactpersoon := TLabel.Create(Self);
  lblOpdracht := TLabel.Create(Self);
  lblOpdDatum := TLabel.Create(Self);
  lblReactie := TLabel.Create(Self);
  lblReactieDatum := TLabel.Create(Self);
  lblVerslag := TLabel.Create(Self);
  lblGeplandDatum := TLabel.Create(Self);
  lblVerslagDatum := TLabel.Create(Self);
  lblAlgemeneOpmerkingen := TLabel.Create(Self);
  lblOpdrachtgever := TLabel.Create(Self);
  ReserveTekst := TMemo.Create(Self);
  OpdbtnAdd := TBitBtn.Create(Self);
  OpdbtnUpdate := TBitBtn.Create(Self);
  OpdbtnDelete := TBitBtn.Create(Self);
  VeldOpdLocatie := TEdit.Create(Self);
  VeldOpdContactpersoon := TEdit.Create(Self);
  OpdOpdrachtDate := TDateTimePicker.Create(Self);
  OpdStatus := TRadioGroup.Create(Self);
  VeldOpdActief := TCheckBox.Create(Self);
  OpdOpdracht := TMemo.Create(Self);
  OpdbtnNieuw := TBitBtn.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  OpdMetaDataCheck := TCheckBox.Create(Self);
  OpdReactie := TMemo.Create(Self);
  OpdReactieDate := TDateTimePicker.Create(Self);
  OpdVerslag := TMemo.Create(Self);
  OpdGeplandDate := TDateTimePicker.Create(Self);
  OpdVerslagDate := TDateTimePicker.Create(Self);
  OpdAlgOpm := TMemo.Create(Self);
  VeldOpdOpdrachtgever := TComboBox.Create(Self);
  FilterBox := TComboBox.Create(Self);
  lblTeller := TLabel.Create(Self);
  WebMyCloudDbClientDataset := TMyCloudDbClientDataset.Create(Self);
  WebDataSource := TDataSource.Create(Self);
  CloudAlles := TmyCloudData.Create(Self);

  Basis.BeforeLoadDFMValues;
  WebPageControl.BeforeLoadDFMValues;
  WPCInloggen.BeforeLoadDFMValues;
  WebLoginPanel.BeforeLoadDFMValues;
  WPCDatabase.BeforeLoadDFMValues;
  OnderdelenControl.BeforeLoadDFMValues;
  PageGebruikers.BeforeLoadDFMValues;
  GebNaam.BeforeLoadDFMValues;
  GebCR.BeforeLoadDFMValues;
  GebLogo.BeforeLoadDFMValues;
  lblInloggerGeb.BeforeLoadDFMValues;
  NaamInloggerGeb.BeforeLoadDFMValues;
  GebDoeEntities.BeforeLoadDFMValues;
  VulBox.BeforeLoadDFMValues;
  GebListBox1.BeforeLoadDFMValues;
  GebListBox2.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  lblGebGebruiker.BeforeLoadDFMValues;
  lblGebWachtwoord.BeforeLoadDFMValues;
  lblGebNaam.BeforeLoadDFMValues;
  lblGebFunctie.BeforeLoadDFMValues;
  lblGebLocatie.BeforeLoadDFMValues;
  VeldGebGebruiker.BeforeLoadDFMValues;
  VeldGebWachtwoord.BeforeLoadDFMValues;
  VeldGebNaam.BeforeLoadDFMValues;
  GebbtnAdd.BeforeLoadDFMValues;
  GebbtnUpdate.BeforeLoadDFMValues;
  GebbtnDelete.BeforeLoadDFMValues;
  GebDBGrid.BeforeLoadDFMValues;
  GebDBNavigator.BeforeLoadDFMValues;
  GebRefresh.BeforeLoadDFMValues;
  VeldGebFunctie.BeforeLoadDFMValues;
  VeldGebLocatie.BeforeLoadDFMValues;
  VeldGebActief.BeforeLoadDFMValues;
  WebGroupBox1.BeforeLoadDFMValues;
  lblBeheerder.BeforeLoadDFMValues;
  lblBesteller.BeforeLoadDFMValues;
  lblUitvoerder.BeforeLoadDFMValues;
  lblControleur.BeforeLoadDFMValues;
  SoortFunctie.BeforeLoadDFMValues;
  GebToonWW.BeforeLoadDFMValues;
  GebActief.BeforeLoadDFMValues;
  Actief.BeforeLoadDFMValues;
  PageLocaties.BeforeLoadDFMValues;
  LocNaam.BeforeLoadDFMValues;
  LocCR.BeforeLoadDFMValues;
  LocLogo.BeforeLoadDFMValues;
  lblInloggerLoc.BeforeLoadDFMValues;
  NaamInloggerLoc.BeforeLoadDFMValues;
  LocListBox1.BeforeLoadDFMValues;
  LocListBox2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  lblLocLocatie.BeforeLoadDFMValues;
  lblLocAdres.BeforeLoadDFMValues;
  lblLocContactpersoon.BeforeLoadDFMValues;
  lblLocEmailadres.BeforeLoadDFMValues;
  VeldLocLocatie.BeforeLoadDFMValues;
  VeldLocAdres.BeforeLoadDFMValues;
  VeldLocContactpersoon.BeforeLoadDFMValues;
  LocbtnAdd.BeforeLoadDFMValues;
  LocbtnUpdate.BeforeLoadDFMValues;
  LocbtnDelete.BeforeLoadDFMValues;
  LocDbGrid.BeforeLoadDFMValues;
  LocDBNavigator.BeforeLoadDFMValues;
  LocRefresh.BeforeLoadDFMValues;
  VeldLocEmailadres.BeforeLoadDFMValues;
  VeldLocActief.BeforeLoadDFMValues;
  SoortBieb.BeforeLoadDFMValues;
  LocDoeEntities.BeforeLoadDFMValues;
  LocActief.BeforeLoadDFMValues;
  PageOpdrachten.BeforeLoadDFMValues;
  lblFilteren.BeforeLoadDFMValues;
  OpdNaam.BeforeLoadDFMValues;
  OpdCR.BeforeLoadDFMValues;
  OpdLogo.BeforeLoadDFMValues;
  lblInloggerOpd.BeforeLoadDFMValues;
  NaamInloggerOpd.BeforeLoadDFMValues;
  OpdActief.BeforeLoadDFMValues;
  OpdListBox2.BeforeLoadDFMValues;
  OpdListBox1.BeforeLoadDFMValues;
  OpdDoeEntities.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  lblOpdLocatie.BeforeLoadDFMValues;
  lblOpdContactpersoon.BeforeLoadDFMValues;
  lblOpdracht.BeforeLoadDFMValues;
  lblOpdDatum.BeforeLoadDFMValues;
  lblReactie.BeforeLoadDFMValues;
  lblReactieDatum.BeforeLoadDFMValues;
  lblVerslag.BeforeLoadDFMValues;
  lblGeplandDatum.BeforeLoadDFMValues;
  lblVerslagDatum.BeforeLoadDFMValues;
  lblAlgemeneOpmerkingen.BeforeLoadDFMValues;
  lblOpdrachtgever.BeforeLoadDFMValues;
  ReserveTekst.BeforeLoadDFMValues;
  OpdbtnAdd.BeforeLoadDFMValues;
  OpdbtnUpdate.BeforeLoadDFMValues;
  OpdbtnDelete.BeforeLoadDFMValues;
  VeldOpdLocatie.BeforeLoadDFMValues;
  VeldOpdContactpersoon.BeforeLoadDFMValues;
  OpdOpdrachtDate.BeforeLoadDFMValues;
  OpdStatus.BeforeLoadDFMValues;
  VeldOpdActief.BeforeLoadDFMValues;
  OpdOpdracht.BeforeLoadDFMValues;
  OpdbtnNieuw.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  OpdMetaDataCheck.BeforeLoadDFMValues;
  OpdReactie.BeforeLoadDFMValues;
  OpdReactieDate.BeforeLoadDFMValues;
  OpdVerslag.BeforeLoadDFMValues;
  OpdGeplandDate.BeforeLoadDFMValues;
  OpdVerslagDate.BeforeLoadDFMValues;
  OpdAlgOpm.BeforeLoadDFMValues;
  VeldOpdOpdrachtgever.BeforeLoadDFMValues;
  FilterBox.BeforeLoadDFMValues;
  lblTeller.BeforeLoadDFMValues;
  WebMyCloudDbClientDataset.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  CloudAlles.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 1361;
    Height := 1340;
    Visible := True;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    Basis.SetParentComponent(Self);
    Basis.Name := 'Basis';
    Basis.Left := 0;
    Basis.Top := 0;
    Basis.Width := 1200;
    Basis.Height := 1330;
    Basis.ChildOrder := 1;
    Basis.Color := clWhite;
    WebPageControl.SetParentComponent(Basis);
    WebPageControl.Name := 'WebPageControl';
    WebPageControl.Left := 0;
    WebPageControl.Top := 0;
    WebPageControl.Width := 1200;
    WebPageControl.Height := 1330;
    WebPageControl.Align := alClient;
    WebPageControl.ChildOrder := 15;
    WebPageControl.Font.Charset := DEFAULT_CHARSET;
    WebPageControl.Font.Color := clWindowText;
    WebPageControl.Font.Height := -13;
    WebPageControl.Font.Name := 'Tahoma';
    WebPageControl.Font.Style := [];
    WebPageControl.TabIndex := 1;
    WebPageControl.ParentFont := False;
    WebPageControl.ShowTabs := False;
    WebPageControl.TabOrder := 0;
    WPCInloggen.SetParentComponent(WebPageControl);
    WPCInloggen.Name := 'WPCInloggen';
    WPCInloggen.Left := 0;
    WPCInloggen.Top := 20;
    WPCInloggen.Width := 1200;
    WPCInloggen.Height := 1310;
    WPCInloggen.Caption := 'Inloggen';
    WebLoginPanel.SetParentComponent(WPCInloggen);
    WebLoginPanel.Name := 'WebLoginPanel';
    WebLoginPanel.Left := 148;
    WebLoginPanel.Top := 97;
    WebLoginPanel.Width := 300;
    WebLoginPanel.Height := 180;
    WebLoginPanel.CaptionLabel := 'Inlog gegevens';
    WebLoginPanel.LoginLabel := 'Inloggen';
    WebLoginPanel.PasswordLabel := 'Wachtwoord:';
    WebLoginPanel.UserLabel := 'Gebruiker:';
    SetEvent(WebLoginPanel, Self, 'OnLogin', 'WebLogin');
    WPCDatabase.SetParentComponent(WebPageControl);
    WPCDatabase.Name := 'WPCDatabase';
    WPCDatabase.Left := 0;
    WPCDatabase.Top := 20;
    WPCDatabase.Width := 1200;
    WPCDatabase.Height := 1310;
    WPCDatabase.Caption := 'Database';
    WPCDatabase.ChildOrder := 1;
    WPCDatabase.ShowCaption := False;
    OnderdelenControl.SetParentComponent(WPCDatabase);
    OnderdelenControl.Name := 'OnderdelenControl';
    OnderdelenControl.Left := 0;
    OnderdelenControl.Top := 0;
    OnderdelenControl.Width := 1200;
    OnderdelenControl.Height := 1310;
    OnderdelenControl.Align := alClient;
    OnderdelenControl.ChildOrder := 9;
    OnderdelenControl.Font.Charset := DEFAULT_CHARSET;
    OnderdelenControl.Font.Color := clWindowText;
    OnderdelenControl.Font.Height := -13;
    OnderdelenControl.Font.Name := 'Tahoma';
    OnderdelenControl.Font.Style := [];
    OnderdelenControl.TabIndex := 2;
    OnderdelenControl.ParentFont := False;
    OnderdelenControl.TabOrder := 0;
    SetEvent(OnderdelenControl, Self, 'OnChange', 'OnderdelenControlChange');
    PageGebruikers.SetParentComponent(OnderdelenControl);
    PageGebruikers.Name := 'PageGebruikers';
    PageGebruikers.Left := 0;
    PageGebruikers.Top := 20;
    PageGebruikers.Width := 1200;
    PageGebruikers.Height := 1290;
    PageGebruikers.Caption := 'Gebruikers';
    GebNaam.SetParentComponent(PageGebruikers);
    GebNaam.Name := 'GebNaam';
    GebNaam.Left := 990;
    GebNaam.Top := 160;
    GebNaam.Width := 139;
    GebNaam.Height := 23;
    GebNaam.Caption := 'Klussenbeheer';
    GebNaam.Font.Charset := DEFAULT_CHARSET;
    GebNaam.Font.Color := clGray;
    GebNaam.Font.Height := -19;
    GebNaam.Font.Name := 'Tahoma';
    GebNaam.Font.Style := [fsBold];
    GebNaam.HeightPercent := 100.000000000000000000;
    GebNaam.ParentFont := False;
    GebNaam.WidthPercent := 100.000000000000000000;
    GebCR.SetParentComponent(PageGebruikers);
    GebCR.Name := 'GebCR';
    GebCR.Left := 945;
    GebCR.Top := 11;
    GebCR.Width := 8;
    GebCR.Height := 16;
    GebCR.Caption := 'C';
    GebCR.Font.Charset := DEFAULT_CHARSET;
    GebCR.Font.Color := 2454527;
    GebCR.Font.Height := -13;
    GebCR.Font.Name := 'Tahoma';
    GebCR.Font.Style := [fsBold];
    GebCR.HeightPercent := 100.000000000000000000;
    GebCR.ParentFont := False;
    GebCR.WidthPercent := 100.000000000000000000;
    GebLogo.SetParentComponent(PageGebruikers);
    GebLogo.Name := 'GebLogo';
    GebLogo.Left := 938;
    GebLogo.Top := 40;
    GebLogo.Width := 237;
    GebLogo.Height := 107;
    GebLogo.HeightPercent := 100.000000000000000000;
    GebLogo.WidthPercent := 100.000000000000000000;
    GebLogo.ChildOrder := 8;
    GebLogo.Picture.LoadFromFile('Unit1.PageGebruikers.GebLogo.Picture.jpg');
    lblInloggerGeb.SetParentComponent(PageGebruikers);
    lblInloggerGeb.Name := 'lblInloggerGeb';
    lblInloggerGeb.Left := 536;
    lblInloggerGeb.Top := 11;
    lblInloggerGeb.Width := 63;
    lblInloggerGeb.Height := 16;
    lblInloggerGeb.Caption := 'Inlogger: ';
    lblInloggerGeb.Font.Charset := DEFAULT_CHARSET;
    lblInloggerGeb.Font.Color := 21484;
    lblInloggerGeb.Font.Height := -13;
    lblInloggerGeb.Font.Name := 'Tahoma';
    lblInloggerGeb.Font.Style := [fsBold];
    lblInloggerGeb.HeightPercent := 100.000000000000000000;
    lblInloggerGeb.ParentFont := False;
    lblInloggerGeb.WidthPercent := 100.000000000000000000;
    NaamInloggerGeb.SetParentComponent(PageGebruikers);
    NaamInloggerGeb.Name := 'NaamInloggerGeb';
    NaamInloggerGeb.Left := 605;
    NaamInloggerGeb.Top := 11;
    NaamInloggerGeb.Width := 9;
    NaamInloggerGeb.Height := 16;
    NaamInloggerGeb.Caption := 'X';
    NaamInloggerGeb.Font.Charset := DEFAULT_CHARSET;
    NaamInloggerGeb.Font.Color := 21484;
    NaamInloggerGeb.Font.Height := -13;
    NaamInloggerGeb.Font.Name := 'Tahoma';
    NaamInloggerGeb.Font.Style := [fsBold];
    NaamInloggerGeb.HeightPercent := 100.000000000000000000;
    NaamInloggerGeb.ParentFont := False;
    NaamInloggerGeb.WidthPercent := 100.000000000000000000;
    GebDoeEntities.SetParentComponent(PageGebruikers);
    GebDoeEntities.Name := 'GebDoeEntities';
    GebDoeEntities.Left := 938;
    GebDoeEntities.Top := 86;
    GebDoeEntities.Width := 96;
    GebDoeEntities.Height := 25;
    GebDoeEntities.Caption := 'Toon Records';
    GebDoeEntities.ChildOrder := 2;
    GebDoeEntities.HeightPercent := 100.000000000000000000;
    GebDoeEntities.TabOrder := 1;
    GebDoeEntities.Visible := False;
    GebDoeEntities.WidthPercent := 100.000000000000000000;
    SetEvent(GebDoeEntities, Self, 'OnClick', 'GebDoeEntitiesClick');
    VulBox.SetParentComponent(PageGebruikers);
    VulBox.Name := 'VulBox';
    VulBox.Left := 1067;
    VulBox.Top := 127;
    VulBox.Width := 96;
    VulBox.Height := 25;
    VulBox.Caption := 'VulBox';
    VulBox.ChildOrder := 9;
    VulBox.HeightPercent := 100.000000000000000000;
    VulBox.TabOrder := 2;
    VulBox.Visible := False;
    VulBox.WidthPercent := 100.000000000000000000;
    SetEvent(VulBox, Self, 'OnClick', 'VulBoxClick');
    GebListBox1.SetParentComponent(PageGebruikers);
    GebListBox1.Name := 'GebListBox1';
    GebListBox1.Left := 938;
    GebListBox1.Top := 40;
    GebListBox1.Width := 225;
    GebListBox1.Height := 40;
    GebListBox1.HeightPercent := 100.000000000000000000;
    GebListBox1.TabOrder := 3;
    GebListBox1.Visible := False;
    GebListBox1.WidthPercent := 100.000000000000000000;
    GebListBox1.ItemIndex := -1;
    GebListBox2.SetParentComponent(PageGebruikers);
    GebListBox2.Name := 'GebListBox2';
    GebListBox2.Left := 12;
    GebListBox2.Top := 40;
    GebListBox2.Width := 910;
    GebListBox2.Height := 150;
    GebListBox2.HeightPercent := 100.000000000000000000;
    GebListBox2.TabOrder := 4;
    GebListBox2.WidthPercent := 100.000000000000000000;
    SetEvent(GebListBox2, Self, 'OnChange', 'GebListBox2Change');
    GebListBox2.ItemIndex := -1;
    WebPanel1.SetParentComponent(PageGebruikers);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 12;
    WebPanel1.Top := 200;
    WebPanel1.Width := 1169;
    WebPanel1.Height := 550;
    WebPanel1.ChildOrder := 7;
    WebPanel1.Color := clWhite;
    lblGebGebruiker.SetParentComponent(WebPanel1);
    lblGebGebruiker.Name := 'lblGebGebruiker';
    lblGebGebruiker.Left := 15;
    lblGebGebruiker.Top := 18;
    lblGebGebruiker.Width := 93;
    lblGebGebruiker.Height := 16;
    lblGebGebruiker.Caption := 'Gebruikersnaam';
    lblGebGebruiker.HeightPercent := 100.000000000000000000;
    lblGebGebruiker.WidthPercent := 100.000000000000000000;
    lblGebWachtwoord.SetParentComponent(WebPanel1);
    lblGebWachtwoord.Name := 'lblGebWachtwoord';
    lblGebWachtwoord.Left := 15;
    lblGebWachtwoord.Top := 57;
    lblGebWachtwoord.Width := 72;
    lblGebWachtwoord.Height := 16;
    lblGebWachtwoord.Caption := 'Wachtwoord';
    lblGebWachtwoord.HeightPercent := 100.000000000000000000;
    lblGebWachtwoord.WidthPercent := 100.000000000000000000;
    lblGebNaam.SetParentComponent(WebPanel1);
    lblGebNaam.Name := 'lblGebNaam';
    lblGebNaam.Left := 15;
    lblGebNaam.Top := 96;
    lblGebNaam.Width := 33;
    lblGebNaam.Height := 16;
    lblGebNaam.Caption := 'Naam';
    lblGebNaam.HeightPercent := 100.000000000000000000;
    lblGebNaam.WidthPercent := 100.000000000000000000;
    lblGebFunctie.SetParentComponent(WebPanel1);
    lblGebFunctie.Name := 'lblGebFunctie';
    lblGebFunctie.Left := 15;
    lblGebFunctie.Top := 135;
    lblGebFunctie.Width := 41;
    lblGebFunctie.Height := 16;
    lblGebFunctie.Caption := 'Functie';
    lblGebFunctie.HeightPercent := 100.000000000000000000;
    lblGebFunctie.WidthPercent := 100.000000000000000000;
    lblGebLocatie.SetParentComponent(WebPanel1);
    lblGebLocatie.Name := 'lblGebLocatie';
    lblGebLocatie.Left := 15;
    lblGebLocatie.Top := 174;
    lblGebLocatie.Width := 40;
    lblGebLocatie.Height := 16;
    lblGebLocatie.Caption := 'Locatie';
    lblGebLocatie.HeightPercent := 100.000000000000000000;
    lblGebLocatie.WidthPercent := 100.000000000000000000;
    VeldGebGebruiker.SetParentComponent(WebPanel1);
    VeldGebGebruiker.Name := 'VeldGebGebruiker';
    VeldGebGebruiker.Left := 115;
    VeldGebGebruiker.Top := 15;
    VeldGebGebruiker.Width := 200;
    VeldGebGebruiker.Height := 22;
    VeldGebGebruiker.ChildOrder := 3;
    VeldGebGebruiker.HeightPercent := 100.000000000000000000;
    VeldGebGebruiker.WidthPercent := 100.000000000000000000;
    VeldGebWachtwoord.SetParentComponent(WebPanel1);
    VeldGebWachtwoord.Name := 'VeldGebWachtwoord';
    VeldGebWachtwoord.Left := 115;
    VeldGebWachtwoord.Top := 54;
    VeldGebWachtwoord.Width := 200;
    VeldGebWachtwoord.Height := 22;
    VeldGebWachtwoord.ChildOrder := 1;
    VeldGebWachtwoord.HeightPercent := 100.000000000000000000;
    VeldGebWachtwoord.PasswordChar := '*';
    VeldGebWachtwoord.TabOrder := 1;
    VeldGebWachtwoord.WidthPercent := 100.000000000000000000;
    VeldGebNaam.SetParentComponent(WebPanel1);
    VeldGebNaam.Name := 'VeldGebNaam';
    VeldGebNaam.Left := 115;
    VeldGebNaam.Top := 93;
    VeldGebNaam.Width := 200;
    VeldGebNaam.Height := 22;
    VeldGebNaam.ChildOrder := 1;
    VeldGebNaam.HeightPercent := 100.000000000000000000;
    VeldGebNaam.TabOrder := 2;
    VeldGebNaam.WidthPercent := 100.000000000000000000;
    GebbtnAdd.SetParentComponent(WebPanel1);
    GebbtnAdd.Name := 'GebbtnAdd';
    GebbtnAdd.Left := 21;
    GebbtnAdd.Top := 483;
    GebbtnAdd.Width := 80;
    GebbtnAdd.Height := 25;
    GebbtnAdd.Caption := 'Toevoegen';
    GebbtnAdd.Color := clNone;
    GebbtnAdd.HeightPercent := 100.000000000000000000;
    GebbtnAdd.TabOrder := 6;
    GebbtnAdd.WidthPercent := 100.000000000000000000;
    SetEvent(GebbtnAdd, Self, 'OnClick', 'GebbtnAddClick');
    GebbtnUpdate.SetParentComponent(WebPanel1);
    GebbtnUpdate.Name := 'GebbtnUpdate';
    GebbtnUpdate.Left := 129;
    GebbtnUpdate.Top := 483;
    GebbtnUpdate.Width := 80;
    GebbtnUpdate.Height := 25;
    GebbtnUpdate.Caption := 'Updaten';
    GebbtnUpdate.Color := clNone;
    GebbtnUpdate.HeightPercent := 100.000000000000000000;
    GebbtnUpdate.TabOrder := 7;
    GebbtnUpdate.WidthPercent := 100.000000000000000000;
    SetEvent(GebbtnUpdate, Self, 'OnClick', 'GebbtnUpdateClick');
    GebbtnDelete.SetParentComponent(WebPanel1);
    GebbtnDelete.Name := 'GebbtnDelete';
    GebbtnDelete.Left := 245;
    GebbtnDelete.Top := 483;
    GebbtnDelete.Width := 80;
    GebbtnDelete.Height := 25;
    GebbtnDelete.Caption := 'Wissen';
    GebbtnDelete.Color := clNone;
    GebbtnDelete.HeightPercent := 100.000000000000000000;
    GebbtnDelete.TabOrder := 8;
    GebbtnDelete.WidthPercent := 100.000000000000000000;
    SetEvent(GebbtnDelete, Self, 'OnClick', 'GebbtnDeleteClick');
    GebDBGrid.SetParentComponent(WebPanel1);
    GebDBGrid.Name := 'GebDBGrid';
    GebDBGrid.Left := 336;
    GebDBGrid.Top := 50;
    GebDBGrid.Width := 817;
    GebDBGrid.Height := 281;
    GebDBGrid.Columns.Clear;
    with GebDBGrid.Columns.Add do
    begin
      Width := 25;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 30;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with GebDBGrid.Columns.Add do
    begin
      Width := 150;
    end;
    GebDBGrid.DataSource := WebDataSource;
    GebDBGrid.FixedCols := 0;
    GebDBGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedColClick];
    GebDBGrid.TabOrder := 10;
    GebDBGrid.HeightPercent := 100.000000000000000000;
    GebDBGrid.WidthPercent := 100.000000000000000000;
    SetEvent(GebDBGrid, Self, 'OnClickCell', 'GebDBGridClickCell');
    SetEvent(GebDBGrid, Self, 'OnFixedCellClick', 'GebDBGridFixedCellClick');
    GebDBGrid.ColWidths[0] := 25;
    GebDBGrid.ColWidths[1] := 30;
    GebDBGrid.ColWidths[2] := 150;
    GebDBGrid.ColWidths[3] := 150;
    GebDBGrid.ColWidths[4] := 150;
    GebDBGrid.ColWidths[5] := 150;
    GebDBGrid.ColWidths[6] := 150;
    GebDBNavigator.SetParentComponent(WebPanel1);
    GebDBNavigator.Name := 'GebDBNavigator';
    GebDBNavigator.Left := 336;
    GebDBNavigator.Top := 15;
    GebDBNavigator.Width := 817;
    GebDBNavigator.Height := 25;
    GebDBNavigator.DataSource := WebDataSource;
    GebDBNavigator.Hints.BeginUpdate;
    try
      GebDBNavigator.Hints.Clear;
      GebDBNavigator.Hints.Add('First');
      GebDBNavigator.Hints.Add('Prior');
      GebDBNavigator.Hints.Add('Next');
      GebDBNavigator.Hints.Add('Last');
      GebDBNavigator.Hints.Add('Edit');
      GebDBNavigator.Hints.Add('Post');
      GebDBNavigator.Hints.Add('Insert');
      GebDBNavigator.Hints.Add('Delete');
      GebDBNavigator.Hints.Add('Cancel');
    finally
      GebDBNavigator.Hints.EndUpdate;
    end;
    GebRefresh.SetParentComponent(WebPanel1);
    GebRefresh.Name := 'GebRefresh';
    GebRefresh.Left := 352;
    GebRefresh.Top := 483;
    GebRefresh.Width := 96;
    GebRefresh.Height := 25;
    GebRefresh.Caption := 'Bijwerken';
    GebRefresh.ChildOrder := 11;
    GebRefresh.HeightPercent := 100.000000000000000000;
    GebRefresh.TabOrder := 9;
    GebRefresh.Visible := False;
    GebRefresh.WidthPercent := 100.000000000000000000;
    SetEvent(GebRefresh, Self, 'OnClick', 'GebRefreshClick');
    VeldGebFunctie.SetParentComponent(WebPanel1);
    VeldGebFunctie.Name := 'VeldGebFunctie';
    VeldGebFunctie.Left := 115;
    VeldGebFunctie.Top := 132;
    VeldGebFunctie.Width := 200;
    VeldGebFunctie.Height := 22;
    VeldGebFunctie.ChildOrder := 1;
    VeldGebFunctie.HeightPercent := 100.000000000000000000;
    VeldGebFunctie.TabOrder := 3;
    VeldGebFunctie.WidthPercent := 100.000000000000000000;
    VeldGebLocatie.SetParentComponent(WebPanel1);
    VeldGebLocatie.Name := 'VeldGebLocatie';
    VeldGebLocatie.Left := 115;
    VeldGebLocatie.Top := 171;
    VeldGebLocatie.Width := 200;
    VeldGebLocatie.Height := 22;
    VeldGebLocatie.ChildOrder := 1;
    VeldGebLocatie.HeightPercent := 100.000000000000000000;
    VeldGebLocatie.TabOrder := 4;
    VeldGebLocatie.WidthPercent := 100.000000000000000000;
    VeldGebActief.SetParentComponent(WebPanel1);
    VeldGebActief.Name := 'VeldGebActief';
    VeldGebActief.Left := 111;
    VeldGebActief.Top := 207;
    VeldGebActief.Width := 62;
    VeldGebActief.Height := 22;
    VeldGebActief.Caption := 'Actief';
    VeldGebActief.ChildOrder := 16;
    VeldGebActief.HeightPercent := 100.000000000000000000;
    VeldGebActief.TabOrder := 5;
    VeldGebActief.WidthPercent := 100.000000000000000000;
    WebGroupBox1.SetParentComponent(WebPanel1);
    WebGroupBox1.Name := 'WebGroupBox1';
    WebGroupBox1.Left := 11;
    WebGroupBox1.Top := 242;
    WebGroupBox1.Width := 95;
    WebGroupBox1.Height := 113;
    WebGroupBox1.BorderColor := clRed;
    WebGroupBox1.Caption := ' Inlogger ';
    WebGroupBox1.ChildOrder := 25;
    WebGroupBox1.Color := clWhite;
    lblBeheerder.SetParentComponent(WebGroupBox1);
    lblBeheerder.Name := 'lblBeheerder';
    lblBeheerder.Left := 10;
    lblBeheerder.Top := 22;
    lblBeheerder.Width := 59;
    lblBeheerder.Height := 16;
    lblBeheerder.Caption := 'Beheerder';
    lblBeheerder.HeightPercent := 100.000000000000000000;
    lblBeheerder.WidthPercent := 100.000000000000000000;
    lblBesteller.SetParentComponent(WebGroupBox1);
    lblBesteller.Name := 'lblBesteller';
    lblBesteller.Left := 10;
    lblBesteller.Top := 42;
    lblBesteller.Width := 49;
    lblBesteller.Height := 16;
    lblBesteller.Caption := 'Besteller';
    lblBesteller.HeightPercent := 100.000000000000000000;
    lblBesteller.WidthPercent := 100.000000000000000000;
    lblUitvoerder.SetParentComponent(WebGroupBox1);
    lblUitvoerder.Name := 'lblUitvoerder';
    lblUitvoerder.Left := 10;
    lblUitvoerder.Top := 62;
    lblUitvoerder.Width := 59;
    lblUitvoerder.Height := 16;
    lblUitvoerder.Caption := 'Uitvoerder';
    lblUitvoerder.HeightPercent := 100.000000000000000000;
    lblUitvoerder.WidthPercent := 100.000000000000000000;
    lblControleur.SetParentComponent(WebGroupBox1);
    lblControleur.Name := 'lblControleur';
    lblControleur.Left := 10;
    lblControleur.Top := 82;
    lblControleur.Width := 60;
    lblControleur.Height := 16;
    lblControleur.Caption := 'Controleur';
    lblControleur.HeightPercent := 100.000000000000000000;
    lblControleur.WidthPercent := 100.000000000000000000;
    SoortFunctie.SetParentComponent(WebPanel1);
    SoortFunctie.Name := 'SoortFunctie';
    SoortFunctie.Left := 115;
    SoortFunctie.Top := 242;
    SoortFunctie.Width := 200;
    SoortFunctie.Height := 75;
    SoortFunctie.Caption := ' SoortFunctie ';
    SoortFunctie.ChildOrder := 23;
    SoortFunctie.Columns := 2;
    SoortFunctie.Items.BeginUpdate;
    try
      SoortFunctie.Items.Clear;
      SoortFunctie.Items.Add('Beheerder');
      SoortFunctie.Items.Add('Besteller');
      SoortFunctie.Items.Add('Uitvoerder');
      SoortFunctie.Items.Add('Controleur');
    finally
      SoortFunctie.Items.EndUpdate;
    end;
    SoortFunctie.Role := '';
    GebToonWW.SetParentComponent(WebPanel1);
    GebToonWW.Name := 'GebToonWW';
    GebToonWW.Left := 181;
    GebToonWW.Top := 207;
    GebToonWW.Width := 134;
    GebToonWW.Height := 22;
    GebToonWW.Caption := 'Toon wachtwoord';
    GebToonWW.ChildOrder := 16;
    GebToonWW.HeightPercent := 100.000000000000000000;
    GebToonWW.TabOrder := 14;
    GebToonWW.WidthPercent := 100.000000000000000000;
    SetEvent(GebToonWW, Self, 'OnClick', 'GebToonWWClick');
    GebActief.SetParentComponent(PageGebruikers);
    GebActief.Name := 'GebActief';
    GebActief.Left := 12;
    GebActief.Top := 12;
    GebActief.Width := 113;
    GebActief.Height := 22;
    GebActief.Caption := 'Alleen Actieven';
    GebActief.ChildOrder := 10;
    GebActief.HeightPercent := 100.000000000000000000;
    GebActief.TabOrder := 7;
    GebActief.WidthPercent := 100.000000000000000000;
    SetEvent(GebActief, Self, 'OnClick', 'GebActiefClick');
    Actief.SetParentComponent(PageGebruikers);
    Actief.Name := 'Actief';
    Actief.Left := 938;
    Actief.Top := 159;
    Actief.Width := 44;
    Actief.Height := 25;
    Actief.Caption := 'Actief';
    Actief.ChildOrder := 1;
    Actief.HeightPercent := 100.000000000000000000;
    Actief.TabOrder := 6;
    Actief.Visible := False;
    Actief.WidthPercent := 100.000000000000000000;
    SetEvent(Actief, Self, 'OnClick', 'ActiefClick');
    PageLocaties.SetParentComponent(OnderdelenControl);
    PageLocaties.Name := 'PageLocaties';
    PageLocaties.Left := 0;
    PageLocaties.Top := 20;
    PageLocaties.Width := 1200;
    PageLocaties.Height := 1290;
    PageLocaties.Caption := 'Locaties';
    PageLocaties.ChildOrder := 1;
    LocNaam.SetParentComponent(PageLocaties);
    LocNaam.Name := 'LocNaam';
    LocNaam.Left := 990;
    LocNaam.Top := 160;
    LocNaam.Width := 139;
    LocNaam.Height := 23;
    LocNaam.Caption := 'Klussenbeheer';
    LocNaam.Font.Charset := DEFAULT_CHARSET;
    LocNaam.Font.Color := clGray;
    LocNaam.Font.Height := -19;
    LocNaam.Font.Name := 'Tahoma';
    LocNaam.Font.Style := [fsBold];
    LocNaam.HeightPercent := 100.000000000000000000;
    LocNaam.ParentFont := False;
    LocNaam.WidthPercent := 100.000000000000000000;
    LocCR.SetParentComponent(PageLocaties);
    LocCR.Name := 'LocCR';
    LocCR.Left := 945;
    LocCR.Top := 11;
    LocCR.Width := 8;
    LocCR.Height := 16;
    LocCR.Caption := 'C';
    LocCR.Font.Charset := DEFAULT_CHARSET;
    LocCR.Font.Color := 2454527;
    LocCR.Font.Height := -13;
    LocCR.Font.Name := 'Tahoma';
    LocCR.Font.Style := [fsBold];
    LocCR.HeightPercent := 100.000000000000000000;
    LocCR.ParentFont := False;
    LocCR.WidthPercent := 100.000000000000000000;
    LocLogo.SetParentComponent(PageLocaties);
    LocLogo.Name := 'LocLogo';
    LocLogo.Left := 938;
    LocLogo.Top := 40;
    LocLogo.Width := 237;
    LocLogo.Height := 107;
    LocLogo.HeightPercent := 100.000000000000000000;
    LocLogo.WidthPercent := 100.000000000000000000;
    LocLogo.ChildOrder := 8;
    LocLogo.Picture.LoadFromFile('Unit1.PageLocaties.LocLogo.Picture.jpg');
    lblInloggerLoc.SetParentComponent(PageLocaties);
    lblInloggerLoc.Name := 'lblInloggerLoc';
    lblInloggerLoc.Left := 536;
    lblInloggerLoc.Top := 11;
    lblInloggerLoc.Width := 63;
    lblInloggerLoc.Height := 16;
    lblInloggerLoc.Caption := 'Inlogger: ';
    lblInloggerLoc.Font.Charset := DEFAULT_CHARSET;
    lblInloggerLoc.Font.Color := 21484;
    lblInloggerLoc.Font.Height := -13;
    lblInloggerLoc.Font.Name := 'Tahoma';
    lblInloggerLoc.Font.Style := [fsBold];
    lblInloggerLoc.HeightPercent := 100.000000000000000000;
    lblInloggerLoc.ParentFont := False;
    lblInloggerLoc.WidthPercent := 100.000000000000000000;
    NaamInloggerLoc.SetParentComponent(PageLocaties);
    NaamInloggerLoc.Name := 'NaamInloggerLoc';
    NaamInloggerLoc.Left := 605;
    NaamInloggerLoc.Top := 11;
    NaamInloggerLoc.Width := 9;
    NaamInloggerLoc.Height := 16;
    NaamInloggerLoc.Caption := 'X';
    NaamInloggerLoc.Font.Charset := DEFAULT_CHARSET;
    NaamInloggerLoc.Font.Color := 21484;
    NaamInloggerLoc.Font.Height := -13;
    NaamInloggerLoc.Font.Name := 'Tahoma';
    NaamInloggerLoc.Font.Style := [fsBold];
    NaamInloggerLoc.HeightPercent := 100.000000000000000000;
    NaamInloggerLoc.ParentFont := False;
    NaamInloggerLoc.WidthPercent := 100.000000000000000000;
    LocListBox1.SetParentComponent(PageLocaties);
    LocListBox1.Name := 'LocListBox1';
    LocListBox1.Left := 938;
    LocListBox1.Top := 40;
    LocListBox1.Width := 225;
    LocListBox1.Height := 40;
    LocListBox1.HeightPercent := 100.000000000000000000;
    LocListBox1.Visible := False;
    LocListBox1.WidthPercent := 100.000000000000000000;
    LocListBox1.ItemIndex := -1;
    LocListBox2.SetParentComponent(PageLocaties);
    LocListBox2.Name := 'LocListBox2';
    LocListBox2.Left := 12;
    LocListBox2.Top := 40;
    LocListBox2.Width := 910;
    LocListBox2.Height := 150;
    LocListBox2.HeightPercent := 100.000000000000000000;
    LocListBox2.TabOrder := 1;
    LocListBox2.WidthPercent := 100.000000000000000000;
    SetEvent(LocListBox2, Self, 'OnChange', 'LocListBox2Change');
    SetEvent(LocListBox2, Self, 'OnClick', 'LocListBox2Click');
    LocListBox2.ItemIndex := -1;
    WebPanel2.SetParentComponent(PageLocaties);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 12;
    WebPanel2.Top := 200;
    WebPanel2.Width := 1169;
    WebPanel2.Height := 550;
    WebPanel2.ChildOrder := 7;
    WebPanel2.Color := clWhite;
    lblLocLocatie.SetParentComponent(WebPanel2);
    lblLocLocatie.Name := 'lblLocLocatie';
    lblLocLocatie.Left := 15;
    lblLocLocatie.Top := 18;
    lblLocLocatie.Width := 40;
    lblLocLocatie.Height := 16;
    lblLocLocatie.Caption := 'Locatie';
    lblLocLocatie.HeightPercent := 100.000000000000000000;
    lblLocLocatie.WidthPercent := 100.000000000000000000;
    lblLocAdres.SetParentComponent(WebPanel2);
    lblLocAdres.Name := 'lblLocAdres';
    lblLocAdres.Left := 15;
    lblLocAdres.Top := 57;
    lblLocAdres.Width := 33;
    lblLocAdres.Height := 16;
    lblLocAdres.Caption := 'Adres';
    lblLocAdres.HeightPercent := 100.000000000000000000;
    lblLocAdres.WidthPercent := 100.000000000000000000;
    lblLocContactpersoon.SetParentComponent(WebPanel2);
    lblLocContactpersoon.Name := 'lblLocContactpersoon';
    lblLocContactpersoon.Left := 15;
    lblLocContactpersoon.Top := 96;
    lblLocContactpersoon.Width := 89;
    lblLocContactpersoon.Height := 16;
    lblLocContactpersoon.Caption := 'Contactpersoon';
    lblLocContactpersoon.HeightPercent := 100.000000000000000000;
    lblLocContactpersoon.WidthPercent := 100.000000000000000000;
    lblLocEmailadres.SetParentComponent(WebPanel2);
    lblLocEmailadres.Name := 'lblLocEmailadres';
    lblLocEmailadres.Left := 15;
    lblLocEmailadres.Top := 135;
    lblLocEmailadres.Width := 63;
    lblLocEmailadres.Height := 16;
    lblLocEmailadres.Caption := 'Emailadres';
    lblLocEmailadres.HeightPercent := 100.000000000000000000;
    lblLocEmailadres.WidthPercent := 100.000000000000000000;
    VeldLocLocatie.SetParentComponent(WebPanel2);
    VeldLocLocatie.Name := 'VeldLocLocatie';
    VeldLocLocatie.Left := 115;
    VeldLocLocatie.Top := 15;
    VeldLocLocatie.Width := 280;
    VeldLocLocatie.Height := 22;
    VeldLocLocatie.ChildOrder := 3;
    VeldLocLocatie.HeightPercent := 100.000000000000000000;
    VeldLocLocatie.WidthPercent := 100.000000000000000000;
    VeldLocAdres.SetParentComponent(WebPanel2);
    VeldLocAdres.Name := 'VeldLocAdres';
    VeldLocAdres.Left := 115;
    VeldLocAdres.Top := 54;
    VeldLocAdres.Width := 280;
    VeldLocAdres.Height := 22;
    VeldLocAdres.ChildOrder := 1;
    VeldLocAdres.HeightPercent := 100.000000000000000000;
    VeldLocAdres.TabOrder := 1;
    VeldLocAdres.WidthPercent := 100.000000000000000000;
    VeldLocContactpersoon.SetParentComponent(WebPanel2);
    VeldLocContactpersoon.Name := 'VeldLocContactpersoon';
    VeldLocContactpersoon.Left := 115;
    VeldLocContactpersoon.Top := 93;
    VeldLocContactpersoon.Width := 280;
    VeldLocContactpersoon.Height := 22;
    VeldLocContactpersoon.ChildOrder := 1;
    VeldLocContactpersoon.HeightPercent := 100.000000000000000000;
    VeldLocContactpersoon.TabOrder := 2;
    VeldLocContactpersoon.WidthPercent := 100.000000000000000000;
    LocbtnAdd.SetParentComponent(WebPanel2);
    LocbtnAdd.Name := 'LocbtnAdd';
    LocbtnAdd.Left := 21;
    LocbtnAdd.Top := 400;
    LocbtnAdd.Width := 80;
    LocbtnAdd.Height := 25;
    LocbtnAdd.Caption := 'Toevoegen';
    LocbtnAdd.Color := clNone;
    LocbtnAdd.HeightPercent := 100.000000000000000000;
    LocbtnAdd.TabOrder := 6;
    LocbtnAdd.WidthPercent := 100.000000000000000000;
    SetEvent(LocbtnAdd, Self, 'OnClick', 'LocbtnAddClick');
    LocbtnUpdate.SetParentComponent(WebPanel2);
    LocbtnUpdate.Name := 'LocbtnUpdate';
    LocbtnUpdate.Left := 129;
    LocbtnUpdate.Top := 400;
    LocbtnUpdate.Width := 80;
    LocbtnUpdate.Height := 25;
    LocbtnUpdate.Caption := 'Updaten';
    LocbtnUpdate.Color := clNone;
    LocbtnUpdate.HeightPercent := 100.000000000000000000;
    LocbtnUpdate.TabOrder := 7;
    LocbtnUpdate.WidthPercent := 100.000000000000000000;
    SetEvent(LocbtnUpdate, Self, 'OnClick', 'LocbtnUpdateClick');
    LocbtnDelete.SetParentComponent(WebPanel2);
    LocbtnDelete.Name := 'LocbtnDelete';
    LocbtnDelete.Left := 245;
    LocbtnDelete.Top := 400;
    LocbtnDelete.Width := 80;
    LocbtnDelete.Height := 25;
    LocbtnDelete.Caption := 'Wissen';
    LocbtnDelete.Color := clNone;
    LocbtnDelete.HeightPercent := 100.000000000000000000;
    LocbtnDelete.TabOrder := 8;
    LocbtnDelete.WidthPercent := 100.000000000000000000;
    SetEvent(LocbtnDelete, Self, 'OnClick', 'LocbtnDeleteClick');
    LocDbGrid.SetParentComponent(WebPanel2);
    LocDbGrid.Name := 'LocDbGrid';
    LocDbGrid.Left := 456;
    LocDbGrid.Top := 50;
    LocDbGrid.Width := 697;
    LocDbGrid.Height := 281;
    LocDbGrid.Columns.Clear;
    with LocDbGrid.Columns.Add do
    begin
      Width := 25;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 30;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 150;
    end;
    with LocDbGrid.Columns.Add do
    begin
      Width := 150;
    end;
    LocDbGrid.DataSource := WebDataSource;
    LocDbGrid.FixedCols := 0;
    LocDbGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedColClick];
    LocDbGrid.TabOrder := 9;
    LocDbGrid.HeightPercent := 100.000000000000000000;
    LocDbGrid.WidthPercent := 100.000000000000000000;
    SetEvent(LocDbGrid, Self, 'OnClickCell', 'GebDBGridClickCell');
    SetEvent(LocDbGrid, Self, 'OnFixedCellClick', 'GebDBGridFixedCellClick');
    LocDbGrid.ColWidths[0] := 25;
    LocDbGrid.ColWidths[1] := 30;
    LocDbGrid.ColWidths[2] := 150;
    LocDbGrid.ColWidths[3] := 150;
    LocDbGrid.ColWidths[4] := 150;
    LocDbGrid.ColWidths[5] := 150;
    LocDbGrid.ColWidths[6] := 150;
    LocDBNavigator.SetParentComponent(WebPanel2);
    LocDBNavigator.Name := 'LocDBNavigator';
    LocDBNavigator.Left := 456;
    LocDBNavigator.Top := 15;
    LocDBNavigator.Width := 697;
    LocDBNavigator.Height := 25;
    LocDBNavigator.DataSource := WebDataSource;
    LocDBNavigator.Hints.BeginUpdate;
    try
      LocDBNavigator.Hints.Clear;
      LocDBNavigator.Hints.Add('First');
      LocDBNavigator.Hints.Add('Prior');
      LocDBNavigator.Hints.Add('Next');
      LocDBNavigator.Hints.Add('Last');
      LocDBNavigator.Hints.Add('Edit');
      LocDBNavigator.Hints.Add('Post');
      LocDBNavigator.Hints.Add('Insert');
      LocDBNavigator.Hints.Add('Delete');
      LocDBNavigator.Hints.Add('Cancel');
    finally
      LocDBNavigator.Hints.EndUpdate;
    end;
    LocRefresh.SetParentComponent(WebPanel2);
    LocRefresh.Name := 'LocRefresh';
    LocRefresh.Left := 352;
    LocRefresh.Top := 400;
    LocRefresh.Width := 96;
    LocRefresh.Height := 25;
    LocRefresh.Caption := 'Bijwerken';
    LocRefresh.ChildOrder := 11;
    LocRefresh.HeightPercent := 100.000000000000000000;
    LocRefresh.TabOrder := 8;
    LocRefresh.Visible := False;
    LocRefresh.WidthPercent := 100.000000000000000000;
    SetEvent(LocRefresh, Self, 'OnClick', 'LocRefreshClick');
    VeldLocEmailadres.SetParentComponent(WebPanel2);
    VeldLocEmailadres.Name := 'VeldLocEmailadres';
    VeldLocEmailadres.Left := 115;
    VeldLocEmailadres.Top := 132;
    VeldLocEmailadres.Width := 280;
    VeldLocEmailadres.Height := 22;
    VeldLocEmailadres.ChildOrder := 1;
    VeldLocEmailadres.HeightPercent := 100.000000000000000000;
    VeldLocEmailadres.TabOrder := 3;
    VeldLocEmailadres.WidthPercent := 100.000000000000000000;
    VeldLocActief.SetParentComponent(WebPanel2);
    VeldLocActief.Name := 'VeldLocActief';
    VeldLocActief.Left := 111;
    VeldLocActief.Top := 171;
    VeldLocActief.Width := 62;
    VeldLocActief.Height := 22;
    VeldLocActief.Caption := 'Actief';
    VeldLocActief.ChildOrder := 16;
    VeldLocActief.HeightPercent := 100.000000000000000000;
    VeldLocActief.TabOrder := 4;
    VeldLocActief.WidthPercent := 100.000000000000000000;
    SoortBieb.SetParentComponent(WebPanel2);
    SoortBieb.Name := 'SoortBieb';
    SoortBieb.Left := 115;
    SoortBieb.Top := 205;
    SoortBieb.Width := 280;
    SoortBieb.Height := 75;
    SoortBieb.Caption := ' Soort Bibliotheek ';
    SoortBieb.ChildOrder := 15;
    SoortBieb.Columns := 2;
    SoortBieb.ItemIndex := -1;
    SoortBieb.Items.BeginUpdate;
    try
      SoortBieb.Items.Clear;
      SoortBieb.Items.Add('Bibliotheek');
      SoortBieb.Items.Add('Leespunt');
      SoortBieb.Items.Add('Afhaalpunt');
      SoortBieb.Items.Add('Bieb in buurt');
    finally
      SoortBieb.Items.EndUpdate;
    end;
    SoortBieb.Role := '';
    LocDoeEntities.SetParentComponent(PageLocaties);
    LocDoeEntities.Name := 'LocDoeEntities';
    LocDoeEntities.Left := 938;
    LocDoeEntities.Top := 96;
    LocDoeEntities.Width := 96;
    LocDoeEntities.Height := 25;
    LocDoeEntities.Caption := 'Toon Records';
    LocDoeEntities.ChildOrder := 2;
    LocDoeEntities.HeightPercent := 100.000000000000000000;
    LocDoeEntities.TabOrder := 3;
    LocDoeEntities.Visible := False;
    LocDoeEntities.WidthPercent := 100.000000000000000000;
    SetEvent(LocDoeEntities, Self, 'OnClick', 'LocDoeEntitiesClick');
    LocActief.SetParentComponent(PageLocaties);
    LocActief.Name := 'LocActief';
    LocActief.Left := 12;
    LocActief.Top := 12;
    LocActief.Width := 113;
    LocActief.Height := 22;
    LocActief.Caption := 'Alleen Actieven';
    LocActief.ChildOrder := 10;
    LocActief.HeightPercent := 100.000000000000000000;
    LocActief.TabOrder := 5;
    LocActief.WidthPercent := 100.000000000000000000;
    SetEvent(LocActief, Self, 'OnClick', 'LocActiefClick');
    PageOpdrachten.SetParentComponent(OnderdelenControl);
    PageOpdrachten.Name := 'PageOpdrachten';
    PageOpdrachten.Left := 0;
    PageOpdrachten.Top := 20;
    PageOpdrachten.Width := 1200;
    PageOpdrachten.Height := 1290;
    PageOpdrachten.Caption := 'Opdrachten';
    PageOpdrachten.ChildOrder := 2;
    lblFilteren.SetParentComponent(PageOpdrachten);
    lblFilteren.Name := 'lblFilteren';
    lblFilteren.Left := 192;
    lblFilteren.Top := 15;
    lblFilteren.Width := 61;
    lblFilteren.Height := 16;
    lblFilteren.Caption := 'Filteren op';
    lblFilteren.HeightPercent := 100.000000000000000000;
    lblFilteren.WidthPercent := 100.000000000000000000;
    OpdNaam.SetParentComponent(PageOpdrachten);
    OpdNaam.Name := 'OpdNaam';
    OpdNaam.Left := 990;
    OpdNaam.Top := 160;
    OpdNaam.Width := 139;
    OpdNaam.Height := 23;
    OpdNaam.Caption := 'Klussenbeheer';
    OpdNaam.Font.Charset := DEFAULT_CHARSET;
    OpdNaam.Font.Color := clGray;
    OpdNaam.Font.Height := -19;
    OpdNaam.Font.Name := 'Tahoma';
    OpdNaam.Font.Style := [fsBold];
    OpdNaam.HeightPercent := 100.000000000000000000;
    OpdNaam.ParentFont := False;
    OpdNaam.WidthPercent := 100.000000000000000000;
    OpdCR.SetParentComponent(PageOpdrachten);
    OpdCR.Name := 'OpdCR';
    OpdCR.Left := 945;
    OpdCR.Top := 11;
    OpdCR.Width := 8;
    OpdCR.Height := 16;
    OpdCR.Caption := 'C';
    OpdCR.Font.Charset := DEFAULT_CHARSET;
    OpdCR.Font.Color := 2454527;
    OpdCR.Font.Height := -13;
    OpdCR.Font.Name := 'Tahoma';
    OpdCR.Font.Style := [fsBold];
    OpdCR.HeightPercent := 100.000000000000000000;
    OpdCR.ParentFont := False;
    OpdCR.WidthPercent := 100.000000000000000000;
    OpdLogo.SetParentComponent(PageOpdrachten);
    OpdLogo.Name := 'OpdLogo';
    OpdLogo.Left := 938;
    OpdLogo.Top := 40;
    OpdLogo.Width := 237;
    OpdLogo.Height := 107;
    OpdLogo.HeightPercent := 100.000000000000000000;
    OpdLogo.WidthPercent := 100.000000000000000000;
    OpdLogo.ChildOrder := 8;
    OpdLogo.Picture.LoadFromFile('Unit1.PageOpdrachten.OpdLogo.Picture.jpg');
    lblInloggerOpd.SetParentComponent(PageOpdrachten);
    lblInloggerOpd.Name := 'lblInloggerOpd';
    lblInloggerOpd.Left := 536;
    lblInloggerOpd.Top := 11;
    lblInloggerOpd.Width := 63;
    lblInloggerOpd.Height := 16;
    lblInloggerOpd.Caption := 'Inlogger: ';
    lblInloggerOpd.Font.Charset := DEFAULT_CHARSET;
    lblInloggerOpd.Font.Color := 21484;
    lblInloggerOpd.Font.Height := -13;
    lblInloggerOpd.Font.Name := 'Tahoma';
    lblInloggerOpd.Font.Style := [fsBold];
    lblInloggerOpd.HeightPercent := 100.000000000000000000;
    lblInloggerOpd.ParentFont := False;
    lblInloggerOpd.WidthPercent := 100.000000000000000000;
    NaamInloggerOpd.SetParentComponent(PageOpdrachten);
    NaamInloggerOpd.Name := 'NaamInloggerOpd';
    NaamInloggerOpd.Left := 605;
    NaamInloggerOpd.Top := 11;
    NaamInloggerOpd.Width := 9;
    NaamInloggerOpd.Height := 16;
    NaamInloggerOpd.Caption := 'X';
    NaamInloggerOpd.Font.Charset := DEFAULT_CHARSET;
    NaamInloggerOpd.Font.Color := 21484;
    NaamInloggerOpd.Font.Height := -13;
    NaamInloggerOpd.Font.Name := 'Tahoma';
    NaamInloggerOpd.Font.Style := [fsBold];
    NaamInloggerOpd.HeightPercent := 100.000000000000000000;
    NaamInloggerOpd.ParentFont := False;
    NaamInloggerOpd.WidthPercent := 100.000000000000000000;
    OpdActief.SetParentComponent(PageOpdrachten);
    OpdActief.Name := 'OpdActief';
    OpdActief.Left := 12;
    OpdActief.Top := 12;
    OpdActief.Width := 113;
    OpdActief.Height := 22;
    OpdActief.Caption := 'Alleen Actieven';
    OpdActief.ChildOrder := 10;
    OpdActief.HeightPercent := 100.000000000000000000;
    OpdActief.WidthPercent := 100.000000000000000000;
    SetEvent(OpdActief, Self, 'OnClick', 'OpdActiefClick');
    OpdListBox2.SetParentComponent(PageOpdrachten);
    OpdListBox2.Name := 'OpdListBox2';
    OpdListBox2.Left := 12;
    OpdListBox2.Top := 40;
    OpdListBox2.Width := 910;
    OpdListBox2.Height := 150;
    OpdListBox2.HeightPercent := 100.000000000000000000;
    OpdListBox2.TabOrder := 1;
    OpdListBox2.WidthPercent := 100.000000000000000000;
    SetEvent(OpdListBox2, Self, 'OnChange', 'OpdListBox2Change');
    SetEvent(OpdListBox2, Self, 'OnClick', 'OpdListBox2Click');
    OpdListBox2.ItemIndex := -1;
    OpdListBox1.SetParentComponent(PageOpdrachten);
    OpdListBox1.Name := 'OpdListBox1';
    OpdListBox1.Left := 938;
    OpdListBox1.Top := 40;
    OpdListBox1.Width := 225;
    OpdListBox1.Height := 40;
    OpdListBox1.HeightPercent := 100.000000000000000000;
    OpdListBox1.TabOrder := 2;
    OpdListBox1.Visible := False;
    OpdListBox1.WidthPercent := 100.000000000000000000;
    OpdListBox1.ItemIndex := -1;
    OpdDoeEntities.SetParentComponent(PageOpdrachten);
    OpdDoeEntities.Name := 'OpdDoeEntities';
    OpdDoeEntities.Left := 938;
    OpdDoeEntities.Top := 96;
    OpdDoeEntities.Width := 96;
    OpdDoeEntities.Height := 25;
    OpdDoeEntities.Caption := 'Toon Records';
    OpdDoeEntities.ChildOrder := 2;
    OpdDoeEntities.HeightPercent := 100.000000000000000000;
    OpdDoeEntities.TabOrder := 4;
    OpdDoeEntities.Visible := False;
    OpdDoeEntities.WidthPercent := 100.000000000000000000;
    SetEvent(OpdDoeEntities, Self, 'OnClick', 'OpdDoeEntitiesClick');
    WebPanel3.SetParentComponent(PageOpdrachten);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 12;
    WebPanel3.Top := 200;
    WebPanel3.Width := 1169;
    WebPanel3.Height := 1100;
    WebPanel3.ChildOrder := 6;
    WebPanel3.Color := clWhite;
    lblOpdLocatie.SetParentComponent(WebPanel3);
    lblOpdLocatie.Name := 'lblOpdLocatie';
    lblOpdLocatie.Left := 15;
    lblOpdLocatie.Top := 18;
    lblOpdLocatie.Width := 40;
    lblOpdLocatie.Height := 16;
    lblOpdLocatie.Caption := 'Locatie';
    lblOpdLocatie.HeightPercent := 100.000000000000000000;
    lblOpdLocatie.WidthPercent := 100.000000000000000000;
    lblOpdContactpersoon.SetParentComponent(WebPanel3);
    lblOpdContactpersoon.Name := 'lblOpdContactpersoon';
    lblOpdContactpersoon.Left := 15;
    lblOpdContactpersoon.Top := 57;
    lblOpdContactpersoon.Width := 89;
    lblOpdContactpersoon.Height := 16;
    lblOpdContactpersoon.Caption := 'Contactpersoon';
    lblOpdContactpersoon.HeightPercent := 100.000000000000000000;
    lblOpdContactpersoon.WidthPercent := 100.000000000000000000;
    lblOpdracht.SetParentComponent(WebPanel3);
    lblOpdracht.Name := 'lblOpdracht';
    lblOpdracht.Left := 416;
    lblOpdracht.Top := 14;
    lblOpdracht.Width := 145;
    lblOpdracht.Height := 16;
    lblOpdracht.Caption := 'Opdracht omschrijving';
    lblOpdracht.Font.Charset := DEFAULT_CHARSET;
    lblOpdracht.Font.Color := clWindowText;
    lblOpdracht.Font.Height := -13;
    lblOpdracht.Font.Name := 'Tahoma';
    lblOpdracht.Font.Style := [fsBold];
    lblOpdracht.HeightPercent := 100.000000000000000000;
    lblOpdracht.ParentFont := False;
    lblOpdracht.WidthPercent := 100.000000000000000000;
    lblOpdDatum.SetParentComponent(WebPanel3);
    lblOpdDatum.Name := 'lblOpdDatum';
    lblOpdDatum.Left := 16;
    lblOpdDatum.Top := 134;
    lblOpdDatum.Width := 91;
    lblOpdDatum.Height := 16;
    lblOpdDatum.Caption := 'Datum opdracht';
    lblOpdDatum.HeightPercent := 100.000000000000000000;
    lblOpdDatum.WidthPercent := 100.000000000000000000;
    lblReactie.SetParentComponent(WebPanel3);
    lblReactie.Name := 'lblReactie';
    lblReactie.Left := 416;
    lblReactie.Top := 326;
    lblReactie.Width := 122;
    lblReactie.Height := 16;
    lblReactie.Caption := 'Reactie uitvoerder';
    lblReactie.Font.Charset := DEFAULT_CHARSET;
    lblReactie.Font.Color := clWindowText;
    lblReactie.Font.Height := -13;
    lblReactie.Font.Name := 'Tahoma';
    lblReactie.Font.Style := [fsBold];
    lblReactie.HeightPercent := 100.000000000000000000;
    lblReactie.ParentFont := False;
    lblReactie.WidthPercent := 100.000000000000000000;
    lblReactieDatum.SetParentComponent(WebPanel3);
    lblReactieDatum.Name := 'lblReactieDatum';
    lblReactieDatum.Left := 16;
    lblReactieDatum.Top := 351;
    lblReactieDatum.Width := 80;
    lblReactieDatum.Height := 16;
    lblReactieDatum.Caption := 'Datum reactie';
    lblReactieDatum.HeightPercent := 100.000000000000000000;
    lblReactieDatum.WidthPercent := 100.000000000000000000;
    lblVerslag.SetParentComponent(WebPanel3);
    lblVerslag.Name := 'lblVerslag';
    lblVerslag.Left := 417;
    lblVerslag.Top := 485;
    lblVerslag.Width := 122;
    lblVerslag.Height := 16;
    lblVerslag.Caption := 'Verslag uitvoerder';
    lblVerslag.Font.Charset := DEFAULT_CHARSET;
    lblVerslag.Font.Color := clWindowText;
    lblVerslag.Font.Height := -13;
    lblVerslag.Font.Name := 'Tahoma';
    lblVerslag.Font.Style := [fsBold];
    lblVerslag.HeightPercent := 100.000000000000000000;
    lblVerslag.ParentFont := False;
    lblVerslag.WidthPercent := 100.000000000000000000;
    lblGeplandDatum.SetParentComponent(WebPanel3);
    lblGeplandDatum.Name := 'lblGeplandDatum';
    lblGeplandDatum.Left := 16;
    lblGeplandDatum.Top := 388;
    lblGeplandDatum.Width := 86;
    lblGeplandDatum.Height := 16;
    lblGeplandDatum.Caption := 'Datum gepland';
    lblGeplandDatum.HeightPercent := 100.000000000000000000;
    lblGeplandDatum.WidthPercent := 100.000000000000000000;
    lblVerslagDatum.SetParentComponent(WebPanel3);
    lblVerslagDatum.Name := 'lblVerslagDatum';
    lblVerslagDatum.Left := 16;
    lblVerslagDatum.Top := 510;
    lblVerslagDatum.Width := 82;
    lblVerslagDatum.Height := 16;
    lblVerslagDatum.Caption := 'Datum verslag';
    lblVerslagDatum.HeightPercent := 100.000000000000000000;
    lblVerslagDatum.WidthPercent := 100.000000000000000000;
    lblAlgemeneOpmerkingen.SetParentComponent(WebPanel3);
    lblAlgemeneOpmerkingen.Name := 'lblAlgemeneOpmerkingen';
    lblAlgemeneOpmerkingen.Left := 417;
    lblAlgemeneOpmerkingen.Top := 792;
    lblAlgemeneOpmerkingen.Width := 151;
    lblAlgemeneOpmerkingen.Height := 16;
    lblAlgemeneOpmerkingen.Caption := 'Algemene opmerkingen';
    lblAlgemeneOpmerkingen.Font.Charset := DEFAULT_CHARSET;
    lblAlgemeneOpmerkingen.Font.Color := clWindowText;
    lblAlgemeneOpmerkingen.Font.Height := -13;
    lblAlgemeneOpmerkingen.Font.Name := 'Tahoma';
    lblAlgemeneOpmerkingen.Font.Style := [fsBold];
    lblAlgemeneOpmerkingen.HeightPercent := 100.000000000000000000;
    lblAlgemeneOpmerkingen.ParentFont := False;
    lblAlgemeneOpmerkingen.WidthPercent := 100.000000000000000000;
    lblOpdrachtgever.SetParentComponent(WebPanel3);
    lblOpdrachtgever.Name := 'lblOpdrachtgever';
    lblOpdrachtgever.Left := 15;
    lblOpdrachtgever.Top := 96;
    lblOpdrachtgever.Width := 84;
    lblOpdrachtgever.Height := 16;
    lblOpdrachtgever.Caption := 'Opdrachtgever';
    lblOpdrachtgever.HeightPercent := 100.000000000000000000;
    lblOpdrachtgever.WidthPercent := 100.000000000000000000;
    ReserveTekst.SetParentComponent(WebPanel3);
    ReserveTekst.Name := 'ReserveTekst';
    ReserveTekst.Left := 416;
    ReserveTekst.Top := 35;
    ReserveTekst.Width := 730;
    ReserveTekst.Height := 270;
    ReserveTekst.AutoSize := False;
    ReserveTekst.HeightPercent := 100.000000000000000000;
    ReserveTekst.SelLength := 0;
    ReserveTekst.SelStart := 0;
    ReserveTekst.TabOrder := 8;
    ReserveTekst.Visible := False;
    ReserveTekst.WidthPercent := 100.000000000000000000;
    OpdbtnAdd.SetParentComponent(WebPanel3);
    OpdbtnAdd.Name := 'OpdbtnAdd';
    OpdbtnAdd.Left := 115;
    OpdbtnAdd.Top := 280;
    OpdbtnAdd.Width := 90;
    OpdbtnAdd.Height := 25;
    OpdbtnAdd.Hint := 'Van de geselecteerde een kopie maken.';
    OpdbtnAdd.Caption := 'Kopie maken';
    OpdbtnAdd.Color := clNone;
    OpdbtnAdd.HeightPercent := 100.000000000000000000;
    OpdbtnAdd.ShowHint := True;
    OpdbtnAdd.TabOrder := 6;
    OpdbtnAdd.WidthPercent := 100.000000000000000000;
    SetEvent(OpdbtnAdd, Self, 'OnClick', 'OpdbtnAddClick');
    OpdbtnUpdate.SetParentComponent(WebPanel3);
    OpdbtnUpdate.Name := 'OpdbtnUpdate';
    OpdbtnUpdate.Left := 211;
    OpdbtnUpdate.Top := 280;
    OpdbtnUpdate.Width := 90;
    OpdbtnUpdate.Height := 25;
    OpdbtnUpdate.Hint := 'Een aanpassing opslaan.';
    OpdbtnUpdate.Caption := 'Opslaan';
    OpdbtnUpdate.Color := clNone;
    OpdbtnUpdate.HeightPercent := 100.000000000000000000;
    OpdbtnUpdate.ShowHint := True;
    OpdbtnUpdate.TabOrder := 7;
    OpdbtnUpdate.WidthPercent := 100.000000000000000000;
    SetEvent(OpdbtnUpdate, Self, 'OnClick', 'OpdbtnUpdateClick');
    OpdbtnDelete.SetParentComponent(WebPanel3);
    OpdbtnDelete.Name := 'OpdbtnDelete';
    OpdbtnDelete.Left := 316;
    OpdbtnDelete.Top := 280;
    OpdbtnDelete.Width := 90;
    OpdbtnDelete.Height := 25;
    OpdbtnDelete.Hint := 'Een opdracht definitief wissen.';
    OpdbtnDelete.Caption := 'Wissen';
    OpdbtnDelete.Color := clNone;
    OpdbtnDelete.HeightPercent := 100.000000000000000000;
    OpdbtnDelete.ShowHint := True;
    OpdbtnDelete.TabOrder := 8;
    OpdbtnDelete.WidthPercent := 100.000000000000000000;
    SetEvent(OpdbtnDelete, Self, 'OnClick', 'OpdbtnDeleteClick');
    VeldOpdLocatie.SetParentComponent(WebPanel3);
    VeldOpdLocatie.Name := 'VeldOpdLocatie';
    VeldOpdLocatie.Left := 115;
    VeldOpdLocatie.Top := 15;
    VeldOpdLocatie.Width := 291;
    VeldOpdLocatie.Height := 22;
    VeldOpdLocatie.ChildOrder := 3;
    VeldOpdLocatie.HeightPercent := 100.000000000000000000;
    VeldOpdLocatie.TabOrder := 3;
    VeldOpdLocatie.WidthPercent := 100.000000000000000000;
    VeldOpdContactpersoon.SetParentComponent(WebPanel3);
    VeldOpdContactpersoon.Name := 'VeldOpdContactpersoon';
    VeldOpdContactpersoon.Left := 115;
    VeldOpdContactpersoon.Top := 54;
    VeldOpdContactpersoon.Width := 291;
    VeldOpdContactpersoon.Height := 22;
    VeldOpdContactpersoon.ChildOrder := 1;
    VeldOpdContactpersoon.HeightPercent := 100.000000000000000000;
    VeldOpdContactpersoon.TabOrder := 4;
    VeldOpdContactpersoon.WidthPercent := 100.000000000000000000;
    OpdOpdrachtDate.SetParentComponent(WebPanel3);
    OpdOpdrachtDate.Name := 'OpdOpdrachtDate';
    OpdOpdrachtDate.Left := 116;
    OpdOpdrachtDate.Top := 131;
    OpdOpdrachtDate.Width := 109;
    OpdOpdrachtDate.Height := 22;
    OpdOpdrachtDate.BorderStyle := bsSingle;
    OpdOpdrachtDate.ChildOrder := 10;
    OpdOpdrachtDate.Color := clWhite;
    OpdOpdrachtDate.Date := 44778.000000000000000000;
    OpdOpdrachtDate.Role := '';
    OpdOpdrachtDate.ShowSeconds := False;
    OpdOpdrachtDate.TabOrder := 5;
    OpdOpdrachtDate.Text := '';
    OpdStatus.SetParentComponent(WebPanel3);
    OpdStatus.Name := 'OpdStatus';
    OpdStatus.Left := 16;
    OpdStatus.Top := 176;
    OpdStatus.Width := 390;
    OpdStatus.Height := 52;
    OpdStatus.Caption := ' Opdracht Status ';
    OpdStatus.ChildOrder := 13;
    OpdStatus.Columns := 4;
    OpdStatus.ItemIndex := -1;
    OpdStatus.Items.BeginUpdate;
    try
      OpdStatus.Items.Clear;
      OpdStatus.Items.Add('Besteld');
      OpdStatus.Items.Add('Gepland');
      OpdStatus.Items.Add('Afgerond');
      OpdStatus.Items.Add('Opgeleverd');
    finally
      OpdStatus.Items.EndUpdate;
    end;
    OpdStatus.Role := '';
    SetEvent(OpdStatus, Self, 'OnChange', 'OpdStatusChange');
    VeldOpdActief.SetParentComponent(WebPanel3);
    VeldOpdActief.Name := 'VeldOpdActief';
    VeldOpdActief.Left := 236;
    VeldOpdActief.Top := 132;
    VeldOpdActief.Width := 62;
    VeldOpdActief.Height := 22;
    VeldOpdActief.Caption := 'Actief';
    VeldOpdActief.ChildOrder := 16;
    VeldOpdActief.HeightPercent := 100.000000000000000000;
    VeldOpdActief.TabOrder := 7;
    VeldOpdActief.WidthPercent := 100.000000000000000000;
    OpdOpdracht.SetParentComponent(WebPanel3);
    OpdOpdracht.Name := 'OpdOpdracht';
    OpdOpdracht.Left := 416;
    OpdOpdracht.Top := 35;
    OpdOpdracht.Width := 730;
    OpdOpdracht.Height := 270;
    OpdOpdracht.AutoSize := False;
    OpdOpdracht.HeightPercent := 100.000000000000000000;
    OpdOpdracht.SelLength := 0;
    OpdOpdracht.SelStart := 0;
    OpdOpdracht.TabOrder := 8;
    OpdOpdracht.WidthPercent := 100.000000000000000000;
    OpdbtnNieuw.SetParentComponent(WebPanel3);
    OpdbtnNieuw.Name := 'OpdbtnNieuw';
    OpdbtnNieuw.Left := 16;
    OpdbtnNieuw.Top := 280;
    OpdbtnNieuw.Width := 90;
    OpdbtnNieuw.Height := 25;
    OpdbtnNieuw.Hint := 'En nieuwe lege opdracht aanmaken.';
    OpdbtnNieuw.Caption := 'Nieuwe (leeg)';
    OpdbtnNieuw.Color := clNone;
    OpdbtnNieuw.HeightPercent := 100.000000000000000000;
    OpdbtnNieuw.ShowHint := True;
    OpdbtnNieuw.TabOrder := 6;
    OpdbtnNieuw.WidthPercent := 100.000000000000000000;
    SetEvent(OpdbtnNieuw, Self, 'OnClick', 'OpdbtnNieuwClick');
    WebMessageDlg1.SetParentComponent(WebPanel3);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 744;
    WebMessageDlg1.Top := 290;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.DialogType := mtConfirmation;
    WebMessageDlg1.Opacity := 0.200000000000000000;
    OpdMetaDataCheck.SetParentComponent(WebPanel3);
    OpdMetaDataCheck.Name := 'OpdMetaDataCheck';
    OpdMetaDataCheck.Left := 295;
    OpdMetaDataCheck.Top := 132;
    OpdMetaDataCheck.Width := 111;
    OpdMetaDataCheck.Height := 22;
    OpdMetaDataCheck.Caption := 'Toon MetaData';
    OpdMetaDataCheck.ChildOrder := 16;
    OpdMetaDataCheck.HeightPercent := 100.000000000000000000;
    OpdMetaDataCheck.TabOrder := 11;
    OpdMetaDataCheck.Visible := False;
    OpdMetaDataCheck.WidthPercent := 100.000000000000000000;
    SetEvent(OpdMetaDataCheck, Self, 'OnClick', 'OpdMetaDataCheckClick');
    OpdReactie.SetParentComponent(WebPanel3);
    OpdReactie.Name := 'OpdReactie';
    OpdReactie.Left := 416;
    OpdReactie.Top := 348;
    OpdReactie.Width := 730;
    OpdReactie.Height := 120;
    OpdReactie.AutoSize := False;
    OpdReactie.HeightPercent := 100.000000000000000000;
    OpdReactie.SelLength := 0;
    OpdReactie.SelStart := 0;
    OpdReactie.TabOrder := 12;
    OpdReactie.WidthPercent := 100.000000000000000000;
    OpdReactieDate.SetParentComponent(WebPanel3);
    OpdReactieDate.Name := 'OpdReactieDate';
    OpdReactieDate.Left := 116;
    OpdReactieDate.Top := 348;
    OpdReactieDate.Width := 109;
    OpdReactieDate.Height := 22;
    OpdReactieDate.BorderStyle := bsSingle;
    OpdReactieDate.ChildOrder := 10;
    OpdReactieDate.Color := clWhite;
    OpdReactieDate.Date := 44778.000000000000000000;
    OpdReactieDate.Role := '';
    OpdReactieDate.ShowSeconds := False;
    OpdReactieDate.TabOrder := 13;
    OpdReactieDate.Text := '';
    OpdVerslag.SetParentComponent(WebPanel3);
    OpdVerslag.Name := 'OpdVerslag';
    OpdVerslag.Left := 416;
    OpdVerslag.Top := 507;
    OpdVerslag.Width := 730;
    OpdVerslag.Height := 270;
    OpdVerslag.AutoSize := False;
    OpdVerslag.HeightPercent := 100.000000000000000000;
    OpdVerslag.SelLength := 0;
    OpdVerslag.SelStart := 0;
    OpdVerslag.TabOrder := 14;
    OpdVerslag.WidthPercent := 100.000000000000000000;
    OpdGeplandDate.SetParentComponent(WebPanel3);
    OpdGeplandDate.Name := 'OpdGeplandDate';
    OpdGeplandDate.Left := 116;
    OpdGeplandDate.Top := 385;
    OpdGeplandDate.Width := 109;
    OpdGeplandDate.Height := 22;
    OpdGeplandDate.BorderStyle := bsSingle;
    OpdGeplandDate.ChildOrder := 10;
    OpdGeplandDate.Color := clWhite;
    OpdGeplandDate.Date := 44778.000000000000000000;
    OpdGeplandDate.Role := '';
    OpdGeplandDate.ShowSeconds := False;
    OpdGeplandDate.TabOrder := 15;
    OpdGeplandDate.Text := '';
    OpdVerslagDate.SetParentComponent(WebPanel3);
    OpdVerslagDate.Name := 'OpdVerslagDate';
    OpdVerslagDate.Left := 116;
    OpdVerslagDate.Top := 510;
    OpdVerslagDate.Width := 109;
    OpdVerslagDate.Height := 22;
    OpdVerslagDate.BorderStyle := bsSingle;
    OpdVerslagDate.ChildOrder := 10;
    OpdVerslagDate.Color := clWhite;
    OpdVerslagDate.Date := 44778.000000000000000000;
    OpdVerslagDate.Role := '';
    OpdVerslagDate.ShowSeconds := False;
    OpdVerslagDate.TabOrder := 16;
    OpdVerslagDate.Text := '';
    OpdAlgOpm.SetParentComponent(WebPanel3);
    OpdAlgOpm.Name := 'OpdAlgOpm';
    OpdAlgOpm.Left := 417;
    OpdAlgOpm.Top := 813;
    OpdAlgOpm.Width := 730;
    OpdAlgOpm.Height := 270;
    OpdAlgOpm.AutoSize := False;
    OpdAlgOpm.HeightPercent := 100.000000000000000000;
    OpdAlgOpm.SelLength := 0;
    OpdAlgOpm.SelStart := 0;
    OpdAlgOpm.TabOrder := 17;
    OpdAlgOpm.WidthPercent := 100.000000000000000000;
    VeldOpdOpdrachtgever.SetParentComponent(WebPanel3);
    VeldOpdOpdrachtgever.Name := 'VeldOpdOpdrachtgever';
    VeldOpdOpdrachtgever.Left := 115;
    VeldOpdOpdrachtgever.Top := 93;
    VeldOpdOpdrachtgever.Width := 145;
    VeldOpdOpdrachtgever.Height := 24;
    VeldOpdOpdrachtgever.HeightPercent := 100.000000000000000000;
    VeldOpdOpdrachtgever.WidthPercent := 100.000000000000000000;
    VeldOpdOpdrachtgever.ItemIndex := 0;
    VeldOpdOpdrachtgever.Items.BeginUpdate;
    try
      VeldOpdOpdrachtgever.Items.Clear;
      VeldOpdOpdrachtgever.Items.Add('');
      VeldOpdOpdrachtgever.Items.Add('Cathy de Leeuw');
      VeldOpdOpdrachtgever.Items.Add('Henny de Groen');
      VeldOpdOpdrachtgever.Items.Add('Rick Bakema');
    finally
      VeldOpdOpdrachtgever.Items.EndUpdate;
    end;
    FilterBox.SetParentComponent(PageOpdrachten);
    FilterBox.Name := 'FilterBox';
    FilterBox.Left := 264;
    FilterBox.Top := 11;
    FilterBox.Width := 90;
    FilterBox.Height := 24;
    FilterBox.HeightPercent := 100.000000000000000000;
    FilterBox.TabOrder := 6;
    FilterBox.Text := 'Uit';
    FilterBox.WidthPercent := 100.000000000000000000;
    SetEvent(FilterBox, Self, 'OnChange', 'FilterBoxChange');
    FilterBox.ItemIndex := 0;
    FilterBox.Items.BeginUpdate;
    try
      FilterBox.Items.Clear;
      FilterBox.Items.Add('Uit');
      FilterBox.Items.Add('Besteld');
      FilterBox.Items.Add('Gepland');
      FilterBox.Items.Add('Afgerond');
      FilterBox.Items.Add('Opgeleverd');
    finally
      FilterBox.Items.EndUpdate;
    end;
    lblTeller.SetParentComponent(OnderdelenControl);
    lblTeller.Name := 'lblTeller';
    lblTeller.Left := 535;
    lblTeller.Top := 2;
    lblTeller.Width := 8;
    lblTeller.Height := 16;
    lblTeller.Caption := 'X';
    lblTeller.HeightPercent := 100.000000000000000000;
    lblTeller.WidthPercent := 100.000000000000000000;
    WebMyCloudDbClientDataset.SetParentComponent(Self);
    WebMyCloudDbClientDataset.Name := 'WebMyCloudDbClientDataset';
    WebMyCloudDbClientDataset.AfterOpen := WebMyCloudDbClientDatasetAfterOpen;
    WebMyCloudDbClientDataset.Left := 968;
    WebMyCloudDbClientDataset.Top := 112;
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := WebMyCloudDbClientDataset;
    WebDataSource.Left := 800;
    WebDataSource.Top := 112;
    CloudAlles.SetParentComponent(Self);
    CloudAlles.Name := 'CloudAlles';
    CloudAlles.LoginHeight := 0;
    CloudAlles.LoginWidth := 0;
    SetEvent(CloudAlles, Self, 'OnAccessToken', 'CloudAllesAccessToken');
    SetEvent(CloudAlles, Self, 'OnResponse', 'CloudAllesResponse');
    CloudAlles.Left := 592;
    CloudAlles.Top := 116;
  finally
    Basis.AfterLoadDFMValues;
    WebPageControl.AfterLoadDFMValues;
    WPCInloggen.AfterLoadDFMValues;
    WebLoginPanel.AfterLoadDFMValues;
    WPCDatabase.AfterLoadDFMValues;
    OnderdelenControl.AfterLoadDFMValues;
    PageGebruikers.AfterLoadDFMValues;
    GebNaam.AfterLoadDFMValues;
    GebCR.AfterLoadDFMValues;
    GebLogo.AfterLoadDFMValues;
    lblInloggerGeb.AfterLoadDFMValues;
    NaamInloggerGeb.AfterLoadDFMValues;
    GebDoeEntities.AfterLoadDFMValues;
    VulBox.AfterLoadDFMValues;
    GebListBox1.AfterLoadDFMValues;
    GebListBox2.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    lblGebGebruiker.AfterLoadDFMValues;
    lblGebWachtwoord.AfterLoadDFMValues;
    lblGebNaam.AfterLoadDFMValues;
    lblGebFunctie.AfterLoadDFMValues;
    lblGebLocatie.AfterLoadDFMValues;
    VeldGebGebruiker.AfterLoadDFMValues;
    VeldGebWachtwoord.AfterLoadDFMValues;
    VeldGebNaam.AfterLoadDFMValues;
    GebbtnAdd.AfterLoadDFMValues;
    GebbtnUpdate.AfterLoadDFMValues;
    GebbtnDelete.AfterLoadDFMValues;
    GebDBGrid.AfterLoadDFMValues;
    GebDBNavigator.AfterLoadDFMValues;
    GebRefresh.AfterLoadDFMValues;
    VeldGebFunctie.AfterLoadDFMValues;
    VeldGebLocatie.AfterLoadDFMValues;
    VeldGebActief.AfterLoadDFMValues;
    WebGroupBox1.AfterLoadDFMValues;
    lblBeheerder.AfterLoadDFMValues;
    lblBesteller.AfterLoadDFMValues;
    lblUitvoerder.AfterLoadDFMValues;
    lblControleur.AfterLoadDFMValues;
    SoortFunctie.AfterLoadDFMValues;
    GebToonWW.AfterLoadDFMValues;
    GebActief.AfterLoadDFMValues;
    Actief.AfterLoadDFMValues;
    PageLocaties.AfterLoadDFMValues;
    LocNaam.AfterLoadDFMValues;
    LocCR.AfterLoadDFMValues;
    LocLogo.AfterLoadDFMValues;
    lblInloggerLoc.AfterLoadDFMValues;
    NaamInloggerLoc.AfterLoadDFMValues;
    LocListBox1.AfterLoadDFMValues;
    LocListBox2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    lblLocLocatie.AfterLoadDFMValues;
    lblLocAdres.AfterLoadDFMValues;
    lblLocContactpersoon.AfterLoadDFMValues;
    lblLocEmailadres.AfterLoadDFMValues;
    VeldLocLocatie.AfterLoadDFMValues;
    VeldLocAdres.AfterLoadDFMValues;
    VeldLocContactpersoon.AfterLoadDFMValues;
    LocbtnAdd.AfterLoadDFMValues;
    LocbtnUpdate.AfterLoadDFMValues;
    LocbtnDelete.AfterLoadDFMValues;
    LocDbGrid.AfterLoadDFMValues;
    LocDBNavigator.AfterLoadDFMValues;
    LocRefresh.AfterLoadDFMValues;
    VeldLocEmailadres.AfterLoadDFMValues;
    VeldLocActief.AfterLoadDFMValues;
    SoortBieb.AfterLoadDFMValues;
    LocDoeEntities.AfterLoadDFMValues;
    LocActief.AfterLoadDFMValues;
    PageOpdrachten.AfterLoadDFMValues;
    lblFilteren.AfterLoadDFMValues;
    OpdNaam.AfterLoadDFMValues;
    OpdCR.AfterLoadDFMValues;
    OpdLogo.AfterLoadDFMValues;
    lblInloggerOpd.AfterLoadDFMValues;
    NaamInloggerOpd.AfterLoadDFMValues;
    OpdActief.AfterLoadDFMValues;
    OpdListBox2.AfterLoadDFMValues;
    OpdListBox1.AfterLoadDFMValues;
    OpdDoeEntities.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    lblOpdLocatie.AfterLoadDFMValues;
    lblOpdContactpersoon.AfterLoadDFMValues;
    lblOpdracht.AfterLoadDFMValues;
    lblOpdDatum.AfterLoadDFMValues;
    lblReactie.AfterLoadDFMValues;
    lblReactieDatum.AfterLoadDFMValues;
    lblVerslag.AfterLoadDFMValues;
    lblGeplandDatum.AfterLoadDFMValues;
    lblVerslagDatum.AfterLoadDFMValues;
    lblAlgemeneOpmerkingen.AfterLoadDFMValues;
    lblOpdrachtgever.AfterLoadDFMValues;
    ReserveTekst.AfterLoadDFMValues;
    OpdbtnAdd.AfterLoadDFMValues;
    OpdbtnUpdate.AfterLoadDFMValues;
    OpdbtnDelete.AfterLoadDFMValues;
    VeldOpdLocatie.AfterLoadDFMValues;
    VeldOpdContactpersoon.AfterLoadDFMValues;
    OpdOpdrachtDate.AfterLoadDFMValues;
    OpdStatus.AfterLoadDFMValues;
    VeldOpdActief.AfterLoadDFMValues;
    OpdOpdracht.AfterLoadDFMValues;
    OpdbtnNieuw.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    OpdMetaDataCheck.AfterLoadDFMValues;
    OpdReactie.AfterLoadDFMValues;
    OpdReactieDate.AfterLoadDFMValues;
    OpdVerslag.AfterLoadDFMValues;
    OpdGeplandDate.AfterLoadDFMValues;
    OpdVerslagDate.AfterLoadDFMValues;
    OpdAlgOpm.AfterLoadDFMValues;
    VeldOpdOpdrachtgever.AfterLoadDFMValues;
    FilterBox.AfterLoadDFMValues;
    lblTeller.AfterLoadDFMValues;
    WebMyCloudDbClientDataset.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
    CloudAlles.AfterLoadDFMValues;
  end;
end;

end.
